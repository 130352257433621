import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import '../../css/blinker.css';
import Logo from './Logo';

export default function LogoBlinkerCenter({
  className,
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={classNames('flex h-full justify-center', className)}>
      <Logo className='w-24 blink-s1' />
    </div>
  );
}
