import produce from 'immer';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { atomFamily, useRecoilState } from 'recoil';
import {
  ModelCreateUserApiRequest,
  ModelGetQueryDetailResponse,
} from '../../api/__gen__/data-contracts';
import { useRpcStatusV3 } from '../../hooks/network';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import {
  AQueryDetails,
  loadQuery,
  runQueryById,
  SQueryResults,
} from '../queryBuilder/QueryState';
import { zToast } from '../toast/toast';
import { ApiQueryTextState } from './ApiQueryPadHooks';
import { postCreateApi } from './api-rpc';
import { useApiId } from './ViewApiHooks';
import { AllColTypes } from '../queryBuilder/api-utils';

export const ApiCreateValues = atomFamily<
  ModelCreateUserApiRequest,
  string | undefined | null
>({
  key: 'ApiCreateValues',
  default: { displayName: 'New API', resultDatabase: 'zettadb1' },
});

export function useQueryIdSearchParams() {
  const [searchParams] = useSearchParams();
  const queryId = searchParams.get('queryId');

  return queryId;
}

export function useNewApiStep2() {
  const [, { getPath, navigate }] = useWorkspace();
  const queryId = useQueryIdSearchParams();
  const apiId = useApiId();

  const [queryDetail, setQueryDetail] = useRecoilState(AQueryDetails(queryId));
  const [values, setValues] = useRecoilState(ApiCreateValues(apiId));
  const [, setQuery] = useRecoilState(ApiQueryTextState(apiId));
  const [queryResults, setQueryResults] = useRecoilState(
    SQueryResults(queryId)
  );

  const [rpcStatus, setRpcStatus] = useRpcStatusV3('new-api-step2');
  const [rpcStatusResults, setRpcStatusResults] = useRpcStatusV3(
    'new-api-step2-query'
  );
  const [rpcStatusCreating, setRpcStatusCreating] = useRpcStatusV3();

  // load query detail
  useEffect(() => {
    async function run() {
      if (queryId && isEmpty(queryDetail)) {
        setRpcStatus({ isLoading: true });

        // fetch query
        const resp = await loadQuery({
          queryId,
        });

        // @ts-ignore
        const error = resp?.error;

        setRpcStatus({ error, isLoading: false });

        if (error) {
          zToast.error(error);
        } else {
          const data = resp?.data as ModelGetQueryDetailResponse;
          setQueryDetail(data);
        }
      }
    }

    run();
  }, [queryId]);

  // populate query text in query pad
  useEffect(() => {
    setQuery(queryDetail.text || '');
  }, [queryDetail]);

  const columns = queryResults?.results?.metadata?.columns || [];

  // load query results
  useEffect(() => {
    async function run() {
      // populate form values
      if (queryDetail && !isEmpty(columns)) {
        const nValue = produce(values, (draft) => {
          draft.query = queryDetail.text;
          draft.database = queryDetail.database;

          draft.resultColumnTypes = columns.reduce((p, i) => {
            const { type } = i;

            const nType = AllColTypes.includes(type) ? type : 'VARCHAR';

            return { ...p, [i.name]: nType };
          }, {});
        });

        setValues(nValue);
      }

      if (
        !rpcStatusResults.isLoading &&
        queryDetail?.id &&
        isEmpty(queryResults)
      ) {
        setRpcStatusResults({ isLoading: true });

        const results = await runQueryById({
          queryId: queryDetail.id,
          paramsStr: queryDetail.paramsStr || '{}',
          resultCacheExpireMillis: queryDetail.resultCacheExpireMillis,
        });

        // @ts-ignore
        const error = results?.error;

        setRpcStatusResults({ error, isLoading: false });

        if (error) {
          zToast.error(error);
        } else {
          setQueryResults(results);
        }
      }
    }

    run();
  }, [queryDetail, columns]);

  async function createNewApi() {
    if (rpcStatusCreating.isLoading) return;

    setRpcStatusCreating({ isLoading: true });

    const resp = await postCreateApi(values);
    const error = resp?.data?.message;

    setRpcStatusCreating({ isLoading: false, error });

    if (error) {
      toast.dismiss();
      toast.error(error);
    } else {
      // success
      const id = resp?.data?.id;
      if (id) {
        navigate(`${getPath('apis')}/${id}`);
      } else {
        toast.error('something went wrong. please report to engineering team.');
      }
    }
  }

  return [
    {
      values,
      queryDetail,
      rpcStatusQueryLoading: rpcStatus,
      rpcStatusCreating,
    },
    { setValues, createNewApi },
  ] as const;
}
