/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelCancelQueryrunRequest,
  ModelCancelQueryrunResponse,
  ModelGetQueryrunDetailResponse,
  ModelGetQueryrunStatusResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Queryruns<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description cancel a running queryrun
   *
   * @tags example
   * @name CancelCreate
   * @summary cancel a running queryrun
   * @request POST:/queryruns/{id}/cancel
   */
  cancelCreate = (
    id: string,
    request: ModelCancelQueryrunRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelCancelQueryrunResponse, any>({
      path: `/queryruns/${id}/cancel`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get queryrun detail
   *
   * @tags example
   * @name DetailDetail
   * @summary get queryrun detail
   * @request GET:/queryruns/{id}/detail
   */
  detailDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetQueryrunDetailResponse, any>({
      path: `/queryruns/${id}/detail`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get queryrun status
   *
   * @tags example
   * @name StatusDetail
   * @summary get queryrun status
   * @request GET:/queryruns/{id}/status
   */
  statusDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetQueryrunStatusResponse, any>({
      path: `/queryruns/${id}/status`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
