import {
  atomFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { get, first, sortBy, debounce } from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { ModelGetQueryDetailResponse } from '../../api/__gen__/data-contracts';
import { ChartEntity } from '../chart/types';
import { TParamState, TQueryRunParams, TQueryRunParamsV3 } from './types';
import {
  AQueryParamDefaultValuesCache,
  AQueryParams,
  convertDBParamsStrToParams,
  convertParamsArrToMap,
  convertParamsToDBParamsStr,
} from './QueryPadState';
import {
  AQueryDetails,
  AQueryRunStartAt,
  AStaticTabType,
  CacheTTLSate,
  createQuery,
  ForkedQueryFromState,
  loadQuery,
  QueryId,
  runQueryById,
  SQueryResults,
  TQueryResult,
  updateQueryText,
} from './QueryState';
import usePrompt from '../../hooks/router';
import { SelectedDBState } from '../database/DatabaseState';
import { zToast } from '../toast/toast';
import { AChartsList } from './ChartsState';
import { useAddChart, TABLE_CHART } from './NewVizHooks';
import {
  useChartIdSearchParam,
  TemporaryQueryResultId,
} from './QueryBuilderHooks';
import { useRpcStatusV3 } from '../../hooks/network';
import { postDeleteQuery, postUpdateQuery } from './query-rpc';
import { useQueryIdV3 } from './QueryPadStateV3';
import { convertDBChartToUIChart } from '../chart/utils';
import useAuth from '../../hooks/auth';
import { MyQueriesState } from '../explorer/QueriesHooks';

export type ServerQueryModel = ModelGetQueryDetailResponse;
export type QueryModel = ServerQueryModel & {
  charts?: ChartEntity[];
  queryParams?: TParamState;
};

export type SaveQueryFun = (values: Partial<QueryModel>) => void;

export type QueryFormValues = Partial<QueryModel>;

export const cacheTtlOptions = [
  { value: 60 * 1000, label: '1 min' },
  { value: 3 * 60 * 1000, label: '3 mins' },
  { value: 10 * 60 * 1000, label: '10 mins' },
  { value: 30 * 60 * 1000, label: '30 mins' },
  { value: 60 * 60 * 1000, label: '1 hr' },
  { value: 3 * 60 * 60 * 1000, label: '3 hrs' },
  { value: 6 * 60 * 60 * 1000, label: '6 hrs' },
  { value: 12 * 60 * 60 * 1000, label: '12 hrs' },
  { value: 24 * 60 * 60 * 1000, label: '24 hrs' },
];

export const queryStateDefaultValue = {
  displayName: 'New Query',
  text: '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',
  resultCacheExpireMillis: cacheTtlOptions[1].value,
};

export const QueryStateV3 = atomFamily<QueryFormValues, QueryId>({
  key: 'QueryStateV3',
  default: queryStateDefaultValue,
});

export function convertServerQueryToClientQuery(
  serverQuery?: ServerQueryModel
): QueryModel {
  return {
    ...serverQuery,
    charts: serverQuery?.charts?.map(convertDBChartToUIChart),
    queryParams: convertDBParamsStrToParams(serverQuery?.paramsStr || '{}'),
  };
}

export function useUpdateQuery() {
  const queryId = useQueryIdV3();
  const [rpcStatus, setRpcStatus] = useRpcStatusV3();
  const setValues = useSetRecoilState(QueryStateV3(queryId));

  async function updateQuery(formValues: QueryFormValues) {
    if (rpcStatus.isLoading || !queryId) {
      return false;
    }

    setRpcStatus({ isLoading: true });

    const resp = await postUpdateQuery(formValues);

    const error = resp?.data?.message;
    const id = resp?.data?.id;

    setRpcStatus({ isLoading: false, error });

    if (error || !id) {
      toast.error(
        error || 'something went wrong. please report to engineering team.'
      );
      return false;
    }

    setValues(resp.data);
    return true;
  }

  return [{ rpcStatus }, { setRpcStatus, updateQuery }] as const;
}

export function useQueryRunV3(
  { queryPath }: { queryPath: string } = { queryPath: '/query-builder' }
) {
  const params = useParams();
  const navigate = useNavigate();
  const queryId = params?.id;
  const chartIdSearchParam = useChartIdSearchParam();
  const [rpcStatus, setRpcStatus] = useRpcStatusV3();
  const [rpcStatusDel, setRpcStatusDel] = useRpcStatusV3(
    `${queryId}-delete-query`
  );

  const [myQueries, setMyQueries] = useRecoilState(MyQueriesState);

  // v3 query state
  const [queryValues, setQueryValues] = useRecoilState(QueryStateV3(queryId));
  const [, { getIsOwner }] = useAuth();
  const isAuthor = !queryId || getIsOwner(queryValues);

  async function deleteQuery() {
    if (!queryId || rpcStatusDel.isLoading) {
      return false;
    }

    setRpcStatusDel({
      isLoading: true,
    });

    const resp = await postDeleteQuery(queryId);
    const error = resp?.data?.message;

    // TODO handle error
    setRpcStatusDel({
      isLoading: false,
      error,
    });

    if (error) {
      zToast.error(error);
      return false;
    }

    setMyQueries({
      items: myQueries.items?.filter((i) => i.id !== queryId),
    });

    navigate(-1);
    return true;
    // remove item from the list
  }

  /**
   * Auto save query
   */
  const debouncedEventHandler = useMemo(
    () =>
      debounce(async (values: QueryFormValues) => {
        // RPC
        // TODO add vaildations and throwing errors
        postUpdateQuery(values);

        setRpcStatus({ isLoading: false });
      }, 600),
    []
  );

  const saveQueryDeb = useCallback(
    (values: QueryFormValues) => {
      if (queryId) {
        setRpcStatus({ isLoading: true });

        debouncedEventHandler(values);
      }

      setQueryValues(values);
    },
    [debouncedEventHandler, queryId]
  );

  // selector states
  const [selectedDB, setDB] = useRecoilState(SelectedDBState);

  // query states
  const setQueryStartAt = useSetRecoilState(AQueryRunStartAt);
  const [ttl, setTtl] = useRecoilState(CacheTTLSate);
  const queryResults = useRecoilValue(SQueryResults(queryId));
  const [isQueryDataLoading, setIsQueryDataLoading] = useState(!!params.id);

  const [forkedQuery, setForkedQuery] = useRecoilState(
    ForkedQueryFromState(queryId)
  );

  const [isQueryRunning, setIsQueryRunning] = useState(false);
  // charts
  const setCharts = useSetRecoilState(AChartsList);

  /**
   * TODO: WIP: consolidating all query details in a recoil state
   * charts, query text, query params, creator etc
   */
  const [queryDetail, setQueryDetail] = useRecoilState(AQueryDetails(queryId));

  // tabs
  const [currentTab, setCurrentTab] = useRecoilState(AStaticTabType);
  // query params
  const [queryParams, setQueryParams] = useRecoilState(AQueryParams);
  // query param cached values
  const setQueryParamDefaultValues = useSetRecoilState(
    AQueryParamDefaultValuesCache
  );
  // to figure out the query inputs are editted
  const [queryRunParamsCache, setQueryRunParamsCache] =
    useState<TQueryRunParams>();

  const [addChart] = useAddChart();

  // TODO better caching runtime (map by query ID)
  // reset query run time when query Id changes
  useEffect(() => {
    if (!queryId) {
      setQueryStartAt(null);
    }
  }, [queryId]);
  // reset when first render
  useEffect(() => {
    setQueryStartAt(null);
  }, []);

  usePrompt('A query is running, do you want to continue?', isQueryRunning);

  const handleRun = useRecoilCallback(
    ({ set }) =>
      async (runParams: TQueryRunParamsV3) => {
        const {
          displayName: displayNameLocal,
          id: queryIdLocal,
          text,
          database,
          queryParams: queryParamsLocal,
        } = runParams;

        if (isAuthor && !displayNameLocal) {
          toast.info('please name the query');
          return;
        }
        if (isAuthor && !text) {
          toast.info('please write a query');
          return;
        }
        if (isQueryRunning || !text || !database) {
          return;
        }

        toast.dismiss();
        setIsQueryRunning(true);
        setQueryStartAt(null);

        // skip saving when loading query first time
        // OR not author
        if (
          isAuthor &&
          queryIdLocal &&
          queryRunParamsCache &&
          queryParamsLocal
        ) {
          const updateParams = {
            queryId: queryIdLocal,
            query: text,
            paramsStr: convertParamsToDBParamsStr(queryParamsLocal),
          };
          const resp = await updateQueryText(updateParams);

          if (resp?.data) {
            // success
            setQueryDetail({
              ...queryDetail,
              ...updateParams,
              text: updateParams.query,
            });
          } else {
            zToast.error('Query update failed');
            setIsQueryRunning(false);
            return;
          }
        }

        setQueryRunParamsCache({
          displayName: displayNameLocal,
          selectedDB: database,
          query: text,
          queryId: queryIdLocal,
          queryParams: queryParamsLocal,
        });

        setQueryStartAt(new Date());

        let result: TQueryResult | null = null;
        // run query
        if (queryIdLocal && text && queryParamsLocal) {
          result = await runQueryById({
            queryId: queryIdLocal,
            paramsStr: convertParamsToDBParamsStr(queryParamsLocal),
            resultCacheExpireMillis: ttl,
          });
        }

        // create query
        if (!queryIdLocal && text && database && queryParamsLocal) {
          result = await createQuery({
            displayName: displayNameLocal,
            query: text,
            paramsStr: convertParamsToDBParamsStr(queryParamsLocal),
            database,
            resultCacheExpireMillis: ttl,
          });

          const newQueryId = result?.queryId;

          if (newQueryId) {
            // ignore eror state
            await addChart(TABLE_CHART, { newQueryId });
          }
        }

        setIsQueryRunning(false);

        if (result !== undefined) {
          const newQueryId = result?.queryId;

          // error handle
          if (result?.error) {
            setQueryStartAt(null);
            zToast.error(result?.error);
          }

          // remove temp results
          set(SQueryResults(TemporaryQueryResultId), null);

          if (newQueryId) {
            // new query
            set(SQueryResults(newQueryId), result);
            if (newQueryId !== queryIdLocal) {
              setTimeout(() => {
                navigate(`${queryPath}/${newQueryId}`);
              }, 200);
            }
          } else {
            // update query
            set(SQueryResults(queryIdLocal), result);
          }
        }
      },
    [isAuthor, isQueryRunning, queryParams, ttl]
  );

  // load query on initial page load
  useEffect(() => {
    async function init() {
      if (queryId) {
        setIsQueryDataLoading(true);

        const resp = await loadQuery({
          queryId,
        });

        // @ts-ignore
        const error = resp?.error;

        if (error) {
          zToast.error(error);
        }

        const data = resp?.data as ModelGetQueryDetailResponse;
        const {
          text,
          charts: chartsFromDB,
          database,
          paramsStr,
          resultCacheExpireMillis,
          forkQueryId,
        } = data;

        setQueryDetail(data);
        setTtl(resultCacheExpireMillis || 3 * 60 * 1000);
        setIsQueryDataLoading(false);
        setQueryValues(convertServerQueryToClientQuery(data));

        if (forkQueryId) {
          // get forked query
          const respForked = await loadQuery({
            queryId: forkQueryId,
          });

          if (respForked?.data) {
            setForkedQuery(respForked?.data);
          }
        }

        if (selectedDB !== database) {
          setDB(database);
        }

        // load query params
        let nQueryParams = [] as TParamState;
        if (paramsStr) {
          nQueryParams = convertDBParamsStrToParams(paramsStr);
        }

        setQueryParamDefaultValues(convertParamsArrToMap(nQueryParams));
        setQueryParams(nQueryParams);

        const chartsUIModel = (chartsFromDB || []).reduce<
          Record<string, ChartEntity>
        >((p, dbChart) => {
          if (dbChart.id) {
            p[dbChart.id] = convertDBChartToUIChart(dbChart);
          }

          return p;
        }, {});

        setCharts(chartsUIModel);

        if (chartIdSearchParam) {
          const currentActiveChart = chartsFromDB?.find(
            (c) => c.id === chartIdSearchParam
          );

          if (currentActiveChart?.id) {
            const parsedChart = get(chartsUIModel, [currentActiveChart.id]);
            setCurrentTab({
              label: `${parsedChart.type} chart`,
              key: parsedChart.id,
            });
          }
        } else {
          // setting correct active tab after loading a query
          const currentActiveChart = chartsFromDB?.find(
            (c) => c.id === currentTab?.key
          );

          // selected chart not available on this query any longer
          // OR new viz tab is enabled (before the query created)
          if (
            !currentActiveChart ||
            currentTab.label === '+ new visualization'
          ) {
            const firstChart = first(sortBy(chartsFromDB, 'createdTime'));
            if (firstChart?.id) {
              const parsedFirstChart = get(chartsUIModel, [firstChart.id]);

              setCurrentTab({
                label: `${parsedFirstChart.type} chart`,
                key: parsedFirstChart.id,
              });
            }
          }
        }

        if (database && text && paramsStr) {
          const nQueryRunParams = {
            selectedDB: database,
            queryId,
            query: text,
            queryParams: nQueryParams,
          };

          if (!queryResults) {
            setQueryStartAt(null);
          }

          setQueryRunParamsCache(nQueryRunParams);
        }
      } else {
        setCharts({});

        setQueryStartAt(null);
      }
    }

    init();
  }, [queryId]);

  return [
    {
      queryValues,
      forkedQuery,
      queryDetail,
      isQueryDataLoading,
      isQueryRunning,
      queryRunParamsCache,
      updateRpcState: rpcStatus,
      rpcStatusDel,
    },
    { handleRun, saveQueryDeb, deleteQuery },
  ] as const;
}
