import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { MdAddBox } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { useWorkspace } from './workspace-hooks';

type MenuItemType = {
  label: string;
  path: string;
};

const AppMenus = [
  {
    label: 'Query Builder',
    path: 'queries/new',
  },
  {
    label: 'API Builder',
    path: 'queries/api',
  },
  {
    label: 'Dashboard',
    path: 'dashboards',
  },
  // {
  //   label: 'Project',
  //   path: '/projects',
  // },
];

const TableMenus = [
  {
    label: 'Data Source',
    path: 'data-sources',
  },
  // {
  //   label: 'Contract',
  //   path: '',
  // },
];

export default function CreateMenu() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [{ workspace }] = useWorkspace();

  const handleMenuClick = useCallback(
    (menu: MenuItemType) => {
      if (menu.path) {
        // TODO unlock later
        navigate(`/my-workspace/${menu.path}`);
      } else {
        toast.dismiss();
        toast.warning('Coming soon');
      }
      setOpen(false);
    },
    [workspace]
  );
  return (
    <div
      className='dropdown dropdown-end'
      onBlur={() => {
        setOpen(false);
      }}
    >
      <button
        className='btn btn-primary gap-2'
        onClick={() => {
          setOpen(!open);
        }}
      >
        Create
        <MdAddBox size='1rem' />
      </button>
      <ul
        className={classNames(
          'mt-3 p-2 shadow bg-base-100 rounded-box w-44 text-right',
          // open ? 'menu menu-compact dropdown-content ' : 'hidden',
          'menu menu-compact dropdown-content opacity-100 right-[-5px] visible'
        )}
      >
        <li className='pointer-events-none'>
          <div className='py-1 px-4 text-xs opacity-30 font-bold text-right block'>
            Application
          </div>
        </li>
        {AppMenus.map((menu) => (
          <li key={menu.label}>
            <div
              className='justify-end font-semibold hover:text-primary hover:bg-transparent'
              onMouseDown={() => {
                handleMenuClick(menu);
              }}
            >
              {menu.label}
            </div>
          </li>
        ))}
        <li className='pointer-events-none'>
          <hr className='py-1' />
          <div className='py-1 px-4 text-xs opacity-30 font-bold text-right block'>
            Table
          </div>
        </li>
        {TableMenus.map((menu) => (
          <li key={menu.label}>
            <div
              className='justify-end font-semibold hover:text-primary hover:bg-transparent'
              onMouseDown={() => {
                handleMenuClick(menu);
              }}
            >
              {menu.label}
            </div>
          </li>
        ))}{' '}
      </ul>
    </div>
  );
}
