import { TSeriesType } from '../chart/components/EChart';

export const PollingInterval = 1000;

export type UpdateChartFuncType = (
  name: string | undefined,
  newOptions: Partial<TSeriesType>
) => void;

export const SERIES_OPTION_ROW = 'SERIES_OPTION_ROW';

type Command = {
  name: string;
  keys: {
    mac: string[];
    pc: string[];
  };
};

export const COMMANDS_PER_PAGE = 6;

export const COMMANDS: Command[] = [
  { name: 'undoSelection', keys: { mac: ['⌘', 'U'], pc: ['Ctrl', 'U'] } },
];

export const dbGroup1 = [
  'arbitrum_mainnet',
  'ethereum_mainnet',
  'iotex_mainnet',
  'polygon_mainnet',
  'ripple_mainnet',
  'solana_mainnet',
];

export const dbGroup2 = ['nft', 'nft_listing', 'prices'];
