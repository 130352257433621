import { useParams } from 'react-router';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import CSV from './csv/CSV';
import Mongodb from './mongodb/Mongodb';

export default function Upload() {
  const { type } = useParams();

  return (
    <LayoutBodyCard className='lg:basis-2/5 basis-full overflow-hidden'>
      {type === 'mongodb' && <Mongodb />}
      {type === 'csv' && <CSV />}
    </LayoutBodyCard>
  );
}
