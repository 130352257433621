import { isEmpty } from 'lodash';
import { useRecoilState } from 'recoil';

import { memo, useCallback } from 'react';
import { Column, SQueryResults } from '../queryBuilder/QueryState';
import { ApiCreateValues, useQueryIdSearchParams } from './NewApiHook';
import { AllColTypes } from '../queryBuilder/api-utils';
import produce from 'immer';
import LoadingIndicator from '../../components/LoadingIndicator';
import { useApiId } from './ViewApiHooks';
import { useRpcStatusV3 } from '../../hooks/network';
import { AiOutlineWarning } from 'react-icons/ai';

function TableColumns() {
  const queryId = useQueryIdSearchParams();
  const apiId = useApiId();
  const [queryResults] = useRecoilState(SQueryResults(queryId));
  const [rpcStatusResults] = useRpcStatusV3('new-api-step2-query');
  const [values, setValues] = useRecoilState(ApiCreateValues(apiId));

  const error = queryResults?.error || rpcStatusResults.error;
  const columns = queryResults?.results?.metadata?.columns || [];

  const handleIndexUpdate = useCallback(
    ({ item, checked }: { item: Column; checked: boolean }) => {
      setValues(
        produce(values, (draft) => {
          if (!draft.resultIndexes) draft.resultIndexes = [];

          if (checked) {
            // add
            draft.resultIndexes.push({ columns: [item.name] });
          } else {
            const idx = draft.resultIndexes.findIndex((i) =>
              i.columns?.includes(item.name)
            );
            // remove
            if (idx > -1) {
              draft.resultIndexes.splice(idx, 1);
            }
          }
        })
      );
    },
    [values, setValues]
  );

  return (
    <div>
      <div className='label font-semibold opacity-50 flex items-center justify-between'>
        <span className='text-[10px] font-semibold p-2'>
          INDEX FIELDS (OPTIONAL)
        </span>
      </div>
      <div className='flex flex-col px-3 pb-2 gap-y-2'>
        {error && (
          <span className='text-warning'>
            <AiOutlineWarning size='0.9rem' className=' inline' />{' '}
            <span>{error}</span>
          </span>
        )}
        {!error && isEmpty(columns) && (
          <span className='opacity-50 flex items-center justify-center'>
            <LoadingIndicator />
          </span>
        )}
        {!error &&
          !isEmpty(columns) &&
          columns.map((item) => {
            return (
              <div
                key={item.name}
                className='text-md flex flex-wrap opacity-80 hover:opacity-100 items-center gap-2'
              >
                <input
                  type='checkbox'
                  className='toggle toggle-sm toggle-primary'
                  checked={
                    !!values.resultIndexes?.find((ix) =>
                      ix.columns?.includes(item.name)
                    )
                  }
                  onChange={(e) => {
                    handleIndexUpdate({ checked: e.target.checked, item });
                  }}
                />
                <span
                  className='table-column-item break-words min-w-0 flex cursor-pointer items-center flex-nowrap font-semibold'
                  onClick={() => {
                    const idx = values.resultIndexes?.findIndex((i) =>
                      i.columns?.includes(item.name)
                    );

                    const checked = idx !== undefined && idx > -1;

                    handleIndexUpdate({ checked: !checked, item });
                  }}
                >
                  {item.name}
                </span>
                <div className='flex-1 text-right opacity-60'>
                  <select
                    className='focus:outline-none active:outline-none text-sm font-semibold'
                    value={values.resultColumnTypes?.[item.name]}
                    onChange={(e) => {
                      setValues(
                        produce(values, (draft) => {
                          if (!draft.resultColumnTypes)
                            draft.resultColumnTypes = {};
                          draft.resultColumnTypes[item.name] = e.target.value;
                        })
                      );
                    }}
                  >
                    {AllColTypes.map((t) => (
                      <option key={t} value={t}>
                        {t}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='mb-2' />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default memo(TableColumns);
