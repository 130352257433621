import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { memo, useState } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';
import { VscCircleOutline } from 'react-icons/vsc';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';

import LoadingIndicator from '../../components/LoadingIndicator';
import { RPCStatus } from '../../hooks/network';
import { convertNumberToFormatted, DateTimeFormat } from '../../utils/date';
import ProfileAvatar from '../auth/ProfileAvatar';
import LineChart from '../chart/components/LineChart';
import { useApiStats, useEditApi, useIndexingProgress } from './ViewApiHooks';
import ViewApiOverviewEditor from './ViewApiOverviewEditor';
import ViewApiProgressPopup from './ViewApiProgressPopup';

function ViewApiOverview({ rpcStatus }: { rpcStatus: RPCStatus }) {
  const [{ rpcStatus: apiRpcStatus, values }] = useEditApi();

  const [{ values: idxValues }] = useIndexingProgress();
  const [{ values: statsValues }] = useApiStats();
  const [openProgressPopup, setOpenProgressPopup] = useState(false);

  if (isEmpty(values) && apiRpcStatus.isLoading) {
    return (
      <div className='flex w-full items-center py-20 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  const indexingPercent = idxValues.progress || 0;

  return (
    <div className='flex'>
      <div className='flex flex-col w-[20rem] flex-shrink-0 flex-grow-0 p-4 gap-4 text-sm'>
        <div className='flex font-semibold opacity-80'>
          {values.description}
        </div>
        <div className='flex'>
          <div className='flex flex-1 flex-col gap-2 pr-1'>
            <div className='opacity-30 font-semibold'>PUBLISH DATE</div>
            <div className='font-semibold opacity-80'>
              {convertNumberToFormatted(values.publishedTime, {
                formatStr: DateTimeFormat,
                fallbackStr: '-',
              })}
            </div>
          </div>
          <div className='flex flex-1 flex-col gap-2'>
            <div className='opacity-30 font-semibold'>LAST UPDATED</div>
            <div className='font-semibold opacity-80'>
              {convertNumberToFormatted(values.updatedTime, {
                formatStr: DateTimeFormat,
                fallbackStr: '-',
              })}
            </div>
          </div>
        </div>
        {values.creator?.name && (
          <div className='flex'>
            <div className='flex flex-1 flex-col gap-2'>
              <div className='opacity-30 font-semibold'>PUBLISHED BY</div>
              <div className='flex items-center gap-2'>
                <ProfileAvatar
                  className='flex-none w-5 bg-base-content bg-opacity-30'
                  img={values.creator?.profileImage}
                />
                <div className='font-semibold opacity-80'>
                  {values.creator?.name}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='divider-y' />
      <div className='flex flex-1'>
        {
          <div className='flex flex-1 flex-col w-0'>
            {/* indexing progress */}
            <div className='flex gap-4 bg-base-200 px-5 py-3 items-center'>
              <div className='flex-none'>
                <span
                  className='border-primary border rounded-full cursor-pointer  font-semibold flex text-primary text-xs items-center gap-1 bg-primary bg-opacity-20 px-2 py-1'
                  onClick={() => {
                    // setType('error');
                  }}
                >
                  <VscCircleOutline size='0.8rem' /> INDEXING
                </span>
              </div>
              <div className='flex-auto'>
                <div className='relative'>
                  <div className='w-full h-1 bg-base-content bg-opacity-10'></div>
                  <div
                    className='h-1 bg-primary absolute top-0'
                    style={{ width: `${indexingPercent}%` }}
                  ></div>
                  <div className='absolute bottom-[-10px]'>
                    <span className='inline-block bg-primary rounded-full text-[6px] text-white p-[2px] w-[13px] h-[13px]'></span>
                  </div>
                  <div
                    className='absolute bottom-[-10px]'
                    style={{ left: `${indexingPercent}%` }}
                  >
                    <span className='inline-block bg-primary rounded-full text-[6px] text-white p-[2px] w-[13px] h-[13px]'></span>
                  </div>
                </div>
              </div>
              <div className='flex-none flex flex-col pl-3'>
                <div
                  className='text-xs flex items-center gap-1 cursor-pointer'
                  onClick={() => {
                    setOpenProgressPopup(!openProgressPopup);
                  }}
                >
                  PROGRESS{' '}
                  <RiErrorWarningLine size='0.8rem' className='opacity-70' />
                  <ViewApiProgressPopup
                    open={openProgressPopup}
                    idxValues={idxValues}
                    setOpen={setOpenProgressPopup}
                  />
                </div>
                <div className='text-xs'>{indexingPercent}%</div>
              </div>
            </div>

            {/* api transform sql */}
            <div className='flex flex-1 flex-col p-4 gap-2'>
              <div className='flex font-semibold text-sm opacity-50'>
                API TRANSFORMATION CODE{' '}
                {rpcStatus.isLoading && <LoadingIndicator />}
              </div>
              <div className='flex flex-col w-full min-h-[209px] overflow-y-auto '>
                <ViewApiOverviewEditor />
              </div>
            </div>

            {/* events per day */}
            {!isEmpty(statsValues.eventsDistribution) && (
              <div className='px-4 pb-4'>
                <div className='border p-4 rounded-xl'>
                  <div className='font-bold'>EVENTS PER DAY</div>
                  <div className='h-[20rem]'>
                    <LineChart
                      chart={{
                        queryId: 'qu12-60c59159-13e6-49d7-a467-80f991a2fcf8',
                        type: 'line',
                        displayName: 'Line chart',
                        uiOptions: {
                          version: 2,
                          chartOptions: {
                            showLegend: false,
                          },
                          dataOptions: {
                            xCol: {
                              colName: 'datetime',
                              valueType: 'int8',
                            },
                            yCols: [
                              {
                                colName: 'numOfEvents',
                                chartType: 'line',
                                color: '#0057FF',
                                customLabel: '',
                                valueType: 'int8',
                              },
                            ],
                          },
                        },
                        id: 'ch-20110408-7195-40a9-aa15-1d5b62b9d353',
                        createdTime: 1663016949935,
                      }}
                      queryResults={{
                        metadata: {
                          columns: [],
                        },
                        rows:
                          [...(statsValues.eventsDistribution || [])]
                            ?.reverse()
                            .map((event) => ({
                              ...event,
                              datetime: format(
                                new Date(event.datetime ?? 0),
                                DateTimeFormat
                              ),
                            })) || [],
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    </div>
  );
}

export default memo(ViewApiOverview);
