import { pick } from 'lodash';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { DefaultAreaChartProps } from '../chart/area-chart-type';
import { DefaultLineChartProps } from '../chart/line-chart-type';
import { DefaultNumberChart, NumberChartType } from '../chart/number-type';
import { DefaultScatterChartProps } from '../chart/scatter-chart-type';
import {
  ChartEntity,
  CommonChartEntityKeys,
  TableChartEntity,
} from '../chart/types';
import {
  AreaChartType,
  BarChartV2,
  LineChartType,
  ScatterChartType,
} from '../chart/types-v2';
import {
  DefaultBarChartProps,
  DefaultBarChartUiOptions,
} from '../chart/utils/echart';

import { AChartsList, createChart } from './ChartsState';
import {
  DefaultTableChart,
  DefaultTableChartUiOptions,
} from './components/TableChartComposeHooks';
import { AStaticTabType } from './QueryState';

export type TViz = {
  label: string;
  type?: ChartEntity['type'];
};

export const TABLE_CHART: TViz = {
  label: 'Table chart',
  type: 'table',
};

export const VIZ_TYPES: TViz[] = [
  TABLE_CHART,
  {
    label: 'Bar chart',
    type: 'bar',
  },
  {
    label: 'Line chart',
    type: 'line',
  },
  {
    label: 'Scatter chart',
    type: 'scatter',
  },
  {
    label: 'Area chart',
    type: 'area',
  },
  {
    label: 'Big number',
    type: 'number',
  },
];

const getBasePropsForChart = (
  // eslint-disable-next-line
  resp: any,
  queryId: string
) => {
  const baseProps = {
    // TODO: backend not returning query ID after creating a chart
    // @ts-ignore
    queryId,
    ...pick(resp?.data, CommonChartEntityKeys),
  };

  return baseProps;
};
export const useAddChart = () => {
  const setTab = useSetRecoilState(AStaticTabType);
  const params = useParams();
  const queryId = params.id;

  const charts = useRecoilValue(AChartsList);
  const [isAddingChart, setIsAddingChart] = useState(false);

  const addChart = useRecoilCallback(
    ({ set }) =>
      async (viz: TViz, options?: { newQueryId?: string }) => {
        const targetQueryId = options?.newQueryId || queryId;
        if (isAddingChart || !targetQueryId) return;

        setIsAddingChart(true);

        if (viz.type === 'bar') {
          const newChartPayload = {
            queryId: targetQueryId,
            type: 'bar',
            uiOptions: DefaultBarChartUiOptions,
          } as BarChartV2;

          const resp = await createChart(newChartPayload);

          const newChart: BarChartV2 = {
            // TODO: fix backend not returning created object
            ...getBasePropsForChart(resp, targetQueryId),
            // This is temporary fix that use client generated default
            uiOptions: DefaultBarChartUiOptions,
            ...DefaultBarChartProps,
          };

          set(AChartsList, {
            ...charts,
            [newChart.id]: newChart,
          });

          setTab({
            label: `bar chart`,
            key: newChart.id,
          });
        } else if (viz.type === 'line') {
          const newChartPayload = {
            queryId: targetQueryId,
            ...DefaultLineChartProps,
          } as LineChartType;

          const resp = await createChart(newChartPayload);

          const newChart: LineChartType = {
            ...newChartPayload,
            // TODO: fix backend not returning created object
            ...getBasePropsForChart(resp, targetQueryId),
          };

          set(AChartsList, {
            ...charts,
            [newChart.id]: newChart,
          });

          setTab({
            label: `line chart`,
            key: newChart.id,
          });
        } else if (viz.type === 'scatter') {
          const newChartPayload = {
            queryId: targetQueryId,
            ...DefaultScatterChartProps,
          } as ScatterChartType;

          const resp = await createChart(newChartPayload);

          const newChart: ScatterChartType = {
            ...newChartPayload,
            // TODO: fix backend not returning created object
            ...getBasePropsForChart(resp, targetQueryId),
          };

          set(AChartsList, {
            ...charts,
            [newChart.id]: newChart,
          });

          setTab({
            label: `scatter chart`,
            key: newChart.id,
          });
        } else if (viz.type === 'area') {
          const newChartPayload = {
            queryId: targetQueryId,
            ...DefaultAreaChartProps,
          } as AreaChartType;

          const resp = await createChart(newChartPayload);

          const newChart: AreaChartType = {
            ...newChartPayload,
            // TODO: fix backend not returning created object
            ...getBasePropsForChart(resp, targetQueryId),
          };

          set(AChartsList, {
            ...charts,
            [newChart.id]: newChart,
          });

          setTab({
            label: `scatter chart`,
            key: newChart.id,
          });
        } else if (viz.type === 'number') {
          const emptyChart = {
            queryId: targetQueryId,
            ...DefaultNumberChart,
          } as NumberChartType;

          const resp = await createChart(emptyChart);

          const newChart: NumberChartType = {
            ...getBasePropsForChart(resp, targetQueryId),
            ...emptyChart,
          };
          set(AChartsList, {
            ...charts,
            [newChart.id]: newChart,
          });
          setTab({
            label: `${DefaultNumberChart.type} chart`,
            key: newChart.id,
          });
        } else if (viz.type === 'table') {
          const newTableChart = {
            queryId: targetQueryId,
            ...DefaultTableChart,
            uiOptions: DefaultTableChartUiOptions,
          } as TableChartEntity;

          const resp = await createChart(newTableChart);

          const newChart: TableChartEntity = {
            ...getBasePropsForChart(resp, targetQueryId),
            ...newTableChart,
          };
          set(AChartsList, {
            ...charts,
            [newChart.id]: newChart,
          });
          setTab({
            label: `${DefaultTableChart.type} chart`,
            key: newChart.id,
          });
        } else {
          toast.warn('Coming soon');
        }

        setIsAddingChart(false);
      },
    [queryId, charts]
  );

  return [addChart, isAddingChart] as const;
};
