import { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { BiGitRepoForked, BiRocket } from 'react-icons/bi';
import { BsFillStarFill } from 'react-icons/bs';
import {
  RiLoginCircleLine,
  RiSettings4Line,
  RiShareBoxFill,
} from 'react-icons/ri';
import { MdOutlineAddBox } from 'react-icons/md';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isEmpty, pick } from 'lodash';

import { SelectedDBState } from '../database/DatabaseState';
import {
  AQueryRunStartAt,
  HighlightedTextState,
  SQueryResults,
} from './QueryState';
import LoadingIndicator from '../../components/LoadingIndicator';
import { AQueryParams } from './QueryPadState';
import {
  TemporaryQueryResultId,
  useRunHighlightedQuery,
} from './QueryBuilderHooks';
import usePrompt from '../../hooks/router';
import { useQueryClone } from './QueryBuilderCloneHooks';
import StopWatchV3 from './StopWatchV3';
import QueryPadV3 from './QueryPadV3';
import CommandsContainerV3 from './CommandsContainerV3';
import { APIIcon, SQLIconV2 } from '../../components/icons/Icons';
import QueryBuilderResultsV3 from './QueryBuilderResultsV3';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import {
  useQueryIdV3,
  useQueryPadInjectV3,
  useTextToSql,
} from './QueryPadStateV3';
import QueryBuilderSettingModal from './QueryBuilderSettingModal';
import QueryBuilderPublishModal from './QueryBuilderPublishModal';
import QueryBuilderCreateApiModal from './QueryBuilderCreateApiModal';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { DefaultAutoDismissMs } from '../../config/toast';
import useAuth from '../../hooks/auth';
import { useQueryRunV3 } from './QueryBuilderHooksV3';
import ProfileAvatar from '../auth/ProfileAvatar';
import produce from 'immer';
import QueryBuilderRunButtonV3 from './components/QueryBuilderRunButtonV3';
import { convertNumberToFormatted, DateTimeFormat } from '../../utils/date';
import { formatDistanceToNow } from 'date-fns';
import QueryBuilderUnpublishModal from './QueryBuilderUnpublishModal';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import QueryBuilderLeftPanel from './QueryBuilderLeftPanel';
import Modal from '../../components/modals/Modal';
import useDragX from '../../hooks/use-drag-x';

export default function QueryBuilderV3() {
  const queryId = useQueryIdV3();

  // selector states
  const [selectedDB] = useRecoilState(SelectedDBState);

  const [{ menu, isMyWorkspace }, { getPath }] = useWorkspace();

  // query states
  const [queryStartAt] = useRecoilState(AQueryRunStartAt);
  const [highlightedText] = useRecoilState(HighlightedTextState);
  const tempResult = useRecoilValue(SQueryResults(TemporaryQueryResultId));
  const queryResults = useRecoilValue(SQueryResults(queryId)) || tempResult;

  // query params
  const [queryParams] = useRecoilState(AQueryParams);

  const [
    {
      updateRpcState,
      queryValues,
      forkedQuery,
      isQueryDataLoading,
      isQueryRunning: isQueryByIdRunning,
      rpcStatusDel,
    },
    { handleRun, saveQueryDeb, deleteQuery },
  ] = useQueryRunV3({ queryPath: `${getPath(menu)}` });

  const queryRunParams = {
    ...pick(queryValues, ['displayName', 'id', 'text']),
    database: selectedDB,
    queryParams,
  };

  const [, { getIsOwner }] = useAuth();
  const isAuthor = !queryId || getIsOwner(queryValues);

  const [runHighlightedQuery, { isQueryRunning: isHighlightedQueryRunning }] =
    useRunHighlightedQuery();

  const isQueryRunning = isQueryByIdRunning || isHighlightedQueryRunning;

  usePrompt(
    'Are you sure to leave the page?',
    isQueryDataLoading || updateRpcState?.isLoading
  );

  const [clone, { isCloning }] = useQueryClone({
    queryPath: `${getPath(menu)}`,
  });

  const [{ formatQuery }] = useQueryPadInjectV3();

  const handleFormat = useCallback(() => {
    formatQuery();
  }, [queryValues.text]);

  const handleAddParam = useCallback(() => {
    const { view } = window;

    if (!view) return;

    const from = view.state.selection.ranges[0].from || 0;
    const to = view.state.selection.ranges[0].to || 0;

    const newStr = `{{param${queryParams.length + 1}}}`;
    const tr = view.state.update(
      {
        changes: [
          { from, to, insert: '' },
          { from, insert: newStr },
        ],
        selection: { anchor: from + newStr.length },
      },
      {
        scrollIntoView: true,
      }
    );

    view.dispatch(tr);
    view.focus();
  }, [queryParams]);

  const [, { toggleIsOpenTTS }] = useTextToSql(queryId);

  const [openSetting, setOpenSetting] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);
  const [openCreateApi, setOpenCreateApi] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const forkedQueryAuthorName = forkedQuery?.creator?.name;
  const forkedQueryName = forkedQuery?.displayName;
  const forkedQueryAuthorId = forkedQuery?.creator?.id;

  const [leftPanelWidth, setLeftPanelWidth] = useState(368);
  const [dragWidth, setDragWidth] = useState(0);
  const [dragWidthValidated, setDragWidthValidated] = useState(0);

  const refLeftPanelHandle = useRef<HTMLDivElement>(null);
  useDragX(refLeftPanelHandle, {
    onDraging: (moved) => {
      const nWidth = leftPanelWidth + dragWidth;

      setDragWidth(moved);
      if (nWidth > 320 && nWidth < 450) {
        setDragWidthValidated(moved);
      }
    },
    onDragEnd: () => {
      setLeftPanelWidth(leftPanelWidth + dragWidthValidated);
      setDragWidth(0);
      setDragWidthValidated(0);
    },
  });

  // @ts-ignore
  const published = queryValues.access === 'public';

  if (isQueryDataLoading && isEmpty(queryValues)) {
    return (
      <LayoutBodyCard className='p-40'>
        <LogoBlinkerCenter />
      </LayoutBodyCard>
    );
  }

  const isCreateApiReady = queryId && isAuthor && isMyWorkspace;

  return (
    <>
      <div className='flex'>
        <div
          className={classNames('flex-auto flex items-center space-x-3', {
            'overflow-hidden': !forkedQueryAuthorName || published,
          })}
        >
          <div className='flex-none'>
            <SQLIconV2 className='w-8' />
          </div>
          {!isAuthor ? (
            <div className='flex flex-col w-full items-start'>
              <div className='font-bold whitespace-nowrap'>
                {queryValues.displayName || queryValues.id}
              </div>
              {queryValues.creator?.name && (
                <div className='flex items-center text-sm font-semibold gap-1'>
                  <ProfileAvatar
                    className='bg-primary w-4'
                    img={queryValues.creator?.profileImage}
                  />
                  <div className='opacity-50'>{queryValues.creator?.name}</div>
                  <div className='opacity-50'>
                    •{' '}
                    {convertNumberToFormatted(queryValues.createdTime, {
                      formatStr: DateTimeFormat,
                    })}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className={classNames('flex-auto w-full relative items-center', {
                'max-w-[24rem]':
                  isAuthor || (forkedQueryAuthorName && !published),
                'overflow-hidden ': !forkedQueryAuthorName || published,
              })}
            >
              {!published ? (
                <div className='relative z-30 w-full'>
                  <input
                    type='text'
                    className='input w-full max-w-[24rem] pr-[4.4rem] no-outline'
                    value={queryValues.displayName}
                    maxLength={125}
                    onChange={(e) =>
                      saveQueryDeb(
                        produce(queryValues, (draft) => {
                          draft.displayName = e.target.value;
                        })
                      )
                    }
                  />
                  <span className='absolute right-[0.4rem] self-center bg-base-content bg-opacity-20 py-1 px-2 text-xs rounded opacity-50 font-bold top-[0.4rem]'>
                    Private
                  </span>
                </div>
              ) : (
                <div className='flex-auto flex items-center space-x-2 overflow-hidden pr-2'>
                  <span
                    title={queryValues.displayName}
                    className='font-bold text-lg whitespace-nowrap text-ellipsis overflow-hidden'
                  >
                    {queryValues.displayName}
                  </span>
                  {queryValues.publishedTime !== 0 && (
                    <span className='flex-none text-sm opacity-50'>
                      published{' '}
                      {formatDistanceToNow(queryValues.publishedTime || 0, {
                        addSuffix: true,
                      })}
                    </span>
                  )}
                </div>
              )}
              {!published && forkedQueryAuthorName && (
                <div className='z-20 relative w-full h-0'>
                  <div className='z-20 absolute w-full h-full bottom-[1.1rem]'>
                    <div className='flex flex-nowrap pt-[1.2rem] px-2 text-xs rounded bg-base-content bg-opacity-10'>
                      <span className='opacity-60 flex-shrink-0 pr-1'>
                        forked from
                      </span>
                      <span className='text-primary whitespace-nowrap overflow-ellipsis overflow-hidden'>
                        @{forkedQueryAuthorName}
                        {forkedQueryName
                          ? `/${forkedQueryName}`
                          : `/${forkedQueryAuthorId}`}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div className='flex items-center flex-none'>
          {updateRpcState.isLoading && (
            <div className='px-3'>
              <LoadingIndicator />
            </div>
          )}

          <div className='flex space-x-3'>
            {queryId && (
              <>
                {isAuthor && (
                  <>
                    <button
                      className='btn btn-primary gap-2'
                      onClick={() => {
                        setOpenSetting(!openSetting);
                      }}
                    >
                      <RiSettings4Line size='1.1rem' />
                      Setting
                    </button>
                    {openSetting && (
                      <QueryBuilderSettingModal
                        openSetting={openSetting}
                        setOpenSetting={setOpenSetting}
                      />
                    )}

                    {published ? (
                      <>
                        <button
                          className='published-button btn btn-primary gap-2 hover:btn-error font-bold'
                          onClick={() => {
                            setOpenPublish(!openPublish);
                          }}
                        >
                          <BiRocket size='1.1rem' />
                          <span className='when-not-hover'>Published</span>
                          <span className='when-hover'>Unpublish</span>
                        </button>
                        {openPublish && (
                          <QueryBuilderUnpublishModal
                            openPublish={openPublish}
                            setOpenPublish={setOpenPublish}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          className='btn btn-primary gap-2'
                          onClick={() => {
                            setOpenPublish(!openPublish);
                          }}
                        >
                          <BiRocket size='1.1rem' />
                          <span>Publish</span>
                        </button>
                        {openPublish && (
                          <QueryBuilderPublishModal
                            openPublish={openPublish}
                            setOpenPublish={setOpenPublish}
                          />
                        )}
                      </>
                    )}

                    {queryId && (
                      <button
                        className='btn btn-warning'
                        onClick={() => {
                          setOpenDelete(true);
                        }}
                      >
                        <span>Delete</span>
                      </button>
                    )}

                    <div className='border-r my-[0.2rem] border-gray-300' />
                  </>
                )}
                <button className='btn btn-primary items-center gap-2 hidden'>
                  <BsFillStarFill size='1.1rem' />
                  32
                </button>
                <button
                  className={classNames(
                    'flex btn btn-primary items-center gap-2',
                    {
                      hidden: !queryId,
                      loading: isCloning,
                    }
                  )}
                  onClick={() => {
                    clone();
                  }}
                >
                  <BiGitRepoForked size='1.1rem' />{' '}
                  {queryValues.forkCount ? queryValues.forkCount : ''}
                </button>
                <CopyToClipboard text={window.location.href}>
                  <button
                    className='flex btn btn-primary items-center gap-2'
                    onClick={() => {
                      toast.success('Share link copied', {
                        autoClose: DefaultAutoDismissMs,
                      });
                    }}
                  >
                    <RiShareBoxFill size='1.1rem' />
                  </button>
                </CopyToClipboard>
              </>
            )}
          </div>
        </div>
      </div>
      <div className='h-6' />

      {isAuthor && (
        <div className='flex bg-base-content bg-opacity-20 items-center'>
          <div className='flex-1 px-2 font-bold opacity-70'>QUERY BUILDER</div>
          <div>
            <button
              className='btn flex items-center gap-2'
              style={{ borderRadius: 0 }}
              onClick={() => {
                if (!isCreateApiReady) {
                  toast.warn('Please run a query first');
                  return;
                }

                setOpenCreateApi(true);
              }}
            >
              <APIIcon className='w-4' />
              <span>CREATE API</span>
            </button>
            <QueryBuilderCreateApiModal
              open={openCreateApi}
              setOpen={setOpenCreateApi}
            />
          </div>
        </div>
      )}

      <div
        className={classNames('lg:basis-2/5 basis-full bg-base-100', {
          'p-5 rounded-xl': !isAuthor,
        })}
      >
        <div className='flex'>
          {/* Side bar */}
          {isAuthor && (
            <>
              <div
                className='flex-none overflow-visible'
                style={{
                  width: leftPanelWidth + dragWidthValidated,
                }}
              >
                <QueryBuilderLeftPanel />
              </div>
              <div className='divider-y' />
              <div className='w-0 overflow-visible z-40 select-none'>
                <div
                  ref={refLeftPanelHandle}
                  className='w-[5px] h-full cursor-grab'
                ></div>
              </div>
            </>
          )}

          <div className='flex-auto min-w-0'>
            <div>
              {!isAuthor && (
                <div className='opacity-40 font-bold text-sm mb-2'>QUERY</div>
              )}
              <QueryPadV3
                readOnly={!isAuthor}
                saveQueryDeb={saveQueryDeb}
                isQueryRunning={isQueryRunning}
                runQuery={() => {
                  handleRun(queryRunParams);
                }}
              />
              {isAuthor && (
                <div className='flex justify-between bg-base-300 border-t'>
                  <div className='flex'>
                    <div className={'flex opacity-80'}>
                      <CommandsContainerV3 />
                      <div className='border-r' />
                      <button
                        className='btn btn-sm btn-ghost'
                        style={{ background: 'transparent' }}
                        onClick={handleFormat}
                      >
                        FORMAT
                      </button>
                      <div className='border-r' />
                      <button
                        className='btn btn-sm btn-ghost'
                        style={{ background: 'transparent' }}
                        onClick={handleAddParam}
                      >
                        <MdOutlineAddBox size='0.9rem' className='mr-1' />
                        PARAMETER
                      </button>
                      <div className='border-r' />
                      <button
                        className='btn btn-sm btn-ghost'
                        style={{ background: 'transparent' }}
                        onClick={() => {
                          toggleIsOpenTTS();
                        }}
                      >
                        <RiLoginCircleLine size='0.9rem' className='mr-1' />
                        TEXT TO SQL
                      </button>
                      <div className='border-r' />
                    </div>
                  </div>
                  <div className='flex items-center space-x-5'>
                    <div className='flex items-center opacity-80'>
                      <span className='mr-1 text-xs'>Cache TTL</span>
                      <select
                        className='select select-xs bg-transparent font-normal active:outline-none focus:outline-none'
                        value={queryValues.resultCacheExpireMillis}
                        onChange={async (e) => {
                          const newTtl = parseInt(e.target.value, 10);
                          saveQueryDeb({
                            ...queryValues,
                            resultCacheExpireMillis: newTtl,
                          });
                        }}
                      >
                        {[
                          { value: 60 * 1000, label: '1 min' },
                          { value: 3 * 60 * 1000, label: '3 mins' },
                          { value: 10 * 60 * 1000, label: '10 mins' },
                          { value: 30 * 60 * 1000, label: '30 mins' },
                          { value: 60 * 60 * 1000, label: '1 hr' },
                          { value: 3 * 60 * 60 * 1000, label: '3 hrs' },
                          { value: 6 * 60 * 60 * 1000, label: '6 hrs' },
                          { value: 12 * 60 * 60 * 1000, label: '12 hrs' },
                          { value: 24 * 60 * 60 * 1000, label: '24 hrs' },
                        ].map((n) => (
                          <option key={n.value} value={n.value}>
                            {n.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <StopWatchV3
                      startedAt={queryStartAt}
                      endedAt={
                        isQueryRunning
                          ? null
                          : queryResults?.results?.resultReceivedAt
                      }
                    />
                    <QueryBuilderRunButtonV3
                      style={{ borderRadius: 0 }}
                      isQueryRunning={isQueryRunning}
                      hasHighlightedText={!!highlightedText}
                      onClick={() => {
                        if (highlightedText) {
                          runHighlightedQuery({
                            ...queryRunParams,
                            queryId:
                              queryRunParams.id || TemporaryQueryResultId,
                            query: highlightedText,
                            selectedDB: queryRunParams.database,
                          });
                        } else {
                          handleRun(queryRunParams);
                        }
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <QueryBuilderResultsV3
              runQuery={() => {
                handleRun(queryRunParams);
              }}
            />
          </div>
        </div>
      </div>

      <Modal
        open={openDelete}
        className='flex flex-col space-y-4'
        onClickBackdrop={() => setOpenDelete(false)}
      >
        <h3 className='flex items-center space-x-2 font-bold text-lg'>
          <span>Deleting the query</span>
        </h3>
        <div className='flex-1 pb-2 overflow-y-auto max-h-[50vh] space-y-1'>
          Are you sure?
        </div>
        <div className='modal-action items-center'>
          <button
            type='button'
            className='btn btn-outline'
            onClick={async () => {
              setOpenDelete(false);
            }}
          >
            CANCEL
          </button>
          <button
            type='button'
            className={classNames('btn btn-warning', {
              loading: rpcStatusDel.isLoading,
            })}
            onClick={async () => {
              const success = await deleteQuery();
              if (success) {
                setOpenDelete(false);
              }
            }}
          >
            DELETE
          </button>
        </div>
      </Modal>
    </>
  );
}
