import { memo, useEffect } from 'react';

import './api-query-pad.scss';
import { useApiQueryPad } from './ApiQueryPadHooks';

function ApiQueryPad() {
  const [{ editor }, { setContainer, view }] = useApiQueryPad({
    readOnly: true,
  });

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [editor.current]);

  useEffect(() => {
    if (view) {
      window.view = view;
    }
    const interval = setInterval(() => {
      if (view) {
        window.view = view;
      }
    }, 500);
    return () => clearInterval(interval);
  }, [view]);

  return (
    <div className='overflow-auto flex flex-col relative readonly h-full'>
      <div
        key='editor'
        className='flex-auto overflow-auto'
        ref={editor as React.RefObject<HTMLDivElement>}
      />
    </div>
  );
}

export default memo(ApiQueryPad);
