import { BsFileEarmarkPlusFill } from 'react-icons/bs';
import classNames from 'classnames';
import { DOMAttributes, memo, useCallback, useState } from 'react';

import { ChartEntity } from '../../chart/types';
import { MdClose } from 'react-icons/md';
import Modal from '../../../components/modals/Modal';

export type StaticTabLabelTypes =
  | 'results'
  | 'selected query'
  | 'query history'
  | '+ new visualization';

type ConvertToChartLabel<Type> = Type extends string ? `${Type} chart` : never;

type ChartTabLabelTypes = ConvertToChartLabel<ChartEntity['type']>;

export type TabLabelTypes = StaticTabLabelTypes | ChartTabLabelTypes;

export type StaticTabType = {
  label: TabLabelTypes;
  key?: string;
};

export type SetTabType = (nTab: StaticTabType) => void;

const editableActive = 'px-4 text-primary opacity-100 bg-gray-300';
const editableInactive = 'opacity-60';

const readonlyActive =
  'border-b border-b-primary px-0 text-primary opacity-100 bg-unset';
const readonlyInactive = 'border-b px-0 opacity-60';

function TabButton({
  tab,
  currentTab,
  setCurrentTab,
  onRemove,
  isEditable,
  activeClassName,
}: DOMAttributes<HTMLButtonElement> & {
  tab: StaticTabType;
  activeClassName?: string;
  currentTab: StaticTabType;
  setCurrentTab: SetTabType;
  isEditable?: boolean;
  onRemove?: (() => void) | null;
}) {
  const [open, setOpen] = useState(false);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const isActive =
    (tab.key || tab.label) === (currentTab.key || currentTab.label);

  const activeCls = isEditable ? editableActive : readonlyActive;
  const inactiveCls = isEditable ? editableInactive : readonlyInactive;

  return (
    <div
      className={classNames(
        'flex tab h-auto',
        isActive ? activeCls : inactiveCls
      )}
    >
      <button
        className={classNames(
          'font-bold uppercase py-0',
          isActive ? activeClassName : ''
        )}
        onClick={() => {
          setCurrentTab(tab);
        }}
      >
        {tab.label === '+ new visualization' ? (
          <span className='flex items-center gap-2'>
            <BsFileEarmarkPlusFill size='.8rem' />
            new visualization
          </span>
        ) : (
          tab.label
        )}
      </button>
      {onRemove && (
        <>
          <div className='w-2' />
          <button
            onClick={() => {
              setOpen(true);
            }}
          >
            <MdClose />
          </button>
          <Modal
            open={open}
            className='flex flex-col space-y-4'
            onClickBackdrop={() => setOpen(false)}
          >
            <h3 className='flex items-center space-x-2 font-bold text-lg'>
              <span>Deleting the chart</span>
            </h3>
            <div className='flex-1 pb-2 overflow-y-auto max-h-[50vh] space-y-1'>
              Are you sure?
            </div>
            <div className='modal-action items-center'>
              <button
                type='button'
                className='btn btn-outline'
                onClick={() => {
                  handleClose();
                }}
              >
                CANCEL
              </button>
              <button
                type='button'
                className={classNames('btn btn-warning')}
                onClick={() => {
                  onRemove();
                  handleClose();
                }}
              >
                DELETE
              </button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
}

export default memo(TabButton);
