import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { useState } from 'react';
import { HiMenuAlt2 } from 'react-icons/hi';
import ProfileMenu from './components/ProfileMenu';
import Logo from '../components/app/Logo';
import { useAppConfig } from '../hooks/app-config';
import LogoIcon from '../components/app/LogoIcon';
import WorkspaceSelector from './components/WorkspaceSelector';
import CreateMenu from './components/CreateMenu';
import { useWorkspace } from './components/workspace-hooks';

export const RoutePaths = {
  projects: 'projects',
};

type NavItemType = {
  name: string;
  path?: string;
  badgeLabel?: string;
  extPath?: string;
};
const NAV_ITEMS: NavItemType[] = [
  { name: 'Dashboards', path: '/dashboards' },
  { name: 'Projects', path: `/${RoutePaths.projects}`, badgeLabel: 'alpha' },
  { name: 'New Query', path: '/query-builder' },
  { name: 'Connectors', path: '/connectors' },
  {
    name: 'Docs',
    extPath: 'https://docs.zettablock.com/',
  },
];

const NAV_ITEMS_V3: NavItemType[] = [
  { name: 'Explorer', path: '/queries' },
  // { name: 'API Playground', path: 'api-playground' },
  { name: 'API Key', path: '/api-key' },
  {
    name: 'Docs',
    extPath: 'https://docs.zettablock.com/',
  },
];

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const [{ isV3 }] = useAppConfig();
  const [{ isMyWorkspace }] = useWorkspace();

  const NavItems = isV3
    ? NAV_ITEMS_V3.map((i) => ({
        ...i,
        path: `/${isMyWorkspace ? 'my-workspace' : 'community'}${i.path}`,
      }))
    : NAV_ITEMS;

  return (
    // Lock the width [responsive]
    // https://datalego.atlassian.net/browse/DL-353
    <div className='mx-auto navbar px-0 py-7'>
      <div className='hidden pr-2'>
        <div className='dropdown'>
          <button
            className='btn btn-ghost'
            onClick={() => {
              setOpenMenu(true);
            }}
          >
            <HiMenuAlt2 size='1.4rem' />
          </button>
          <ul
            className={classNames(
              'menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52',
              openMenu ? 'block' : 'hidden'
            )}
          >
            {NavItems.map((nav) => (
              <li key={nav.name}>
                {nav.extPath ? (
                  <a
                    href={nav.extPath}
                    {...(nav.extPath && {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    })}
                    className='text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5'
                  >
                    {nav.name}
                  </a>
                ) : (
                  <NavLink
                    onClick={() => {
                      setOpenMenu(false);
                    }}
                    to={`${nav.path}`}
                    className={({ isActive }) => {
                      return classNames(
                        'text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5',
                        {
                          'bg-white text-blue-600': isActive,
                        }
                      );
                    }}
                  >
                    {nav.name}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className='flex-none'>
        <button type='button' className='text-3xl font-bold text-primary'>
          {!isV3 ? (
            <NavLink to={'/dashboards'}>
              <Logo />
            </NavLink>
          ) : (
            <NavLink to={'/my-workspace'}>
              <LogoIcon />
            </NavLink>
          )}
        </button>
      </div>

      <div className='flex-none flex px-7'>
        {isV3 && <WorkspaceSelector />}
        <ul className='menu menu-horizontal p-0'>
          {NavItems.map((nav) => {
            return (
              <li key={nav.name}>
                {nav.extPath ? (
                  <a
                    href={nav.extPath}
                    {...(nav.extPath && {
                      target: '_blank',
                      rel: 'noopener noreferrer',
                    })}
                    className='text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5'
                  >
                    {nav.name}
                  </a>
                ) : (
                  <NavLink
                    to={
                      nav.path || `/${nav.name.toLowerCase().replace(' ', '-')}`
                    }
                    className={({ isActive }) => {
                      return classNames(
                        'text-[1.0rem] font-semibold btn-sm mr-1 text-gray-600 px-4 py-5',
                        {
                          'bg-white text-blue-600': isActive,
                        }
                      );
                    }}
                  >
                    {nav.name}

                    {nav.badgeLabel && (
                      <span className='absolute indicator-item badge badge-sm badge-secondary top-[-0.3rem] right-[-0.3rem]'>
                        {nav.badgeLabel}
                      </span>
                    )}
                  </NavLink>
                )}
              </li>
            );
          })}
        </ul>
      </div>

      <div className='flex flex-1 space-x-3'>
        <div className='flex-1' />
        {
          // TODO: enable theme when it's ready
          // <div className='flex-none h-7 px-4'>
          //   <ThemeSwitch />
          // </div>
        }
        {isV3 && <CreateMenu />}
        <div className='flex justify-center'>
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
}
