import 'react-toastify/dist/ReactToastify.css';
import { ApolloProvider } from '@apollo/client';
import { enableMapSet } from 'immer';
import { RecoilRoot } from 'recoil';
import { Helmet } from 'react-helmet';

import { createClient } from './modules/apollo/apollo';
import {
  getRecoilState,
  RecoilExternalStatePortal,
} from './modules/recoil/utils';
import ToastContainerConfig from './modules/toast/ToastContainerConfig';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useCookies } from 'react-cookie';
import envs from './config/envs';
import { AuthState } from './modules/auth/AuthState';
import AppRoutes from './Routes';

enableMapSet();

const client = createClient();

export default function App() {
  const auth = getRecoilState(AuthState);
  const [cookies] = useCookies(['auth']);

  return (
    <RecoilRoot key={auth?.id || cookies.auth?.username || ''}>
      <GoogleOAuthProvider clientId={envs.GoogleClientID || ''}>
        <ApolloProvider client={client}>
          <Helmet>
            <title>
              {'Zettablock - Blockchain Data APIs and Infrastructure'}
            </title>
          </Helmet>

          <AppRoutes />

          <ToastContainerConfig />
          <RecoilExternalStatePortal />
        </ApolloProvider>
      </GoogleOAuthProvider>
    </RecoilRoot>
  );
}
