import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import '../../css/blinker.css';
import Logo from './Logo';

export default function FullPageLoading({
  className,
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        'flex items-center justify-center w-full h-screen',
        className
      )}
    >
      <Logo className='blink-s1' />
    </div>
  );
}
