import { memo, useState } from 'react';
import {
  RiHomeFill,
  RiSettings4Line,
  RiShareBoxFill,
  RiSwapFill,
} from 'react-icons/ri';
import { formatDistanceToNow } from 'date-fns';
import { BiCopy, BiGitRepoForked, BiRocket } from 'react-icons/bi';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { NavLink, Route, Routes } from 'react-router-dom';
import produce from 'immer';

import { useEditApi } from './ViewApiHooks';
import { DefaultAutoDismissMs } from '../../config/toast';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import envs from '../../config/envs';
import ViewApiOverview from './ViewApiOverview';
import ApiSettingModal from './ApiSettingModal';
import ApiPublishModal from './ApiPublishModal';
import { useApiClone } from './ApiCloneHooks';
import ApiUnpublishModal from './ApiUnpublishModal';
import ApiInfoModal from './ApiInfoModal';
import ViewApiLogs from './ViewApiLogs';
import GraphqlV3 from '../dataset/tabs/GraphqlV3';
import useAuth from '../../hooks/auth';

const TAB_LINKS = [
  {
    path: 'overview',
    title: 'Overview',
    icon: <RiHomeFill />,
  },
  {
    path: 'playground',
    title: 'Playground',
    icon: <RiHomeFill />,
  },
  {
    title: 'Logs',
    path: 'logs',
    icon: <RiHomeFill />,
  },
];

function ViewApi() {
  const [{ apiId, rpcStatus, values }, { setValues }] = useEditApi();
  const [{ menu }, { getPath }] = useWorkspace();

  const [openSetting, setOpenSetting] = useState(false);
  const [openPublish, setOpenPublish] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  // TODO repalce api clone
  const [clone, { isCloning }] = useApiClone({
    queryPath: `${getPath(menu)}`,
  });

  // @ts-ignore
  const published = values.access === 'public';
  const [, { getIsOwner }] = useAuth();
  const isAuthor = getIsOwner(values);

  return (
    <>
      <div className='flex'>
        <div className='flex-auto flex items-center space-x-3 overflow-hidden'>
          <div>
            <RiSwapFill size='2.2rem' className='text-primary' />
          </div>
          {isAuthor && !published ? (
            <div className='w-full min-w-[24rem] max-w-md relative items-center'>
              <input
                type='text'
                className='input w-full min-w-[24rem] pr-[4.4rem] font-bold text-lg'
                value={values.displayName || ''}
                onChange={(e) =>
                  setValues(
                    produce(values, (draft) => {
                      draft.displayName = e.target.value;
                    })
                  )
                }
              />
              <span className='absolute right-[0.4rem] self-center bg-base-content bg-opacity-20 py-1 px-2 text-xs rounded opacity-50 font-bold top-[0.4rem]'>
                Private
              </span>
            </div>
          ) : (
            <div className='flex items-center space-x-2 overflow-hidden pr-2'>
              <span
                title={values.displayName}
                className='flex-auto font-bold text-lg overflow-hidden text-ellipsis whitespace-nowrap'
              >
                {values.displayName}
              </span>
              {values.createdTime && (
                <span className='text-sm opacity-50 flex-shrink-0'>
                  published{' '}
                  {formatDistanceToNow(values.createdTime, {
                    addSuffix: true,
                  })}
                </span>
              )}
            </div>
          )}
        </div>

        <div className='flex items-center flex-none'>
          <div className='flex space-x-3'>
            {apiId && (
              <>
                {isAuthor && (
                  <>
                    <button
                      className='btn btn-primary gap-2'
                      onClick={() => {
                        setOpenSetting(!openSetting);
                      }}
                    >
                      <RiSettings4Line size='1.1rem' />
                      Setting
                    </button>
                    {openSetting && (
                      <ApiSettingModal
                        openSetting={openSetting}
                        setOpenSetting={setOpenSetting}
                      />
                    )}

                    {published ? (
                      <>
                        <button
                          className='published-button btn btn-primary gap-2 hover:btn-error font-bold'
                          onClick={() => {
                            setOpenPublish(!openPublish);
                          }}
                        >
                          <BiRocket size='1.1rem' />
                          <span className='when-not-hover'>Published</span>
                          <span className='when-hover'>Unpublish</span>
                        </button>
                        {openPublish && (
                          <ApiUnpublishModal
                            openPublish={openPublish}
                            setOpenPublish={setOpenPublish}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <button
                          className='btn btn-primary gap-2'
                          onClick={() => {
                            setOpenPublish(!openPublish);
                          }}
                        >
                          <BiRocket size='1.1rem' />
                          <span>Publish</span>
                        </button>
                        {openPublish && (
                          <ApiPublishModal
                            openPublish={openPublish}
                            setOpenPublish={setOpenPublish}
                          />
                        )}
                      </>
                    )}

                    <div className='border-r my-[0.2rem] border-gray-300' />
                  </>
                )}
                <span className='hidden'>
                  <button
                    className={classNames(
                      'flex btn btn-primary items-center gap-2',
                      {
                        hidden: !apiId,
                        loading: isCloning,
                      }
                    )}
                    onClick={() => {
                      clone();
                    }}
                  >
                    <BiGitRepoForked size='1.1rem' />
                  </button>
                </span>
                <CopyToClipboard text={window.location.href}>
                  <button
                    className='flex btn btn-primary items-center gap-2'
                    onClick={() => {
                      toast.success('Share link copied', {
                        autoClose: DefaultAutoDismissMs,
                      });
                    }}
                  >
                    <RiShareBoxFill size='1.1rem' />
                  </button>
                </CopyToClipboard>
              </>
            )}
          </div>
        </div>
        {/* <div className='flex items-center'>
          <div className='flex space-x-3'>
            <button
              className='btn btn-primary gap-2'
              onClick={() => {
                updateApi();
                // createNewApi();
              }}
            >
              <RiSkipForwardLine size='1.1rem' />
              Create API
            </button>
          </div>
        </div> */}
      </div>
      <div className='h-6' />

      <div className='flex min-w-[40rem]'>
        <div className='rounded-l-lg inline-block border-r bg-primary-content'>
          <span
            className='inline-block text-xs opacity-30 font-semibold px-1 pb-3 pt-1 text-center justify-center'
            style={{ writingMode: 'vertical-lr', transform: 'rotate(180deg)' }}
          >
            INFO
          </span>
        </div>
        <div className='rounded-r-lg bg-primary-content text-sm'>
          <div className='flex flex-col gap-1 py-2 px-3'>
            <div className='text-xs font-bold opacity-30'>GraphQL URL</div>
            <CopyToClipboard
              text={`${envs.ZettaBackendApiPrivate}/userapis/${apiId}/graphql`}
            >
              <div
                className='flex gap-2 items-center cursor-pointer'
                onClick={() => {
                  setOpenInfoModal(true);
                }}
              >
                <div className='flex-1'>{`/userapis/${apiId}/graphql`}</div>
                <BiCopy size='.8rem' className='opacity-40' />
              </div>
            </CopyToClipboard>

            {openInfoModal && (
              <ApiInfoModal open={openInfoModal} setOpen={setOpenInfoModal} />
            )}
          </div>
        </div>
      </div>

      <div className='h-6' />

      <div className='lg:basis-2/5 basis-full bg-base-100 rounded-xl'>
        <div className='flex border-b'>
          <div className='flex py-2 px-4'>
            {TAB_LINKS.map((tab) => (
              <NavLink
                key={tab.title}
                className={({ isActive }) =>
                  classNames(
                    'flex mr-4 px-4 py-2 items-center rounded-xl',
                    isActive
                      ? 'bg-base-content bg-opacity-10 text-primary font-bold'
                      : 'opacity-40'
                  )
                }
                to={`${getPath(menu)}/${apiId}/${tab.path}`}
              >
                {tab.icon} <span className='ml-2'>{tab.title}</span>
              </NavLink>
            ))}
          </div>
        </div>

        <Routes>
          <Route path='playground' element={<GraphqlV3 />} />
          <Route path='logs' element={<ViewApiLogs />} />
          <Route
            path='overview'
            element={<ViewApiOverview rpcStatus={rpcStatus} />}
          />
        </Routes>
      </div>
    </>
  );
}

export default memo(ViewApi);
