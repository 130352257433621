import classNames from 'classnames';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router';

import { AuthApi } from '../../api/client';
import { ActiveLink } from '../../components/ActiveLink';
import { UserAvatar } from '../../modules/auth/UserAvatar';
import useAuth from '../../hooks/auth';

export default function ProfileMenu() {
  const [cookies, , removeCookie] = useCookies(['auth']);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [{ auth, isSignedIn }, { setAuth }] = useAuth();

  function handleClick() {
    setOpen(false);
  }

  const authMouseDown = async () => {
    if (!isSignedIn) {
      navigate('/auth/login');
    } else {
      if (auth) {
        const authApi = AuthApi();
        try {
          const resp = await authApi.logoutCreate();
          if (resp.data.success) {
            setAuth(null);
          }
        } catch (error) {
          setAuth(null);
        }
      }
      if (cookies.auth) {
        removeCookie('auth', { path: '/' });
      }
    }
    handleClick();
  };

  return (
    <div
      className='dropdown dropdown-end'
      onBlur={() => {
        setOpen(false);
      }}
    >
      <button
        className='flex flex-1 space-x-3'
        onClick={() => {
          setOpen(!open);
        }}
      >
        <UserAvatar />
      </button>
      <ul
        className={classNames(
          'mt-3 p-2 shadow bg-base-100 rounded-box w-52',
          open ? 'menu menu-compact dropdown-content ' : 'hidden'
        )}
      >
        <li>
          <ActiveLink
            to='/'
            onMouseDown={authMouseDown}
            activeNames='active'
            className='justify-between'
          >
            {isSignedIn ? 'Logout' : 'Login'}
          </ActiveLink>
        </li>
      </ul>
    </div>
  );
}
