import { useCodeMirror } from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { format, FormatFnOptions, SqlLanguage } from 'sql-formatter';
import { useParams } from 'react-router';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { useCallback, useRef } from 'react';

import { HighlightedTextState, QueryState } from './QueryState';
import { DatabaseCache, SelectedDBState } from '../database/DatabaseState';
import { TParamMap, TParamState } from './types';
import { useSearchParams } from 'react-router-dom';

export const AQueryParamDefaultValuesCache = atom<TParamMap>({
  key: 'AQueryParamDefaultValuesCache',
  default: {},
});

export const AQueryParams = atom<TParamState>({
  key: 'AQueryParams',
  default: [],
});

export type TParamsStrParsed = {
  params: TParamState;
};

export function useQueryId() {
  const params = useParams();
  const queryId = params?.id;

  return [queryId] as const;
}

export function useSearchParam(key: string) {
  const [searchParams] = useSearchParams();
  const param = searchParams.get(key);

  return param;
}

export function convertParamsToDBParamsStr(params: TParamState) {
  return JSON.stringify({
    params,
  });
}

export function convertParamsArrToMap(params: TParamState): TParamMap {
  return params.reduce((r, param) => {
    r[param.name] = param;
    return r;
  }, {} as TParamMap);
}

export function convertDBParamsStrToParams(paramsStr: string): TParamState {
  try {
    const paramsParsed = JSON.parse(paramsStr) as TParamsStrParsed;
    return paramsParsed.params || [];
  } catch (error) {
    return [];
  }
}

export function useQueryPad({
  readOnly,
}: { readOnly?: boolean } | undefined = {}) {
  const [query, setQuery] = useRecoilState(QueryState);
  const setHighlightedText = useSetRecoilState(HighlightedTextState);
  const editor = useRef<HTMLDivElement>();

  const props = useCodeMirror({
    readOnly,
    container: editor.current,
    extensions: [sql()],
    value: query,
    onChange: (value) => {
      setQuery(value);
    },
    onUpdate: (viewUpdate) => {
      if (readOnly) {
        return;
      }

      const range = viewUpdate.state.selection.ranges[0];
      if (range) {
        const selectedSql = query?.slice(range.from, range.to);
        setHighlightedText(selectedSql);
        return;
      }

      setHighlightedText('');
    },
  });

  return [{ query, setQuery, editor }, props] as const;
}

export function formatSql(query?: string, options?: Partial<FormatFnOptions>) {
  const formatted = format(query || '', {
    language: options?.language || 'mysql',
    tabWidth: 2,
    keywordCase: 'upper',
    linesBetweenQueries: 2,
  });

  return `${formatted} \n\n`;
}

export function useFormatSqlWithType() {
  const [selectedDBId] = useRecoilState(SelectedDBState);
  const cacheValue = DatabaseCache.get('cache');

  const selectedDB = cacheValue?.find((v) => v.id === selectedDBId);

  const formatSqlWithType = useCallback(
    (query = '') => {
      return formatSql(query, {
        language: selectedDB?.sqlDialect as SqlLanguage,
      });
    },
    [formatSql, selectedDB?.sqlDialect]
  );

  return formatSqlWithType;
}
