import { useCodeMirror } from '@uiw/react-codemirror';
import { sql } from '@codemirror/lang-sql';
import { atomFamily, useRecoilState } from 'recoil';
import { useRef } from 'react';
import { useQueryIdSearchParams } from './ViewApiHooks';
import { AQueryDetails } from '../queryBuilder/QueryState';

export const ApiQueryTextState = atomFamily<string, string | undefined | null>({
  key: 'ApiQueryTextState',
  default: '',
});

export function useApiQueryPad({
  readOnly,
}: { readOnly?: boolean } | undefined = {}) {
  const queryId = useQueryIdSearchParams();
  const [queryDetail] = useRecoilState(AQueryDetails(queryId));

  const editor = useRef<HTMLDivElement>();

  const props = useCodeMirror({
    readOnly,
    container: editor.current,
    extensions: [sql()],
    value: queryDetail.text,
  });

  return [{ editor }, props] as const;
}
