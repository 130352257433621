import { memo } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import Modal from '../../components/modals/Modal';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { useQueryId } from './QueryPadState';

function QueryBuilderCreateApiModal({
  open,
  setOpen,
}: {
  open?: boolean;
  setOpen: (v: boolean) => void;
}) {
  const queryId = useQueryId();
  const navigate = useNavigate();
  const [, { getPath }] = useWorkspace();

  return (
    <Modal
      open={open}
      onClickBackdrop={() => {
        setOpen(false);
      }}
      className='max-w-[20rem]'
    >
      <div className='flex flex-col gap-6'>
        <div className='text-center'>
          Build your own GraphQL API with the SQL transformation code here.
        </div>
        <button
          className='btn btn-primary gap-4 w-full'
          onClick={() => {
            if (!queryId) {
              toast.warn('Please create a query first');
              return;
            }

            setOpen(false);
            navigate(`${getPath('apis')}/new?queryId=${queryId}`);
          }}
        >
          Create
        </button>
      </div>
    </Modal>
  );
}

export default memo(QueryBuilderCreateApiModal);
