import GraphiQL from 'graphiql';
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import 'graphiql/graphiql.min.css';
import './GraphqlEditor.css';
import './graphiql.css';
import { useApiId } from '../../api/ViewApiHooks';
import envs from '../../../config/envs';
import { useAuthHeaders } from '../../auth/AuthState';
import { useRunQueryApiV3 } from './GraphqlHooksV3';
import { useMemo, useEffect } from 'react';

interface Props {
  setShowShema?: (showSchema: boolean) => void;
}

function GraphQLEditor({ setShowShema }: Props): JSX.Element {
  const apiId = useApiId();
  const [{ result, graphqlState, setGraphqlState }] = useRunQueryApiV3();

  const response = JSON.stringify(result.result, null, 2);

  const headers = useAuthHeaders();

  const fetcher = useMemo(() => {
    return createGraphiQLFetcher({
      url: `${envs.ZettaBackendApiPrivate}/userapis/${apiId}/graphql`,
      // @ts-ignore
      headers,
    });
  }, []);

  useEffect(() => {
    // opens the schema explorer by default
    (document.querySelector('.docExplorerShow') as HTMLElement)?.click();
    setShowShema?.(true);
  }, []);

  return (
    <div className=' h-[60vh] min-h-[60vh]'>
      <GraphiQL
        // TODO: Dark theme - https://github.com/graphql/graphiql/issues/129
        fetcher={fetcher}
        defaultQuery={graphqlState.query}
        variables={undefined}
        response={response}
        onToggleDocs={() => {
          setShowShema?.(false);
        }}
        onEditQuery={(newQuery) => {
          setGraphqlState({
            ...graphqlState,
            query: newQuery || '',
          });
        }}
      ></GraphiQL>
    </div>
  );
}

export default GraphQLEditor;
