import { memo, ReactElement, useEffect } from 'react';
import classNames from 'classnames';
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilStateLoadable,
  useRecoilValue,
} from 'recoil';

import Selector from '../../components/inputs/Selector';
import LoadingIndicator from '../../components/LoadingIndicator';
import { isEmpty } from 'lodash';
import {
  SchemaCache,
  SchemasQueryState,
  SelectedSchemaState,
} from './SchemaState';
import { ModelSchema } from '../../api/__gen__/data-contracts';

function SchemaSelector({
  value,
  onSelect,
  showLabel,
}: {
  value?: string;
  onSelect: (value: string) => void;
  showLabel?: boolean;
}): ReactElement {
  const [{ state, contents }] = useRecoilStateLoadable(SchemasQueryState);
  const refresh = useRecoilRefresher_UNSTABLE(SchemasQueryState);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const selectedSchema = useRecoilValue(SelectedSchemaState);
  const cacheValue = SchemaCache.get('cache');
  const loading = state === 'loading';
  const list: ModelSchema[] = (loading ? cacheValue : contents) || [];

  useEffect(() => {
    const listNames = list?.map((item) => item.name);

    // when
    // 1 no schema is selected
    // 2 fetched new list (hash compare)
    // 3 selectedItem not found in the list
    if (
      !selectedSchema?.schema ||
      (!isEmpty(listNames) && !listNames?.includes(selectedSchema.schema))
    ) {
      const firstItem = list?.[0]?.name;
      if (firstItem) {
        onSelect(firstItem);
      }
    }
  }, [list, selectedSchema]);

  return (
    <div className={classNames('form-control w-full')}>
      {showLabel && (
        <label className='label flex'>
          <span className='label-text font-semibold opacity-50'>DATABASE</span>
          {loading && <LoadingIndicator />}
        </label>
      )}
      <Selector
        value={value}
        className='select-sm select-bordered'
        options={list
          .filter((d): d is { name: string } => typeof d.name === 'string')
          .map((d) => ({
            label: d.name,
            value: d.name,
          }))}
        onSelect={(e) => {
          onSelect(e.target.value);
        }}
      />
    </div>
  );
}

export default memo(SchemaSelector);
