import classNames from 'classnames';
import { HTMLAttributes, memo } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';

function ProfileAvatar({
  img,
  className = 'bg-neutral-focus w-10',
}: {
  img?: string;
} & HTMLAttributes<HTMLDivElement>) {
  if (!img) {
    return (
      <div className='avatar placeholder'>
        <div
          className={classNames('text-neutral-content rounded-full', className)}
        >
          <BsFillPersonFill size='1rem' />
        </div>
      </div>
    );
  }

  return (
    <div className='avatar placeholder'>
      <div
        className={classNames('text-neutral-content rounded-full', className)}
      >
        <img src={img} referrerPolicy='no-referrer' />
      </div>
    </div>
  );
}

export default memo(ProfileAvatar);
