import { AxiosError } from 'axios';
import { UserApiV2 } from '../../../api/client';

export async function runGraphqlQuery({
  apiId,
  query,
}: {
  apiId: string;
  query: string;
}) {
  try {
    const client = UserApiV2();

    const resp = await client.graphqlCreate(apiId, { query });

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('error running graphql', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}
