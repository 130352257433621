import { AxiosError } from 'axios';
import { DashboardApi } from '../../api/client';
import { ModelCreateDashboardRequest } from '../../api/__gen__/data-contracts';
import {
  convertUIDashboardToUpdateModel,
  DashboardFormData,
} from './dashboard-models';

export async function fetchMyDashboard({ id }: { id: string }) {
  try {
    const client = DashboardApi();
    const resp = await client.dashboardsDetail(id);

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('fetching dashboard error', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function updateMyDashboard(
  id: string,
  dashboard: DashboardFormData
) {
  try {
    const client = DashboardApi();
    const resp = await client.dashboardsCreate2(
      id,
      convertUIDashboardToUpdateModel(dashboard)
    );

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('updating dashboard error', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function deleteMyDashboard(id: string) {
  try {
    const client = DashboardApi();
    const resp = await client.dashboardsDelete(id);

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('deleting dashboard error', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}

export type CreateDashboardFormData = ModelCreateDashboardRequest;

export async function createMyDashboard(formData: CreateDashboardFormData) {
  try {
    const client = DashboardApi();
    const resp = await client.dashboardsCreate(formData);

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('creating dashboard error', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}
