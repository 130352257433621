import { AxiosError } from 'axios';
import { MyApi, UserApiV2 } from '../../api/client';
import {
  ModelCreateUserApiRequest,
  ModelUpdateUserApiRequest,
} from '../../api/__gen__/data-contracts';

export async function postCreateApi(params: ModelCreateUserApiRequest) {
  try {
    const client = UserApiV2();
    const resp = await client.userapisCreate(params);
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getApiDetail(apiId: string) {
  try {
    const client = UserApiV2();
    const resp = await client.userapisDetail(apiId);
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function postUpdateApi(
  apiId: string,
  params: ModelUpdateUserApiRequest
) {
  try {
    const client = UserApiV2();
    const resp = await client.userapisCreate2(apiId, params);
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getFetchMyApis() {
  try {
    const client = MyApi();
    const resp = await client.userapisList();
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getFetchApiLogs(apiId: string) {
  try {
    const client = UserApiV2();
    const resp = await client.eventsDetail(apiId);
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

// TODO change to api clone
export async function postCloneApi({ apiId }: { apiId: string }) {
  try {
    const client = UserApiV2();
    const resp = await client.userapisDetail(apiId);

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getIndexProgress(apiId: string) {
  try {
    const client = UserApiV2();
    const resp = await client.progressDetail(apiId);

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getApiStats(apiId: string) {
  try {
    const client = UserApiV2();
    const resp = await client.statisticsDetail(apiId);

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}
