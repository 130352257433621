import { useGoogleLogin } from '@react-oauth/google';
import { useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { toast } from 'react-toastify';
import { AuthApi, DatabaseApi } from '../../api/client';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import { useRecoilState } from 'recoil';
import { AuthState } from './AuthState';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { isEmpty, keys } from 'lodash';
import { HorizontalLine } from '../../components/HorizontalLine';
import { AxiosError } from 'axios';
import { defaultErrorMessage } from '../error/const';

// TODO temporary login check machanism
const checkAuthorized = async ({ token }: { token: string }) => {
  try {
    const client = DatabaseApi();

    const headers = {
      Authorization: `Basic ${token}`,
    };
    const resp = await client.databasesList({
      // @ts-ignore
      headers,
    });
    return resp.data;
  } catch (error) {
    // eslint-disable-next-line
    console.error('error', error);
  }

  return null;
};

export interface FormValues {
  username?: string;
  password?: string;
}

export default function Login() {
  const [cookies, setCookie] = useCookies(['auth']);
  const [auth, setAuth] = useRecoilState(AuthState);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const back = searchParams.get('back');
  const { register, handleSubmit } = useForm<FormValues>({
    defaultValues: typeof cookies.auth === 'object' ? cookies.auth : {},
  });

  const googleLogin = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const client = AuthApi();
      try {
        const resp = await client.loginGoogleCreate({
          authorizationCode: code,
        });
        if (resp.data.accessToken && resp.data.user?.id) {
          setAuth({
            accessToken: resp.data.accessToken,
            id: resp.data.user.id,
            name: resp.data.user.name,
            email: resp.data.user.email,
            profileImage: resp.data.user.profileImage,
          });
        }
      } catch (error) {
        if (error instanceof AxiosError) {
          const msg = error.response?.data.message;
          toast.error(msg || defaultErrorMessage);
        }
        setAuth(null);
      }
    },
    onError: () => {
      toast.error('Login Failed. Please try again.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    flow: 'auth-code',
  });

  useEffect(() => {
    if (auth || !isEmpty(cookies.auth)) {
      if (back) {
        navigate(back);
      } else {
        navigate('/');
      }
    }
  }, [auth, cookies.auth]);

  return (
    <LayoutBodyCard>
      <div className='p-12 max-w-xl mx-auto space-y-5'>
        <h2 className='mb-12 font-semibold text-4xl'>Login (alpha)</h2>
        <form
          className='w-full'
          onSubmit={handleSubmit(async (values) => {
            /**
             * This is to fix the cookie created without path params.
             * That seems to cause some issue for auth
             * TODO: delete it when we build new auth handling mechanism
             */
            const uCookies = new Cookies();
            const allCookie = uCookies.getAll();

            keys(allCookie).forEach((element) => {
              uCookies.remove(element);
            });

            const token = `${window.btoa(
              `${values.username}:${values.password}`
            )}`;

            // check if user authed successfully
            const databases = await checkAuthorized({ token });
            const items = databases?.items;

            if (items) {
              // success
              toast.info('Login successful');

              // store auth values
              setCookie('auth', values, {
                path: '/',
              });
              if (back) {
                navigate(back);
              } else {
                navigate('/');
              }
            } else {
              // failed
              toast.warning('Please check username or password');
            }
          })}
        >
          <div className='form-control w-full'>
            <label className='label'>
              <span className='label-text'>Username</span>
            </label>
            <input
              type='text'
              className='input input-bordered w-full'
              {...register('username')}
            />
          </div>
          <div className='h-6' />
          <div className='form-control w-full'>
            <label className='label'>
              <span className='label-text'>Password</span>
            </label>
            <input
              type='password'
              className='input input-bordered w-full'
              {...register('password')}
            />
          </div>
          <div className='h-6' />
          <div className='flex justify-center w-full'>
            <button type='submit' className='btn btn-primary w-full'>
              Login
            </button>
          </div>
        </form>
        <div className='h-2' />
        <div className='w-full inline-flex items-center'>
          <HorizontalLine className='flex-1 h-0' />
          <span className='mx-5'>or</span>
          <HorizontalLine className='flex-1 h-0' />
        </div>
        {isEmpty(cookies.auth) && (
          <button
            className='btn btn-outline gap-5 w-full normal-case text-xl'
            onClick={() => googleLogin()}
          >
            <FcGoogle size='1.5rem' />
            Continue with Google
          </button>
        )}
      </div>
    </LayoutBodyCard>
  );
}
