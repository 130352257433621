import { AxiosError } from 'axios';
import { values } from 'lodash';
import { atom, DefaultValue, selectorFamily } from 'recoil';
import { ChartApi } from '../../api/client';
import { ChartEntity } from '../chart/types';
import { convertUIChartToDBChart } from '../chart/utils';

export const AChartsList = atom<Record<string, ChartEntity>>({
  key: 'AChartsList',
  default: {},
});

export const SelectChartByIdState = selectorFamily<
  ChartEntity | undefined,
  {
    id: string;
  }
>({
  key: 'SelectChartByIdState',
  get:
    (param) =>
    ({ get }) => {
      const chartMapById = get(AChartsList);
      const chart = values(chartMapById).find(
        (chartItem) => chartItem.id === param.id
      );
      return chart;
    },
  set:
    (param) =>
    ({ get, set }, nValue) => {
      if (!(nValue instanceof DefaultValue) && nValue) {
        const chartMapById = get(AChartsList);
        set(AChartsList, {
          ...chartMapById,
          [param.id]: nValue,
        });
      }
    },
});

export async function removeChart({ chartId }: { chartId: string }) {
  try {
    const client = ChartApi();
    const resp = await client.chartsDelete(chartId);

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('error deleting a chart', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}

export async function createChart(chart: ChartEntity) {
  try {
    const client = ChartApi();
    const resp = await client.chartsCreate(convertUIChartToDBChart(chart));

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('error creating a chart', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}

export async function updateChart({ chart }: { chart: ChartEntity }) {
  try {
    const dbChart = convertUIChartToDBChart(chart);
    const client = ChartApi();

    const resp = await client.chartsCreate2(chart.id, dbChart);

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('error updating a chart', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}
