import {
  ApolloClient,
  NormalizedCacheObject,
  ApolloLink,
  concat,
  InMemoryCache,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import fetch from 'isomorphic-unfetch';
import envs from '../../config/envs';

// TODO change to memory storage
// eslint-disable-next-line
const getToken = (res: any) => 'token-here';

const option = {
  typePolicies: {
    Query: {
      fields: {},
    },
  },
};

const httpLink = createUploadLink({
  uri: envs.BackendUrl,
  fetchOptions: {
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_request_options
    // origin: [
    //   'http://localhost:3012',
    //   'http://localhost:3011',
    //   'http://localhost:1401',
    // ],
    credentials: 'omit',
  },
  // @ts-ignore
  fetch,
});

// eslint-disable-next-line
const createAuthMiddleware = (res?: any) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => {
      const token = getToken(res);

      return {
        headers: {
          ...headers,
          ...(token
            ? {
                authorization: `Bearer ${token}`,
              }
            : null),
        },
      };
    });

    return forward(operation);
  });

// eslint-disable-next-line
export function createClient(res?: any) {
  const client = new ApolloClient<NormalizedCacheObject>({
    link: concat(createAuthMiddleware(res), httpLink),
    cache: new InMemoryCache(option),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
      },
    },
  });

  return client;
}
