import { AxiosError } from 'axios';
import { reverse, sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { MyApi } from '../../api/client';
import {
  convertDBDashboardToUIDashboard,
  DashboardFormData,
} from './dashboard-models';

export const MyDashboardListState = atom<DashboardFormData[] | null>({
  key: 'MyDashboardListState',
  default: undefined,
});

export async function fetchMyDashboards() {
  try {
    const client = MyApi();
    const resp = await client.dashboardsList();

    return resp;
  } catch (error) {
    // eslint-disable-next-line
    console.error('fetching dashboards error', error);

    if (error instanceof AxiosError) {
      return {
        ...error.response,
        error: `${error?.response?.data?.message}`,
      };
    }
  }

  return null;
}

export function useMyDashboards() {
  const [myDashboards, setMyDashboards] = useRecoilState(MyDashboardListState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function init() {
      if (!myDashboards) {
        setLoading(true);

        const resp = await fetchMyDashboards();
        const items = reverse(sortBy(resp?.data?.items, 'createdTime'));
        setMyDashboards(items.map(convertDBDashboardToUIDashboard));

        setLoading(false);
      }
    }

    init();
  }, []);

  return [myDashboards, { loading }] as const;
}
