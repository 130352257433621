import produce from 'immer';
import { useState } from 'react';
import { RiSaveLine } from 'react-icons/ri';
import LoadingIndicator from '../../components/LoadingIndicator';

import Modal from '../../components/modals/Modal';
import {
  useDashboardFormData,
  useSaveDashboard,
} from './DashboardViewPageState';

export default function DashSettingModal({
  openSetting,
  setOpenSetting,
}: {
  openSetting?: boolean;
  setOpenSetting: (v: boolean) => void;
}) {
  // NOTE: outdated architecture, please refer [API api] folder structure
  const [saving, { saveDashboard }] = useSaveDashboard();

  const [values, setValues] = useDashboardFormData();
  const [formValues, setFormValues] = useState(values);

  return (
    <Modal open={openSetting}>
      <div className='text-center text-xl font-bold'>Setting</div>
      <div className='flex flex-col gap-3'>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Title</span>
          </label>
          <input
            type='text'
            className='input bg-base-300'
            placeholder='Please enter title'
            maxLength={125}
            value={formValues.displayName}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.displayName = e.target.value;
                })
              );
            }}
          />
        </div>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Description</span>
          </label>
          <textarea
            className='textarea bg-base-300'
            placeholder='Please enter description'
            value={formValues.description}
            onChange={(e) => {
              setFormValues(
                produce(formValues, (draft) => {
                  draft.description = e.target.value;
                })
              );
            }}
          />
        </div>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Privacy setting</span>
          </label>
          <div className='flex flex-row items-center gap-2'>
            <input
              type='checkbox'
              className='toggle toggle-primary'
              id='privacy'
              checked={
                // @ts-ignore
                formValues.access === 'public'
              }
              onChange={(e) => {
                setFormValues(
                  produce(formValues, (draft) => {
                    // @ts-ignore
                    draft.access = e.target.checked ? 'public' : 'private';
                  })
                );
              }}
            />
            <label className='label cursor-pointer' htmlFor={'privacy'}>
              <span className='label-text'>Make public</span>
            </label>
          </div>
        </div>

        <div className='flex gap-2 items-center'>
          <button
            className='btn btn-primary gap-4'
            onClick={async () => {
              const success = await saveDashboard(formValues);

              if (success) {
                setValues(formValues);
                setOpenSetting(false);
              }
            }}
          >
            <RiSaveLine />
            Save
          </button>
          <button
            className='btn btn-ghost'
            style={{ border: '1px solid #aaaaaa' }}
            onClick={() => {
              setOpenSetting(false);
            }}
          >
            Cancel
          </button>
          {saving && <LoadingIndicator />}
        </div>
      </div>
    </Modal>
  );
}
