import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { atom, atomFamily, useRecoilState, useSetRecoilState } from 'recoil';
import { fetchMyDashboard, updateMyDashboard } from './dashboard-api';
import { MyDashboardListState } from './DashboardListState';
import {
  convertDBDashboardToUIDashboard,
  DashboardFormData,
  DefaultGridConfigV2,
  FormDataType,
} from './dashboard-models';
import { debounce, isEmpty } from 'lodash';
import produce from 'immer';
import { toast } from 'react-toastify';

export const AMyDashboardFormDataMap = atom<{
  [id: string]: DashboardFormData;
}>({
  key: 'AMyDashboardFormDataMap',
  default: {},
});

export const ALatestDashboards = atomFamily<
  DashboardFormData | null,
  string | undefined
>({
  key: 'ALatestDashboard',
  default: null,
});

export function useDashboardParam() {
  const { dashboardId } = useParams<{ dashboardId: string }>();
  return dashboardId;
}

export function validateDashboardLayouts(dashboard: DashboardFormData) {
  return produce(dashboard, (draft) => {
    const { charts, uiOptions } = dashboard;
    const { layouts: layoutsPure, widgets } = uiOptions;
    // remove invalid OR deleted charts from layout
    if (!isEmpty(charts) && layoutsPure) {
      draft.uiOptions.layouts = layoutsPure.filter(
        (layout) =>
          charts.find((c) => layout.i === c?.id) ||
          (widgets && widgets[layout.i])
      );
    }
  });
}

export function migrateDashboard(dashboard: DashboardFormData) {
  return produce(dashboard, (draft) => {
    // NOTE old dashboard don't have value set and react-grid-layout has default value of 150
    if (!draft?.uiOptions?.rowHeight) {
      draft.uiOptions.rowHeight = DefaultGridConfigV2.rowHeight;
      draft.uiOptions.layouts = draft.uiOptions.layouts?.map((layout) => ({
        ...layout,
        h: layout.h * 2,
      }));
    }
  });
}

export function useMyDashboard() {
  const dashboardId = useDashboardParam();

  const [myDashboardFormDataMap, setMyDashboardFormDataMap] = useRecoilState(
    AMyDashboardFormDataMap
  );

  const [loading, setLoading] = useState(false);

  // latest dashboard
  const [latestDashboard, setLastestDashboard] = useRecoilState(
    ALatestDashboards(dashboardId)
  );

  useEffect(() => {
    async function init() {
      if (!loading && dashboardId) {
        setLoading(true);

        const resp = await fetchMyDashboard({ id: dashboardId });

        let nDashboard = convertDBDashboardToUIDashboard(resp?.data);

        // migrate config values for old dashboard
        nDashboard = migrateDashboard(nDashboard);
        nDashboard = validateDashboardLayouts(nDashboard);

        // TODO error handle
        setMyDashboardFormDataMap({
          ...myDashboardFormDataMap,
          [dashboardId]: nDashboard,
        });

        setLastestDashboard(nDashboard);
        setLoading(false);
      }
    }

    init();
  }, [dashboardId]);

  return [latestDashboard, loading, { setLastestDashboard }] as const;
}

export function useDashboardFormData() {
  const dashboardId = useDashboardParam();

  const [dashboardEditFormDataMap, setDashboardEditFormDataMap] =
    useRecoilState(AMyDashboardFormDataMap);

  const updateFormData = useCallback((newFormData: FormDataType) => {
    if (dashboardId && newFormData) {
      setDashboardEditFormDataMap({
        ...dashboardEditFormDataMap,
        [dashboardId]: newFormData,
      });
    }
  }, []);

  return [dashboardEditFormDataMap[dashboardId || ''], updateFormData] as const;
}

export function useDashboardEdit(dashboard?: FormDataType) {
  const [formData, setFormData] = useDashboardFormData();

  const updateFormData = useCallback((newFormData: FormDataType) => {
    setFormData(newFormData);
  }, []);

  // init form data
  useEffect(() => {
    if (!formData && dashboard?.id) {
      setFormData(dashboard);
    }
  }, [formData, dashboard]);

  const reset = useCallback(() => {
    updateFormData(null);
  }, [updateFormData]);

  return [formData, { updateFormData, reset }] as const;
}

export function useSaveDashboard() {
  const [loading, setLoading] = useState(false);
  const dashboardId = useDashboardParam();
  const [myDashboards, setMyDashboards] = useRecoilState(MyDashboardListState);

  // latest dashboard
  const setLastestDashboard = useSetRecoilState(ALatestDashboards(dashboardId));

  const saveDashboard = useCallback(
    async (nFormData: FormDataType) => {
      if (!dashboardId || !nFormData) return false;

      setLoading(true);

      const resp = await updateMyDashboard(dashboardId, nFormData);

      setLoading(false);

      const error = resp?.data?.message;

      if (error) {
        // error
        toast.error(error);
      } else if (resp?.data) {
        setLastestDashboard(nFormData);

        setMyDashboards(
          myDashboards?.map((item) => {
            if (item.id === dashboardId) {
              // New dashboard
              return nFormData;
            }
            return item;
          }) || []
        );
        return true;
      }

      return false;
    },
    [dashboardId]
  );

  const debouncedEventHandler = useMemo(
    () =>
      debounce((formData: FormDataType) => {
        saveDashboard(formData);

        setLoading(false);
      }, 600),
    []
  );

  const deboundedSaveDashboard = useCallback(
    (formData: FormDataType) => {
      setLoading(true);

      // trigger rerender by updating chart
      debouncedEventHandler(formData);
      setLastestDashboard(formData);
    },
    [debouncedEventHandler]
  );

  return [loading, { saveDashboard, deboundedSaveDashboard }] as const;
}
