import { RiSkipForwardLine, RiSwapFill } from 'react-icons/ri';

import LoadingIndicator from '../../components/LoadingIndicator';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { useNewApiStep2 } from './NewApiHook';
import ApiQueryPad from './ApiQueryPad';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import TableColumns from './TableColumns';
import VersionNameSelector from './VersionNameSelector';
import classNames from 'classnames';
import produce from 'immer';
import { useRecoilState } from 'recoil';
import { useRpcStatusV3 } from '../../hooks/network';
import { SQueryResults } from '../queryBuilder/QueryState';
import { useQueryIdSearchParams } from './ViewApiHooks';

export default function NewApiStep2() {
  const [
    { rpcStatusQueryLoading, rpcStatusCreating, values },
    { createNewApi, setValues },
  ] = useNewApiStep2();

  const [{ isMyWorkspace }] = useWorkspace();
  const queryId = useQueryIdSearchParams();

  const [queryResults] = useRecoilState(SQueryResults(queryId));
  const [rpcStatusResults] = useRpcStatusV3('new-api-step2-query');

  const error = queryResults?.error || rpcStatusResults.error;

  return (
    <>
      <div className='flex'>
        <div className='flex-1 flex items-center space-x-3'>
          <div>
            <RiSwapFill size='2.2rem' className='text-primary' />
          </div>
          <div className='w-full min-w-[24rem] max-w-md relative items-center'>
            <input
              type='text'
              className='input w-full min-w-[24rem] pr-[4.4rem] font-bold text-lg'
              value={values.displayName || ''}
              onChange={(e) =>
                setValues(
                  produce(values, (draft) => {
                    draft.displayName = e.target.value;
                  })
                )
              }
            />
            <span className='absolute right-[0.4rem] self-center bg-base-content bg-opacity-20 py-1 px-2 text-xs rounded opacity-50 font-bold top-[0.4rem]'>
              Private
            </span>
          </div>
          <div className='flex-1'>
            Step 2: Input API Information and check SQL code
          </div>
        </div>
        <div className='flex items-center'>
          <div className='flex space-x-3'>
            <button
              className={classNames('btn btn-primary gap-2', {
                loading: rpcStatusCreating.isLoading,
                'opacity-80': !!error,
              })}
              onClick={() => {
                if (error) {
                  return;
                }
                createNewApi();
              }}
            >
              {!rpcStatusCreating.isLoading && (
                <RiSkipForwardLine size='1.1rem' />
              )}
              Create API
            </button>
          </div>
        </div>
      </div>
      <div className='h-6' />

      {isMyWorkspace && (
        <div className='flex bg-base-content bg-opacity-20 items-center'>
          <div className='flex-1 p-2 font-bold opacity-70'>
            STEP 2: INPUT API INFORMATION AND CHECK SQL CODE
          </div>
        </div>
      )}

      <div className='lg:basis-2/5 basis-full bg-base-100'>
        <div className='flex'>
          <div className='w-[20rem] flex-shrink-0 flex-grow-0'>
            {rpcStatusQueryLoading.isLoading ? (
              <div className='flex items-center justify-center py-10'>
                <LoadingIndicator />
              </div>
            ) : (
              <>
                <div className='hidden'>
                  <VersionNameSelector
                    title={'VERSION NAME'}
                    disabled
                    value={'Community Public Data'}
                    onSelect={() => {}}
                    showLabel
                  />
                  <div className='border-b' />
                </div>

                <div
                  className={classNames('w-full flex items-start pl-2 py-2')}
                >
                  <label className='label flex opacity-50 max-w-[33%] w-full'>
                    <span className='font-semibold text-[10px]'>
                      DESCRIPTION
                    </span>
                  </label>
                  <textarea
                    className='textarea w-full no-outline py-0 min-h-[5rem]'
                    placeholder='input description'
                    onChange={(e) => {
                      setValues(
                        produce(values, (draft) => {
                          draft.description = e.target.value;
                        })
                      );
                    }}
                  />
                </div>

                <div className='border-b' />

                <div className='hidden'>
                  <div
                    className={classNames('w-full flex items-start pl-2 py-2')}
                  >
                    <label className='label flex opacity-50 max-w-[33%] w-full'>
                      <span className='font-semibold text-[10px] whitespace-nowrap'>
                        RESULT DATABASE
                      </span>
                    </label>
                    <input
                      className='input w-full no-outline py-0 min-h-[5rem]'
                      placeholder='Database name'
                      value={values.resultDatabase}
                      onChange={(e) => {
                        setValues(
                          produce(values, (draft) => {
                            draft.resultDatabase = e.target.value;
                          })
                        );
                      }}
                    />
                  </div>
                  <div className='border-b' />
                </div>
                {rpcStatusQueryLoading.isLoading ? (
                  <div className='flex-1 overflow-hidden'>
                    <LogoBlinkerCenter />
                  </div>
                ) : (
                  <TableColumns />
                )}
                <div className='h-10' />
              </>
            )}
          </div>
          <div className='divider-y' />
          {rpcStatusQueryLoading.isLoading ? (
            <div className='flex-1 overflow-hidden'>
              <LogoBlinkerCenter />
            </div>
          ) : (
            <div className='flex-1 overflow-hidden bg-[#f3f3f3]'>
              <ApiQueryPad />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
