import { useWorkspace } from '../../../layouts/components/workspace-hooks';
import NewDashboardV3 from '../../dashboard/NewDashboardV3';
import AddEntityButton from './AddEntityButton';

export default function CreateSection() {
  const [{ menu }, { getPath }] = useWorkspace();

  if (menu === 'data-sources') {
    return null;
  }

  return (
    <div className='flex h-[8rem] w-full space-x-5 items-center py-5 px-5'>
      {menu === 'queries' && (
        <AddEntityButton title='Create Query' to={getPath('queries/new')} />
      )}
      {menu === 'apis' && (
        <AddEntityButton
          title='Create API With SQL'
          to={getPath('queries/api')}
        />
      )}
      {menu === 'apis' && (
        <AddEntityButton
          title='Create API With PYSPARK'
          subtitle='COMING SOON'
        />
      )}
      {menu === 'dashboards' && <NewDashboardV3 />}
      {menu === 'projects' && <AddEntityButton title='Create Project' />}
      {menu === 'data-sources' && (
        <AddEntityButton title='Create Data Source' />
      )}
      {menu === 'contracts' && <AddEntityButton title='Create Contract' />}
    </div>
  );
}
