import produce from 'immer';
import { BiRocket } from 'react-icons/bi';
import { useRecoilState } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';

import Modal from '../../components/modals/Modal';
import { useApiId, useUpdateApi, ApiEditFormState } from './ViewApiHooks';

export default function ApiPublishModal({
  openPublish,
  setOpenPublish,
}: {
  openPublish?: boolean;
  setOpenPublish: (v: boolean) => void;
}) {
  const apiId = useApiId();
  const [{ rpcStatus }, { updateApi }] = useUpdateApi();
  const [values, setValues] = useRecoilState(ApiEditFormState(apiId));

  return (
    <Modal open={openPublish}>
      <div className='flex flex-col gap-2'>
        <div className='text-xl font-bold'>
          Publish to the <br />
          ZettaBlock Community
        </div>
        <div className='text-sm opacity-50'>
          Publish this API to the community for the public to duplicate and
          remix. Note that the communtiy will always see the latest update.
          <br />
          <br />
        </div>
      </div>
      <div className='flex flex-col gap-3'>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Title</span>
          </label>
          <input
            type='text'
            className='input bg-base-300'
            placeholder='Please enter title'
            value={values.displayName}
            maxLength={125}
            onChange={(e) => {
              setValues(
                produce(values, (draft) => {
                  draft.displayName = e.target.value;
                })
              );
            }}
          />
        </div>
        <div className='form-control'>
          <label className='label'>
            <span className='label-text'>Description</span>
          </label>
          <textarea
            className='textarea bg-base-300'
            placeholder='Please enter description'
            value={values.description}
            onChange={(e) => {
              setValues(
                produce(values, (draft) => {
                  draft.description = e.target.value;
                })
              );
            }}
          />
        </div>
        <br />

        <div className='flex gap-2 items-center'>
          <button
            className='btn btn-primary gap-4'
            onClick={async () => {
              const nValues = {
                ...values,
                access: 'public',
              };
              const success = await updateApi(nValues);

              if (success) {
                setValues(nValues);
                setOpenPublish(false);
              }
            }}
          >
            <BiRocket size='1.1rem' />
            Publish
          </button>
          <button
            className='btn btn-ghost'
            style={{ border: '1px solid #aaaaaa' }}
            onClick={() => {
              setOpenPublish(false);
            }}
          >
            Cancel
          </button>
          {rpcStatus.isLoading && <LoadingIndicator />}
        </div>
      </div>
    </Modal>
  );
}
