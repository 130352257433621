import classNames from 'classnames';
import { ReactNode } from 'react';
import { AiFillLayout } from 'react-icons/ai';
import { MdDashboard } from 'react-icons/md';
import { RiArchiveDrawerFill, RiProfileFill } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import { HorizontalLine } from '../../../components/HorizontalLine';
import { useWorkspace } from '../../../layouts/components/workspace-hooks';
import { slugify } from '../../../utils/slugify';

function NavItem({ label, icon }: { label: string; icon: ReactNode }) {
  const [{ menu, isMyWorkspace }] = useWorkspace();

  const navigate = useNavigate();

  const menuCls = isMyWorkspace ? 'text-purple-600' : '';
  const path = slugify(label.toLowerCase());

  return (
    <div
      className={classNames(
        'inline-flex btn btn-ghost items-center normal-case text-[0.94rem] px-1 opacity-50',
        menu === path && ['text-primary font-bold opacity-100', menuCls]
      )}
      onClick={() => navigate(path)}
    >
      {icon}
      <div className='w-2' />
      {label}
    </div>
  );
}
export default function ExplorerNavbar() {
  return (
    <div className='flex flex-col w-full'>
      <div className='flex items-center w-full justify-between px-4 py-3'>
        <div className='inline-flex items-center space-x-3'>
          <NavItem label='Queries' icon={<AiFillLayout size='1rem' />} />
          <NavItem label='APIs' icon={<MdDashboard size='1rem' />} />
          <NavItem label='Dashboards' icon={<RiProfileFill size='1rem' />} />
          <NavItem
            label='Data Sources'
            icon={<RiArchiveDrawerFill size='1rem' />}
          />
        </div>
      </div>
      <HorizontalLine />
    </div>
  );
}
