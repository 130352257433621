import { AxiosError } from 'axios';
import { QueryApi, QueryRunsApi } from '../../api/client';
import { QueryFormValues } from './QueryBuilderHooksV3';

export async function postUpdateQuery(params: QueryFormValues) {
  try {
    const { id, displayName, text, paramsStr, ...rest } = params;

    const client = QueryApi();
    const resp = await client.queriesCreate(id || '', {
      displayName,
      query: text,
      paramsStr,
      ...rest,
    });

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error.response,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function getApiDetail(queryRunId: string) {
  try {
    const client = QueryRunsApi();
    const resp = await client.detailDetail(queryRunId);
    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error,
        data: error?.response?.data,
      };
    }
  }

  return null;
}

export async function postDeleteQuery(queryId: string) {
  try {
    const client = QueryApi();
    // TODO use query delete
    const resp = await client.queriesDelete(queryId, {
      //
    });

    return resp;
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        ...error.response,
        data: error?.response?.data,
      };
    }
  }

  return null;
}
