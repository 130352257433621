import { Suspense } from 'react';
import { Route, Navigate, Routes } from 'react-router';
import PageLoading from './components/app/PageLoading';
import LayoutV3 from './layouts/LayoutV3';
import QueryBuilderV3 from './modules/queryBuilder/QueryBuilderV3';
import Explorer from './modules/explorer/Explorer';
import NewApiStep2 from './modules/api/NewApiStep2';
import ViewApi from './modules/api/ViewApi';
import DashboardViewPageV3 from './modules/dashboard/DashboardViewPageV3';
import QueryListToApiPage from './modules/explorer/QueryListToApiPage';
import ApiKeysPage from './modules/api-key/ApiKeysPage';
import Upload from './modules/connectors/Upload';
import ConnectorView from './modules/connectors/ConnectorView';

export function CommunityRoutes() {
  return (
    <Routes>
      <Route path='/' element={<LayoutV3 />}>
        <Route index element={<Navigate to='queries' replace />} />
        <Route
          path='queries/new'
          element={
            <Suspense fallback={<PageLoading />}>
              <QueryBuilderV3 />
            </Suspense>
          }
        />
        <Route
          path='queries/:id'
          element={
            <Suspense fallback={<PageLoading />}>
              <QueryBuilderV3 />
            </Suspense>
          }
        />
        <Route path='*' element={<Explorer />} />
      </Route>
    </Routes>
  );
}

export function MyWorkspaceRoutes() {
  return (
    <Routes>
      <Route path='/' element={<LayoutV3 />}>
        <Route index element={<Navigate to='queries' replace />} />
        <Route path='api-key' element={<ApiKeysPage />} />
        <Route
          path='queries/api'
          element={
            <Suspense fallback={<PageLoading />}>
              <QueryListToApiPage />
            </Suspense>
          }
        />
        <Route
          path='queries/new'
          element={
            <Suspense fallback={<PageLoading />}>
              <QueryBuilderV3 />
            </Suspense>
          }
        />
        <Route
          path='queries/:id'
          element={
            <Suspense fallback={<PageLoading />}>
              <QueryBuilderV3 />
            </Suspense>
          }
        />

        <Route
          path='dashboards/:dashboardId'
          element={
            <Suspense fallback={<PageLoading />}>
              <DashboardViewPageV3 />
            </Suspense>
          }
        />

        <Route path='data-sources/type/:type' element={<Upload />} />
        <Route path='data-sources/:id/*' element={<ConnectorView />} />

        <Route path='apis/new' element={<NewApiStep2 />} />
        <Route
          path='apis/:apiId'
          element={<Navigate to='overview' replace />}
        />
        <Route path='apis/:apiId/*' element={<ViewApi />} />

        <Route path='*' element={<Explorer />} />
      </Route>
    </Routes>
  );
}
