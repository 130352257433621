import { cloneDeep } from 'lodash';
import { HTMLAttributes, lazy, memo, Suspense } from 'react';
import LogoBlinkerCenter from '../../../components/app/LogoBlinkerCenter';
import { removeKeyRecursive } from '../../../utils/object';
import { QueryResultResult } from '../../queryBuilder/QueryState';
import { BarChartV2 } from '../types-v2';
import { connectEChartData } from '../utils/echart';

const EChartV2 = lazy(() => import('./EChartV2'));

interface Props {
  queryResults?: QueryResultResult;
  chart: BarChartV2;
  loading?: boolean;
}

function BarChart({
  queryResults,
  chart,
  loading,
}: HTMLAttributes<HTMLTableElement> & Props) {
  if (loading) {
    return <LogoBlinkerCenter />;
  }

  // calculate bar chart configs
  const option = connectEChartData({
    chart,
    queryResults,
  });

  // TODO delete after
  // eslint-disable-next-line
  // console.log('Barchart re-render with option', option);

  // TODO memo hash obj
  const hashObj = cloneDeep(option);
  // Mutating
  removeKeyRecursive(hashObj, 'data');

  return (
    <Suspense fallback={<LogoBlinkerCenter />}>
      <EChartV2
        loading={loading}
        option={option}
        key={JSON.stringify(hashObj)}
        style={{ height: '100%' }}
      />
    </Suspense>
  );
}

export default memo(BarChart);
