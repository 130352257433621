import classNames from 'classnames';
import { HTMLAttributes, memo } from 'react';
import { BsPlayFill } from 'react-icons/bs';
import LoadingIndicator from '../../../components/LoadingIndicator';

function QueryBuilderRunButtonV3({
  isQueryRunning,
  needQueryUpdate,
  hasHighlightedText,
  onClick,
  ...props
}: {
  needQueryUpdate?: boolean;
  isQueryRunning: boolean;
  hasHighlightedText: boolean;
} & HTMLAttributes<HTMLButtonElement>) {
  let label;

  if (isQueryRunning) {
    label = <LoadingIndicator color='white' />;
  } else if (hasHighlightedText) {
    label = <span>{'RUN SELECTED'}</span>;
  } else if (needQueryUpdate) {
    label = <span>{'SAVE & RUN'}</span>;
  } else {
    label = (
      <span className='flex gap-3 pr-2'>
        <BsPlayFill size='0.8rem' /> {'RUN'}
      </span>
    );
  }

  return (
    <button
      className={classNames('btn btn-primary btn-sm min-w-[7rem]')}
      {...props}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

export default memo(QueryBuilderRunButtonV3);
