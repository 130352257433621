import classNames from 'classnames';
import { InputHTMLAttributes, ReactNode } from 'react';
import { IoMdRemoveCircleOutline } from 'react-icons/io';

import { slugify } from '../../../utils/slugify';

export function OptionCard({ children }: { children: ReactNode }) {
  return (
    <div className='w-full rounded-lg border p-3 flex flex-col space-y-2'>
      {children}
    </div>
  );
}

export function TextInputOption({
  label,
  placeholder,
  ...props
}: {
  label?: string | ReactNode;
  placeholder?: string;
} & InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div className='form-control w-full flex flex-row items-center space-x-2'>
      {label && (
        <label className={classNames('flex-grow label min-w-[28%]')}>
          <span className='label-text whitespace-pre'>{label}</span>
        </label>
      )}
      <div className='w-full flex-shrink'>
        <input
          type='text'
          placeholder={placeholder || `${label} ...`}
          className='input input-sm input-bordered w-full'
          {...props}
        />
      </div>
    </div>
  );
}

export function SwitchInputOption({
  label,
  id,
  className,
  ...props
}: {
  label: string;
  id?: string;
} & InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div
      className={classNames(
        'form-control w-full flex flex-row items-center space-x-2',
        className
      )}
    >
      <input
        type='checkbox'
        className='toggle toggle-sm toggle-primary'
        id={slugify(id || label)}
        {...props}
      />
      <label className='label cursor-pointer' htmlFor={slugify(id || label)}>
        <span className='label-text'>{label}</span>
      </label>
    </div>
  );
}

export function SelectInputOption({
  label,
  options,
  onRemove,
  className,
  ...props
}: {
  label?: string;
  onRemove?: () => void;
  options: { label: string; value: string }[];
} & InputHTMLAttributes<HTMLSelectElement>) {
  return (
    <div className='form-control w-full flex flex-row items-center space-x-2'>
      {label && (
        <label className='label flex-shrink-0 w-[28%]'>
          <span className='label-text'>{label}</span>
        </label>
      )}
      <select
        className={classNames(
          'select select-sm select-bordered w-full flex-shrink',
          className
        )}
        {...props}
      >
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {onRemove && (
        <span
          className='cursor-pointer'
          onClick={() => {
            onRemove();
          }}
        >
          <IoMdRemoveCircleOutline />
        </span>
      )}
    </div>
  );
}

export function SliderInputOption({
  label,
  id,
  className,
  ...props
}: {
  label: string;
  id?: string;
} & InputHTMLAttributes<HTMLInputElement>) {
  return (
    <div
      className={classNames(
        'form-control w-full flex flex-row items-center space-x-2',
        className
      )}
    >
      {label && (
        <label className='label flex-shrink-0 w-[28%]'>
          <span className='label-text'>{label}</span>
        </label>
      )}
      <input
        type='range'
        className='range range-xs range-primary'
        id={slugify(id || label)}
        {...props}
      />
    </div>
  );
}
