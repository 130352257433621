import classNames from 'classnames';
import { useRef, useState } from 'react';
import { BiCommand, BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { COMMANDS, COMMANDS_PER_PAGE } from './const';
import { getIsMac } from './utils/system';
import useOutsideAlerter from './utils/useOutsideAlerter';

export default function CommandsContainerV3() {
  const isMac = getIsMac();
  const commandCardRef = useRef(null);
  const [commandIndex, setCommandIndex] = useState(0);
  const [commandCardShown, toggleCommandCardShown] = useState(false);
  useOutsideAlerter(commandCardRef, () => toggleCommandCardShown(false));

  return (
    <div ref={commandCardRef} className='relative flex items-center'>
      <button
        className='mx-1 p-1'
        onClick={() => toggleCommandCardShown((prev) => !prev)}
      >
        <BiCommand size='0.9rem' />
      </button>
      <div
        className={classNames(
          'absolute bottom-8 left-0 card bg-base-200 bg-opacity-100 h-auto border-0 p-3 transition-opacity',
          !commandCardShown && 'hidden'
        )}
      >
        <div className='inline-flex items-center w-full'>
          <span className='font-bold'>Commands</span>
          <div className='flex-1' />
          <div className='items-center hidden'>
            <button
              className='btn btn-sm p-0 px-1 rounded-none rounded-l-lg'
              disabled={commandIndex === 0}
              onClick={() => setCommandIndex((prev) => prev - 1)}
            >
              <BiChevronLeft size='1.5rem' />
            </button>
            <button
              className='btn btn-sm p-0 px-1 rounded-none rounded-r-lg'
              disabled={
                commandIndex + 1 >=
                Math.ceil(COMMANDS.length / COMMANDS_PER_PAGE)
              }
              onClick={() => setCommandIndex((prev) => prev + 1)}
            >
              <BiChevronRight size='1.5rem' />
            </button>
          </div>
        </div>
        <div className='h-3' />
        <div className='w-full h-full'>
          {COMMANDS.slice(
            commandIndex * COMMANDS_PER_PAGE,
            (commandIndex + 1) * COMMANDS_PER_PAGE
          ).map(({ name, keys }) => (
            <div key={name} className='flex flex-col space-y-2 w-full'>
              <span className='text-left'>{name}</span>
              <div className='inline-flex space-x-1'>
                {keys[isMac ? 'mac' : 'pc'].map((key) => (
                  <kbd key={`${name}-${key}`} className='kbd kbd-sm'>
                    {key}
                  </kbd>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
