import classNames from 'classnames';
import numeral from 'numeral';
import { HTMLAttributes, memo } from 'react';

import LogoBlinkerCenter from '../../../components/app/LogoBlinkerCenter';
import { QueryResultResult } from '../../queryBuilder/QueryState';
import { getCaption, NumberChartType, transformData } from '../number-type';

interface IProps {
  queryResults?: QueryResultResult;
  chart: NumberChartType;
  loading?: boolean;
}

function NubmerChart({
  className,
  queryResults,
  chart,
  loading,
  ...props
}: HTMLAttributes<HTMLTableElement> & IProps) {
  const rows = queryResults?.rows || [];

  if (loading) {
    return <LogoBlinkerCenter />;
  }

  const xColumnName = chart.uiOptions?.dataOptions?.xCol?.colName || '';
  const formatStr = chart.uiOptions?.dataOptions?.formatStr || '';
  const prefix = chart.uiOptions?.labelOptions?.prefix || '';
  const postfix = chart.uiOptions?.labelOptions?.postfix || '';
  const align = chart.uiOptions?.labelOptions?.align || '';
  const style = chart.uiOptions?.labelOptions?.style;
  const labelAbove = chart.uiOptions?.labelOptions?.labelAbove;

  const transformType = chart.uiOptions?.dataOptions?.transformType;
  const isEligibleForPercent =
    transformType === 'first - last' || transformType === 'last - first';
  const showPercent =
    isEligibleForPercent && chart.uiOptions?.dataOptions?.showPercent;

  // calculate bar chart configs
  const values = rows.map((row) => parseFloat(`${row[xColumnName]}`));
  let label: string | number = transformData(values, transformType);

  if (formatStr) {
    label = numeral(label).format(formatStr);
  }

  let caption: string | number | null = null;

  if (showPercent) {
    const value = getCaption(values, transformType);
    if (!value) {
      caption = '-';
    } else {
      caption = numeral(value).format('0.0%');
    }
  }

  const alignCls = {
    'justify-center text-center': align === 'center',
    'justify-start text-left': align === 'left',
    'justify-end text-right': align === 'right',
  };

  return (
    <div
      className={classNames('flex h-full items-center', alignCls, className)}
      style={{
        paddingRight: style?.padding,
        paddingLeft: style?.padding,
      }}
      {...props}
    >
      <span className={classNames('w-full')}>
        {labelAbove?.label && (
          <span
            className={classNames('w-auto opacity-40 font-bold')}
            style={labelAbove.style}
          >
            {labelAbove?.label}
          </span>
        )}
        <div className='leading-[0.8em]' style={{ fontSize: style?.fontSize }}>
          <div>
            {prefix}
            {label}
            {postfix}
          </div>
          {showPercent && caption && (
            <div
              className={classNames(
                'w-auto opacity-40 font-bold text-[0.4em] leading-[1em]'
              )}
            >
              {caption}
            </div>
          )}
        </div>
      </span>
    </div>
  );
}

export default memo(NubmerChart);
