import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import { useClientPageSize } from '../../hooks/network';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { convertNumberToFormatted, DateFormat } from '../../utils/date';
import ProfileAvatar from '../auth/ProfileAvatar';
import { useMyDashboards } from '../dashboard/dashboard-hooks';
import OnVisible from './components/OnVisible';

export default function DashboardListPage() {
  const [{ menu }, { getPath }] = useWorkspace();

  const [{ myDashboards, rpcStatus }, { getMyDashboards }] = useMyDashboards();
  const [pageSize, { showMore }] = useClientPageSize('dashboard-list');

  useEffect(() => {
    getMyDashboards();
  }, []);

  if (!myDashboards?.items?.length && rpcStatus.isLoading) {
    return (
      <div className='flex w-full items-center py-20 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  return (
    <div className='explorer flex flex-col w-full p-3 overflow-x-auto'>
      <table className='table w-full'>
        <thead className='rounded-full w-full'>
          <tr className='w-full font-semibold opacity-30'>
            <th className='px-4 py-2 w-full'>NAME</th>
            <th className='px-4 py-2'>CREATED</th>
            <th className='px-4 py-2'>
              <div className='inline items-center text-center'>CREATED</div>
            </th>
            <th className='px-4 py-2'>
              <span className='flex items-center text-center'>
                LAST UPDATED
              </span>
            </th>
          </tr>
        </thead>
        <tbody className='rounded-full w-full'>
          {isEmpty(myDashboards.items) && (
            <td colSpan={1000} className='text-center opacity-30 p-20'>
              No dashboards
            </td>
          )}
          {myDashboards.items
            ?.slice(0, pageSize)
            .map(({ id, displayName, creator, updatedTime, createdTime }) => (
              <tr
                key={id}
                className='font-semibold hover:bg-primary hover:bg-opacity-10 hover:rounded-lg items-center overflow-hidden'
              >
                <td className='p-2 overflow-hidden'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div className='flex flex-auto items-center text-lg space-x-2 pr-2 overflow-hidden w-[42rem]'>
                      <span
                        title={displayName || id}
                        className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {displayName || id}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className='p-2 overflow-hidden'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div className='flex flex-auto w-[12rem] justify-start items-center gap-3'>
                      <ProfileAvatar
                        img={creator?.profileImage}
                        className='flex-none w-6 bg-base-content bg-opacity-30'
                      />
                      <span
                        title={creator?.name || 'Api owner'}
                        className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {creator?.name || 'Api owner'}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className='p-2 w-8 opacity-50'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div>
                      {convertNumberToFormatted(createdTime, {
                        formatStr: DateFormat,
                      })}
                    </div>
                  </Link>
                </td>
                <td className='p-2 w-8 opacity-50 text-center'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div>
                      {convertNumberToFormatted(updatedTime, {
                        formatStr: DateFormat,
                      })}
                    </div>
                  </Link>
                </td>
              </tr>
            ))}
          <OnVisible
            onVisible={() => {
              showMore();
            }}
          />
        </tbody>
      </table>
    </div>
  );
}
