import { useRecoilCallback } from 'recoil';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';

import { makeCloneQuery } from './QueryState';
import { useSearchParams } from 'react-router-dom';
import { useRpcStatus } from '../../hooks/network';
import { ModelCloneQueryResponse } from '../../api/__gen__/data-contracts';

export const TemporaryQueryResultId = 'temp';

export function useChartIdSearchParam() {
  const [searchParams] = useSearchParams();
  const chartId = searchParams.get('chartId');
  return chartId;
}

export function useQueryClone(
  { queryPath }: { queryPath: string } = { queryPath: '/query-builder' }
) {
  const params = useParams();
  const navigate = useNavigate();
  const queryId = params?.id;

  const [rpcStatus, setRpcStatus] = useRpcStatus();

  const cloneQuery = useRecoilCallback(
    () => async () => {
      if (rpcStatus.isLoading || !queryId) {
        return;
      }

      toast.dismiss();
      setRpcStatus({ isLoading: true });

      const result = await makeCloneQuery({ queryId });
      const data = result?.data as ModelCloneQueryResponse;
      const error = result?.data?.error;

      if (error) {
        setRpcStatus({
          isLoading: false,
          error,
        });
      } else if (data?.id) {
        setRpcStatus({
          isLoading: false,
        });

        navigate(`${queryPath}/${data.id}`);
      }
    },
    [queryId, rpcStatus]
  );

  // TODO refactor order of props
  return [cloneQuery, { isCloning: rpcStatus?.isLoading }] as const;
}
