/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelListApiKeys,
  ModelListChartResponse,
  ModelListDashboardResponse,
  ModelListQueryResponse,
  ModelListQueryrunResponse,
  ModelListUserApiResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class My<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description list api keys
   *
   * @tags example
   * @name ApikeysList
   * @summary list api keys I own
   * @request GET:/my/apikeys
   */
  apikeysList = (params: RequestParams = {}) =>
    this.request<ModelListApiKeys, any>({
      path: `/my/apikeys`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list user charts
   *
   * @tags example
   * @name ChartsList
   * @summary list user charts
   * @request GET:/my/charts
   */
  chartsList = (params: RequestParams = {}) =>
    this.request<ModelListChartResponse, any>({
      path: `/my/charts`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list user dashboards
   *
   * @tags example
   * @name DashboardsList
   * @summary list user dashboards
   * @request GET:/my/dashboards
   */
  dashboardsList = (params: RequestParams = {}) =>
    this.request<ModelListDashboardResponse, any>({
      path: `/my/dashboards`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list user queries
   *
   * @tags example
   * @name QueriesList
   * @summary list user queries
   * @request GET:/my/queries
   */
  queriesList = (params: RequestParams = {}) =>
    this.request<ModelListQueryResponse, any>({
      path: `/my/queries`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list user queryruns
   *
   * @tags example
   * @name QueryrunsList
   * @summary list user queryruns
   * @request GET:/my/queryruns
   */
  queryrunsList = (params: RequestParams = {}) =>
    this.request<ModelListQueryrunResponse, any>({
      path: `/my/queryruns`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list user's apis
   *
   * @tags example
   * @name UserapisList
   * @summary list user's apis
   * @request GET:/my/userapis
   */
  userapisList = (params: RequestParams = {}) =>
    this.request<ModelListUserApiResponse, any>({
      path: `/my/userapis`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
