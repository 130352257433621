import { toast } from 'react-toastify';
import { atom, useRecoilState } from 'recoil';

import {
  ModelListUserApiResponse,
  ModelListUserApiUserFacingEventLogResponse,
} from '../../api/__gen__/data-contracts';
import { useRpcStatus } from '../../hooks/network';
import { getFetchApiLogs, getFetchMyApis } from './api-rpc';
import { useApiId } from './ViewApiHooks';

export const MyApisState = atom<ModelListUserApiResponse>({
  key: 'MyApisState',
  default: { items: [] },
});

export const MyApiLogs = atom<ModelListUserApiUserFacingEventLogResponse>({
  key: 'MyApiLogs',
  default: {},
});

export const useMyApis = () => {
  const [rpcStatus, setRpcStatus] = useRpcStatus();
  const [myApis, setMyApis] = useRecoilState(MyApisState);

  async function getMyApis() {
    if (rpcStatus?.isLoading) {
      return;
    }

    setRpcStatus({
      isLoading: true,
      error: '',
    });

    const resp = await getFetchMyApis();
    const error = resp?.data.error;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      const data = resp?.data as ModelListUserApiResponse;
      setMyApis(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  return [
    { myApis, rpcStatus },
    { setRpcStatus, getMyApis },
  ] as const;
};

export const useMyApiLogs = () => {
  const [rpcStatus, setRpcStatus] = useRpcStatus();
  const [apiLogs, setApiLogs] = useRecoilState(MyApiLogs);
  const apiId = useApiId();

  async function fetchApiLogs() {
    if (rpcStatus?.isLoading || !apiId) {
      return;
    }

    toast.dismiss();

    setRpcStatus({
      isLoading: true,
    });

    const resp = await getFetchApiLogs(apiId);

    // @ts-ignore
    const error = resp?.data.message || resp?.message;

    if (error) {
      // error
      setRpcStatus({
        isLoading: false,
        error,
      });

      toast.error(error);
    } else {
      setRpcStatus({
        isLoading: false,
      });

      // success
      const data = resp?.data;
      setApiLogs(data);
    }
    setRpcStatus({
      isLoading: false,
      error,
    });
  }

  return [
    { apiLogs, rpcStatus },
    { setRpcStatus, fetchApiLogs },
  ] as const;
};
