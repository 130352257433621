import classNames from 'classnames';
import { HTMLAttributes } from 'react';

export function HorizontalLine({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames('border border-base-300', className)}
      {...props}
    />
  );
}
