/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ModelLoginRequest,
  ModelLoginResponse,
  ModelLogoutResponse,
  ModelRefreshResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Auth<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description login with google and create an account if necessary
   *
   * @tags example
   * @name LoginGoogleCreate
   * @summary login with google and create an account if necessary
   * @request POST:/auth/login/google
   */
  loginGoogleCreate = (
    request: ModelLoginRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelLoginResponse, any>({
      path: `/auth/login/google`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description logout out of the account
   *
   * @tags example
   * @name LogoutCreate
   * @summary logout out of the account
   * @request POST:/auth/logout
   */
  logoutCreate = (params: RequestParams = {}) =>
    this.request<ModelLogoutResponse, any>({
      path: `/auth/logout`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description refresh tokens and return logged user information
   *
   * @tags example
   * @name PostAuth
   * @summary refresh tokens and return logged user information
   * @request POST:/auth/me
   */
  postAuth = (params: RequestParams = {}) =>
    this.request<ModelRefreshResponse, any>({
      path: `/auth/me`,
      method: 'POST',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
