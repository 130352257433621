/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { loadChart } from './StandaloneChartState';
import { convertDBChartToUIChart } from './utils';
import {
  ChartEntity,
  isAreaChart,
  isBarChart,
  isLineChart,
  isNumberChart,
  isScatterChart,
  isTableChartV2,
} from './types';
import { set } from 'lodash';
import { toast } from 'react-toastify';

import '../../css/blinker.css';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PublicChartApi } from '../../api/client';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import classNames from 'classnames';
import { DefaultAutoDismissMs } from '../../config/toast';
import Logo from '../../components/app/Logo';
import useLoadChartData from './StandaloneChartHooks';
import {
  convertDBParamsStrToParams,
  convertParamsArrToMap,
  convertParamsToDBParamsStr,
} from '../queryBuilder/QueryPadState';
import BarChart from './components/BarChart';
import ChartDisplayName from './components/ChartDisplayName';
import TableChartV2 from './components/TableChartV2';
import ShowDetail from '../../components/typographic/ShowDetail';
import NumberChart from './components/NumberChart';
import ScatterChart from './components/ScatterChart';
import AreaChart from './components/AreaChart';
import LineChart from './components/LineChart';

export default function StandaloneChart({
  standalone,
  chartId,
  queryBasePath = '/query-builder',
  privateApi,
}: {
  standalone?: boolean;
  chartId: string;
  queryBasePath?: string;
  privateApi?: boolean;
}) {
  const navigate = useNavigate();

  const [urlParams] = useSearchParams();

  const [isChartLoading, setIsChartLoading] = useState(false);
  const [chart, setChart] = useState<ChartEntity>();

  // @ts-ignore
  function getHydratedParams() {
    // @ts-ignore
    const queryParams = convertDBParamsStrToParams(chart?.queryParamsStr || '');
    const queryMap = convertParamsArrToMap(queryParams);

    queryParams.forEach((qParam) => {
      const { name } = qParam;
      const urlParam = urlParams.get(name);
      if (urlParam) {
        set(queryMap, [name, 'value'], urlParam);
      }
    });

    const paramsStrHydrated = convertParamsToDBParamsStr(
      Object.values(queryMap)
    );

    return paramsStrHydrated;
  }

  const hydratedParams = getHydratedParams();

  const queryId = chart?.queryId;
  const [queryResultCache, loadChartData] = useLoadChartData({
    queryId,
    queryParamsStr: hydratedParams,
    privateApi: true,
  });

  const isDataLoading = queryResultCache?.loading;

  // trigger loading data when chart is fetched
  useEffect(() => {
    if (queryId) {
      loadChartData({ queryId, queryParamsStr: hydratedParams });
    }
  }, [queryId, hydratedParams]);

  // Load chart
  useEffect(() => {
    const load = async () => {
      if (chartId) {
        setIsChartLoading(true);

        const resp = await loadChart(
          { chartId },
          privateApi ? undefined : PublicChartApi()
        );

        if (resp?.data) {
          const uiChart = convertDBChartToUIChart({
            id: resp?.data.id,
            uiOptionsStr: resp?.data.uiOptionsStr,
            ...resp.data,
          });

          setChart(uiChart);
        } else {
          toast.error('failed to load chart');
        }
        setIsChartLoading(false);
      }
    };

    load();
  }, [chartId]);

  if (
    !chart ||
    isChartLoading ||
    isDataLoading ||
    !queryResultCache?.response
  ) {
    return <LogoBlinkerCenter />;
  }

  let chartComp;
  if (isBarChart(chart) && chart.type === 'bar') {
    chartComp = (
      <BarChart
        chart={chart}
        queryResults={queryResultCache?.response?.results}
      />
    );
  } else if (isTableChartV2(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <TableChartV2
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  } else if (isNumberChart(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <NumberChart
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  } else if (isLineChart(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <LineChart
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  } else if (isScatterChart(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <ScatterChart
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  } else if (isAreaChart(chart)) {
    if (queryResultCache?.response?.results) {
      chartComp = (
        <AreaChart
          queryResults={queryResultCache?.response?.results}
          chart={chart}
        />
      );
    }
  }

  const error = queryResultCache?.response?.error;

  return (
    <div
      className={classNames(
        'standalone-chart-base flex flex-col items-center justify-center w-full relative',
        standalone ? 'h-screen' : 'h-full'
      )}
    >
      <div className={classNames('h-full w-full relative flex flex-col')}>
        {standalone && (
          <div
            className='w-full cursor-pointer hover:underline'
            onClick={() => {
              if (chart?.queryId) {
                navigate(`${queryBasePath}/${chart?.queryId}`);
              } else {
                toast.error('Query id is missing for chart', {
                  autoClose: DefaultAutoDismissMs,
                });
              }
            }}
          >
            <ChartDisplayName>
              {chart.displayName || 'Untitled chart'}
            </ChartDisplayName>
          </div>
        )}
        {error ? (
          <div className='flex h-full w-full items-center justify-center'>
            <ShowDetail
              initialLabel='There was an error'
              showMoreLabel='(Show message)'
              detail={error}
            />
          </div>
        ) : (
          chartComp
        )}
      </div>
      {!error && (
        <div
          className={classNames(
            'h-full w-full absolute flex justify-center opacity-30 items-center pointer-events-none'
          )}
        >
          <div>
            <Logo />
          </div>
        </div>
      )}
    </div>
  );
}
