import { useCallback, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { atomFamily, useRecoilState } from 'recoil';
import {
  ModelGetUserApiProgressResponse,
  ModelGetUserApiResponse,
  ModelGetUserApiStatisticsResponse,
} from '../../api/__gen__/data-contracts';
import { useRpcStatus, useRpcStatusV3 } from '../../hooks/network';
import { zToast } from '../toast/toast';
import { ApiQueryTextState } from './ApiQueryPadHooks';
import {
  getApiDetail,
  getApiStats,
  getIndexProgress,
  postUpdateApi,
} from './api-rpc';

const PollingIntervalMS = 5000;

export const ApiEditFormState = atomFamily<
  ModelGetUserApiResponse,
  string | undefined | null
>({
  key: 'ApiEditFormState',
  default: {},
});

export const ApiProgressState = atomFamily<
  ModelGetUserApiProgressResponse,
  string | undefined | null
>({
  key: 'ApiProgressState',
  default: {},
});

export const ApiStatsState = atomFamily<
  ModelGetUserApiStatisticsResponse,
  string | undefined | null
>({
  key: 'ApiStatsState',
  default: {},
});

export function useQueryIdSearchParams() {
  const [searchParams] = useSearchParams();
  const queryId = searchParams.get('queryId');

  return queryId;
}

export function useApiId() {
  const params = useParams();
  const apiId = params?.apiId;

  return apiId;
}

export function useEditApi() {
  const apiId = useApiId();

  const [values, setValues] = useRecoilState(ApiEditFormState(apiId));
  const [, setQuery] = useRecoilState(ApiQueryTextState(apiId));

  const [rpcStatus, setRpcStatus] = useRpcStatus();

  // load detail
  useEffect(() => {
    async function run() {
      if (apiId) {
        setRpcStatus({ isLoading: true });

        const resp = await getApiDetail(apiId);

        const error = resp?.data.message;

        setRpcStatus({ error, isLoading: false });

        if (error) {
          zToast.error(error);
        } else {
          const data = resp?.data as ModelGetUserApiResponse;
          setValues(data);
          setQuery(data.query || '');
        }
      }
    }

    run();
  }, [apiId]);

  async function updateApi(formValues: ModelGetUserApiResponse) {
    if (!apiId) {
      return false;
    }

    const resp = await postUpdateApi(apiId, formValues);
    const error = resp?.data?.message;
    const id = resp?.data?.id;

    if (error || !id) {
      toast.error(
        error || 'something went wrong. please report to engineering team.'
      );
      return false;
    }

    setValues(resp?.data);

    return true;
  }

  return [
    { apiId, values, rpcStatus },
    { setValues, updateApi },
  ] as const;
}

export function useUpdateApi() {
  const apiId = useApiId();
  const [rpcStatus, setRpcStatus] = useRpcStatusV3();
  const [values, setValues] = useRecoilState(ApiEditFormState(apiId));

  async function updateApi(formValues: ModelGetUserApiResponse) {
    if (rpcStatus.isLoading || !apiId) {
      return false;
    }

    setRpcStatus({ isLoading: true });

    const resp = await postUpdateApi(apiId, formValues);

    const error = resp?.data?.message;
    const id = resp?.data?.id;

    setRpcStatus({ isLoading: false, error });

    if (error || !id) {
      toast.error(
        error || 'something went wrong. please report to engineering team.'
      );
      return false;
    }

    setValues({
      ...resp?.data,
    });

    return true;
  }

  return [
    { values, rpcStatus },
    { setRpcStatus, updateApi, setValues },
  ] as const;
}

export function useIndexingProgress() {
  const apiId = useApiId();

  const [values, setValues] = useRecoilState(ApiProgressState(apiId));
  const [rpcStatus, setRpcStatus] = useRpcStatusV3(`${apiId}-index-progress`);

  const fetchIndexProgress = useCallback(async () => {
    if (apiId && !rpcStatus.isLoading) {
      setRpcStatus({ isLoading: true });

      const resp = await getIndexProgress(apiId);

      const error = resp?.data.message;

      setRpcStatus({ error, isLoading: false });

      if (error) {
        zToast.error(error);
      } else {
        const data = resp?.data;
        setValues(data);
      }
    }
  }, [values, setValues, rpcStatus]);

  // polling progress
  useEffect(() => {
    // initial fetch
    fetchIndexProgress();
  }, []);

  // polling progress
  useEffect(() => {
    // polling
    const keepPolling = values.progress === undefined || values.progress < 100;

    if (keepPolling) {
      const i = setTimeout(() => {
        // poll
        fetchIndexProgress();
      }, PollingIntervalMS);

      return () => {
        if (i) {
          clearInterval(i);
        }
      };
    }

    return () => {};
  }, [setValues, values]);

  return [{ values, rpcStatus }] as const;
}

export function useApiStats() {
  const apiId = useApiId();

  const [values, setValues] = useRecoilState(ApiStatsState(apiId));
  const [rpcStatus, setRpcStatus] = useRpcStatusV3(`${apiId}-api-stats`);

  useEffect(() => {
    async function run() {
      if (apiId && !rpcStatus.isLoading) {
        setRpcStatus({ isLoading: true });

        const resp = await getApiStats(apiId);
        const error = resp?.data.message;

        setRpcStatus({ error, isLoading: false });

        if (error) {
          zToast.error(error);
        } else {
          const data = resp?.data;
          setValues(data);
        }
      }
    }

    // initial fetch
    run();
  }, [apiId]);

  return [{ values, rpcStatus }] as const;
}
