import { Fragment, memo, useCallback, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { first, orderBy, values } from 'lodash';
import { useParams } from 'react-router';

import NewVizSelector from './NewVizSelector';
import { AChartsList } from './ChartsState';
import { AQueryDetails, AStaticTabType, SQueryResults } from './QueryState';
import {
  ChartEntity,
  isAreaChart,
  isBarChart,
  isLineChart,
  isNumberChart,
  isScatterChart,
  isTableChartV2,
} from '../chart/types';
import { useDeleteChart } from './QueryBuilderResultsHooks';
import Results from './Results';
import { TemporaryQueryResultId } from './QueryBuilderHooks';
import BarChartComposeV2 from './components/BarChartComposeV2';
import NumberChartCompose from './components/NumberChartCompose';
import LineChartCompose from './components/LineChartCompose';
import ScatterChartCompose from './components/ScatterChartCompose';
import AreaChartCompose from './components/AreaChartCompose';
import useAuth from '../../hooks/auth';
import TabButtonV3 from './components/TabButtonV3';
import TableChartComposeV3 from './components/TableChartComposeV3';
import QueryHistoryV3 from './QueryHistoryV3';
import classNames from 'classnames';
import { useSearchParam } from './QueryPadState';
import { getApiDetail } from './query-rpc';
import { QueryStateV3 } from './QueryBuilderHooksV3';

function QueryBuilderResultsV3({ runQuery }: { runQuery: () => void }) {
  const queryRunId = useSearchParam('runId');

  const params = useParams();
  const queryId = params?.id;
  const [currentTab, setCurrentTab] = useRecoilState(AStaticTabType);
  const tempResult = useRecoilValue(SQueryResults(TemporaryQueryResultId));

  const [queryDetail] = useRecoilState(AQueryDetails(queryId));
  const [, { isOwner }] = useAuth(queryDetail);
  const isEditable = isOwner;

  // TODO better chart management
  // it seems that chart cache map is caching all charts and
  // in here trying to render charts related to a speicifc query
  // NEED to manage by query id
  // charts state
  const chartsMap = useRecoilValue(AChartsList);
  const [deleteChart] = useDeleteChart();
  const charts = values(chartsMap).filter((chart) => chart.queryId === queryId);

  const handleRemove = useCallback(
    (chart: ChartEntity) => {
      deleteChart(chart);
    },
    [deleteChart]
  );

  const selectedChart = charts.find((chart) => chart.id === currentTab.key);

  const [queryValues, setQueryValues] = useRecoilState(QueryStateV3(queryId));

  useEffect(() => {
    async function run(queryRunIdT: string) {
      // load fetched query text on to query pad
      const resp = await getApiDetail(queryRunIdT);
      const text = resp?.data?.text;

      if (text) {
        setQueryValues({
          ...queryValues,
          text,
        });
      }
    }

    if (queryRunId && queryDetail.id) {
      // select query history
      setCurrentTab({ label: 'query history' });
      run(queryRunId);
    }
  }, [queryDetail]);

  if (!tempResult && !queryId) {
    return (
      <div className=' flex h-32 justify-center items-center'>
        <div className='opacity-40'>
          {"Let's get started by running a query"}
        </div>
      </div>
    );
  }

  const firstChart = first(charts);
  const isFirstChartTableChart = isTableChartV2(firstChart);

  return (
    <div>
      <div
        className={classNames(
          'tabs z-[3] relative',
          isEditable ? 'space-x-1' : 'pt-5 -b-1 space-x-4'
        )}
      >
        {isFirstChartTableChart && (
          <TabButtonV3
            tab={{ label: 'results', key: firstChart.id }}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            isEditable={isEditable}
          />
        )}

        {tempResult && (
          <TabButtonV3
            tab={{ label: 'selected query' }}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            isEditable={isEditable}
          />
        )}

        {isEditable && (
          <TabButtonV3
            tab={{ label: 'query history' }}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            isEditable={isEditable}
          />
        )}

        {orderBy(
          isFirstChartTableChart ? charts.slice(1) : charts,
          'createdTime',
          'asc'
        ).map((chart) => {
          return (
            <Fragment key={chart.id}>
              <TabButtonV3
                tab={{ label: `${chart.type} chart`, key: chart.id }}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                isEditable={isEditable}
                onRemove={
                  isEditable
                    ? () => {
                        handleRemove(chart);
                      }
                    : null
                }
              />
            </Fragment>
          );
        })}

        {isEditable && (
          <TabButtonV3
            tab={{ label: '+ new visualization' }}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            isEditable={isEditable}
            activeClassName='text-primary'
          />
        )}
      </div>
      <div className='border-b relative top-[-1px] z-[2]' />

      <div>
        {currentTab.label === 'selected query' && <Results />}
        {currentTab.label === 'query history' && <QueryHistoryV3 />}
        {currentTab.label === '+ new visualization' && <NewVizSelector />}
        {isBarChart(selectedChart) && (
          <BarChartComposeV2
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
        {isTableChartV2(selectedChart) && (
          <TableChartComposeV3
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
            runQuery={runQuery}
          />
        )}
        {isNumberChart(selectedChart) && (
          <NumberChartCompose
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
        {isLineChart(selectedChart) && (
          <LineChartCompose
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
        {isScatterChart(selectedChart) && (
          <ScatterChartCompose
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
        {isAreaChart(selectedChart) && (
          <AreaChartCompose
            key={currentTab.key}
            chart={selectedChart}
            readOnly={!isEditable}
          />
        )}
      </div>
    </div>
  );
}

export default memo(QueryBuilderResultsV3);
