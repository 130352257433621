import './table-columns.css';
import { isEmpty } from 'lodash';
import { useRecoilState } from 'recoil';

import { SelectedTableSchemaState } from '../database/TableSchemaState';
import { memo } from 'react';
import { formatColumn } from './utils/sql';
import { useTables } from '../database/schema-hooks';
import { SelectedSchemaState } from '../database/SchemaState';

function TableColumnsV3({
  onInsertClick,
}: {
  onInsertClick: (value: string) => void;
}) {
  const [selectedSchema] = useRecoilState(SelectedSchemaState);
  const [{ tables }] = useTables(selectedSchema);
  const [selectedTable] = useRecoilState(SelectedTableSchemaState);

  const columns =
    tables?.items?.find((t) => t.name === selectedTable)?.columns || [];

  return (
    <div className='overflow-y-auto'>
      <div className='px-3 pb-2 max-h-[60vh] overflow-auto'>
        {isEmpty(columns) ? (
          <span className='opacity-50'>{'No columns'}</span>
        ) : (
          columns.map((item) => {
            return (
              <div
                key={item.name}
                className='text-md flex flex-wrap opacity-80 hover:opacity-100 cursor-pointer'
                onClick={() => {
                  if (item.name) {
                    onInsertClick(formatColumn(item.name));
                  }
                }}
              >
                <span className='table-column-item break-words min-w-0 flex items-center flex-nowrap'>
                  {item.name}
                  <span className='w-1' />
                  <span
                    className='badge badge-sm badge-outline opacity-60'
                    onClick={(e) => {
                      if (item.name) {
                        onInsertClick(
                          formatColumn(item.name, {
                            withComma: true,
                          })
                        );
                      }

                      e.stopPropagation();
                    }}
                  >
                    + comma
                  </span>
                </span>
                <div className='flex-1 text-right opacity-60'>{item.type}</div>
                <div className='mb-2' />
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

export default memo(TableColumnsV3);
