import { useParams } from 'react-router';
import { useRecoilValue } from 'recoil';

import { SideButton } from './components/SideButton';
import { useAddChart, VIZ_TYPES } from './NewVizHooks';
import { SQueryResults } from './QueryState';

export default function NewVizSelector() {
  const params = useParams();
  const queryId = params.id;

  const [addChart] = useAddChart();
  const queryResults = useRecoilValue(SQueryResults(queryId));

  if (!queryResults) {
    return (
      <div className='rounded-2xl bordered bg-base-200 flex h-32 justify-center items-center'>
        <div className='opacity-40'>
          {"Let's get started by running a query"}
        </div>
      </div>
    );
  }

  return (
    <div className='flex border rounded-2xl'>
      <div className='flex w-44 p-5'>
        <div className='w-full'>
          <SideButton active>All charts</SideButton>
        </div>
      </div>

      <div className='border-r' />

      <div className='flex-1'>
        <div className='flex flex-wrap p-3'>
          {VIZ_TYPES.map((viz) => (
            <div key={viz.label} className='w-1/3 p-3'>
              <button
                className='text-left w-full font-bold border rounded-lg p-3 cursor-pointer hover:bg-base-300'
                onClick={() => {
                  addChart(viz);
                }}
              >
                {viz.label}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
