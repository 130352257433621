import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useState } from 'react';
import { isEmpty, set, trim, uniqBy } from 'lodash';
import { AQueryParamDefaultValuesCache, AQueryParams } from './QueryPadState';
import { DefaultTParamType, TParam } from './types';
import produce from 'immer';
import { FiPlay, FiSettings } from 'react-icons/fi';
import QueryParamInputV3 from './components/QueryParamInputV3';
import { BsPlusSquare } from 'react-icons/bs';
import QueryParamsConfigModalV3 from './components/QueryParamConfigModalV3';
import TextToSearchModal from './TextToSearchModal';
import { useQueryPadV3 } from './QueryPadStateV3';
import { SaveQueryFun } from './QueryBuilderHooksV3';
import classNames from 'classnames';
import LoadingIndicator from '../../components/LoadingIndicator';

export default function QueryPadV3({
  readOnly,
  saveQueryDeb,
  runQuery,
  isQueryRunning,
}: {
  isQueryRunning?: boolean;
  readOnly?: boolean;
  saveQueryDeb: SaveQueryFun;
  runQuery: () => void;
}) {
  const [{ query, setQuery, editor }, { setContainer, view }] = useQueryPadV3({
    readOnly,
    saveQueryDeb,
  });

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [editor.current]);

  useEffect(() => {
    if (view) {
      window.view = view;
    }
    const interval = setInterval(() => {
      if (view) {
        window.view = view;
      }
    }, 500);
    return () => clearInterval(interval);
  }, [view]);

  // query param cached values
  const [queryParamDefaultValues, setQueryParamDefaultValues] = useRecoilState(
    AQueryParamDefaultValuesCache
  );
  const [queryParams, setQueryParams] = useRecoilState(AQueryParams);

  // editing query param
  const [editingParam, setEditingParam] = useState<TParam>();

  const handleAddParam = useCallback(() => {
    if (!view) return;

    const from = view.state.selection.ranges[0].from || 0;
    const to = view.state.selection.ranges[0].to || 0;

    const newStr = `{{param${queryParams.length + 1}}}`;
    const tr = view.state.update(
      {
        changes: [
          { from, to, insert: '' },
          { from, insert: newStr },
        ],
        selection: { anchor: from + newStr.length },
      },
      {
        scrollIntoView: true,
      }
    );

    view.dispatch(tr);
    view.focus();
  }, [view, queryParams]);

  useEffect(() => {
    // find params
    const matches = query?.match(/{{[^{^{^}^}]+}}/g) || [];

    setQueryParams(
      matches.map((match) => {
        const name = trim(match, '{{}}');
        const defaultValueFoundFromCache =
          queryParamDefaultValues[name]?.defaultValue || '';

        const defaultTypeFoundFromCache =
          queryParamDefaultValues[name]?.type || DefaultTParamType;
        return {
          name,
          value: defaultValueFoundFromCache,
          defaultValue: defaultValueFoundFromCache,
          type: defaultTypeFoundFromCache,
        };
      })
    );
  }, [query, queryParamDefaultValues]);

  return (
    <div>
      <TextToSearchModal />
      <div className='h-[50vh] flex flex-col relative overflow-y-auto'>
        {readOnly && (
          <div className='z-10 relative h-0 w-full'>
            <div className='absolute right-0 border bg-primary-content'>
              <button
                className={classNames(
                  'flex gap-2 tex-sm opacity-70 font-semibold items-center pl-1 pr-4 py-[0.1rem]'
                )}
                onClick={() => {
                  runQuery();
                }}
              >
                {isQueryRunning ? (
                  <span className='py-1 px-5'>
                    <LoadingIndicator />
                  </span>
                ) : (
                  <>
                    <FiPlay size='1rem' /> RUN
                  </>
                )}
              </button>
            </div>
          </div>
        )}
        <div
          key='editor'
          className={classNames('flex-1 h-full', {
            border: readOnly,
          })}
          ref={editor as React.RefObject<HTMLDivElement>}
        />

        {!isEmpty(queryParams) && (
          <div className='flex flex-wrap border-t'>
            {uniqBy(queryParams, 'name').map((param, idx) => (
              <div key={`${param.name}-${idx}`} className='relative'>
                <div className='relative w-0 h-0 overflow-visible'>
                  <div className='absolute bottom-0 py-2'>
                    {param.name === editingParam?.name && (
                      <QueryParamsConfigModalV3
                        className='border rounded-none p-0 w-[255px] overflow-visible bg-primary-content'
                        queryParam={param}
                        idx={idx}
                        startOpen
                        onClose={() => {
                          setEditingParam(undefined);
                        }}
                        onRemove={() => {
                          const nParams = produce(queryParams, (draft) => {
                            draft.splice(idx, 1);
                          });

                          const reg = new RegExp(
                            `{{${editingParam.name}}}`,
                            'g'
                          );
                          // removing params
                          setQuery(query?.replace(reg, ''));
                          setQueryParams(nParams);
                          // dismiss modal
                          setEditingParam(undefined);
                        }}
                        onSave={(nParam) => {
                          const nParams = produce(queryParams, (draft) => {
                            set(draft, [idx], nParam);
                          });

                          const reg = new RegExp(
                            `{{${editingParam.name}}}`,
                            'g'
                          );
                          // replace params
                          setQuery(query?.replace(reg, `{{${nParam.name}}}`));
                          setQueryParams(nParams);
                          // dismiss modal
                          setEditingParam(undefined);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className='border-b border-r relative'>
                  <span className='px-3 flex items-center space-x-1'>
                    <div className='flex-grow-0 text-xs whitespace-pre text-primary'>
                      {param.name}
                    </div>

                    <QueryParamInputV3
                      idx={idx}
                      className='input-sm inline w-[4rem]'
                      queryParam={param}
                      readOnly={readOnly}
                      onChange={(value) => {
                        setQueryParamDefaultValues({
                          ...queryParamDefaultValues,
                          [param.name]: {
                            name: param.name,
                            type: param.type,
                            value,
                            defaultValue: value,
                          },
                        });

                        const nParams = produce(queryParams, (draft) => {
                          // found the param, update value
                          set(draft, [idx, 'value'], value);
                          set(draft, [idx, 'defaultValue'], value);
                        });

                        setQueryParams(nParams);
                      }}
                    />
                    {!readOnly && (
                      <div
                        className='cursor-pointer opacity-40 hover:opacity-100'
                        onClick={() => {
                          // config param settings (type)
                          setEditingParam(param);
                        }}
                      >
                        <FiSettings size='0.9rem' />
                      </div>
                    )}
                  </span>
                </div>
              </div>
            ))}
            {!readOnly && (
              <button
                className='cursor-pointer flex items-center border-r border-b px-2'
                onClick={handleAddParam}
              >
                <BsPlusSquare size='0.8rem' />
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
