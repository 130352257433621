import { Route, Routes } from 'react-router';

import './row.css';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import ExplorerNavbar from './components/ExplorerNavbar';
import Apis from './ApiListPage';
import DashboardListPage from './DashboardListPage';
import QueryListPage from './QueryListPage';
import CreateSection from './components/CreateSection';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import DataSourcesListPageV3 from './DataSourcesListPageV3';

export default function Explorer() {
  const [{ isMyWorkspace }] = useWorkspace();

  return (
    <LayoutBodyCard className='lg:basis-2/5 basis-full'>
      <ExplorerNavbar />
      {isMyWorkspace && <CreateSection />}
      <Routes>
        <Route path='queries' element={<QueryListPage />} />
        <Route path='apis' element={<Apis />} />
        <Route path='dashboards' element={<DashboardListPage />} />
        <Route path='data-sources' element={<DataSourcesListPageV3 />} />
      </Routes>
    </LayoutBodyCard>
  );
}
