import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { SQLIconV2 } from '../../components/icons/Icons';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { useMyQueries } from './QueriesHooks';
import { convertNumberToFormatted, DateFormat } from '../../utils/date';
import ProfileAvatar from '../auth/ProfileAvatar';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import { useClientPageSize } from '../../hooks/network';
import OnVisible from './components/OnVisible';
import { isEmpty } from 'lodash';
import { queryStateDefaultValue } from '../queryBuilder/QueryBuilderHooksV3';

export default function Queries() {
  const [{ menu }, { getPath }] = useWorkspace();

  const [{ myQueries, rpcStatus }, { getMyQueries }] = useMyQueries();
  const [pageSize, { showMore }] = useClientPageSize('query-list');

  useEffect(() => {
    getMyQueries();
  }, []);

  if (!myQueries?.items?.length && rpcStatus.isLoading) {
    return (
      <div className='flex w-full items-center py-20 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  return (
    <div className='explorer flex flex-col w-full p-3 overflow-x-auto'>
      <table className='table w-full'>
        <thead className='rounded-full w-full'>
          <tr className='w-full font-semibold opacity-30'>
            <th className='px-4 py-2 w-full'>NAME</th>
            <th className='px-4 py-2'>CREATED</th>
            <th className='px-4 py-2'>
              <div className='inline items-center text-center'>CREATED</div>
            </th>
            <th className='px-4 py-2'>
              <span className='flex items-center text-center'>
                LAST UPDATED
              </span>
            </th>
          </tr>
        </thead>
        <tbody className='rounded-full w-full'>
          {isEmpty(myQueries.items) && (
            <td colSpan={1000} className='text-center opacity-30 p-20'>
              No queries
            </td>
          )}
          {myQueries.items?.slice(0, pageSize).map(
            ({
              id,
              // @ts-ignore
              displayName,
              creator,
              createdTime,
              updatedTime,
            }) => (
              <tr
                key={id}
                className='font-semibold hover:bg-primary hover:bg-opacity-10 hover:rounded-lg items-center overflow-hidden'
              >
                <td className='p-2 overflow-hidden'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div className='flex flex-auto items-center text-lg space-x-2 pr-2 overflow-hidden w-[42rem]'>
                      <SQLIconV2
                        style={{ width: '1.5rem' }}
                        className='flex-none'
                      />
                      <span
                        title={displayName || id}
                        className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {displayName || queryStateDefaultValue.displayName}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className='p-2 overflow-hidden'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div className='flex flex-auto w-[12rem] justify-start items-center gap-3'>
                      <ProfileAvatar
                        img={creator?.profileImage}
                        className='flex-none w-6 bg-base-content bg-opacity-30'
                      />
                      <span
                        title={creator?.name}
                        className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                      >
                        {creator?.name}
                      </span>
                    </div>
                  </Link>
                </td>
                <td className='p-2 w-8 opacity-50 text-center'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div>
                      {convertNumberToFormatted(createdTime, {
                        formatStr: DateFormat,
                      })}
                    </div>
                  </Link>
                </td>
                <td className='p-2 w-8 opacity-50 text-center'>
                  <Link key={id} to={`${getPath(menu)}/${id}`}>
                    <div>
                      {convertNumberToFormatted(updatedTime, {
                        formatStr: DateFormat,
                      })}
                    </div>
                  </Link>
                </td>
              </tr>
            )
          )}
          <OnVisible
            onVisible={() => {
              showMore();
            }}
          />
        </tbody>
      </table>
    </div>
  );
}
