const backendApi = process.env.REACT_APP_ZETTA_BACKEND_API || '';

export function getEnvs() {
  if (!process.env.REACT_APP_GA4_ID) {
    // eslint-disable-next-line
    console.error('MISSING: google analytic id');
  }

  const envs = {
    BackendUrl: process.env.REACT_APP_BACKEND_URL,
    ZettaBackendApiPrivate: `${backendApi}/qugate/v1`,
    ZettaBackendApiPublic: `${backendApi}/pubgate/v1`,
    GoogleClientID: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    ga4Id: process.env.REACT_APP_GA4_ID || '',
    isProd: process.env.NODE_ENV === 'production',
    isProdRuntime: process.env.REACT_APP_ENV === 'production',
  };

  return envs;
}

export default getEnvs();
