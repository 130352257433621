import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { Dashboards } from './__gen__/Dashboards';
import { Databases } from './__gen__/Databases';
import { Queries } from './__gen__/Queries';
import { Charts } from './__gen__/Charts';
import { Userapis } from './__gen__/Userapis';
import { Stream } from './__gen__/Stream';
import { Queryruns } from './__gen__/Queryruns';
import { Texttosql } from './__gen__/Texttosql';
import { My } from './__gen__/My';
import { Auth } from './__gen__/Auth';
import envs from '../config/envs';
import { getRecoilState, setRecoilState } from '../modules/recoil/utils';
import { AuthState } from '../modules/auth/AuthState';
import Cookies from 'universal-cookie';

export const getAuthHeader = (): AxiosRequestHeaders => {
  const cookie = new Cookies();
  const auth = cookie.get('auth');

  if (auth && auth.username) {
    const token = `${window.btoa(`${auth.username}:${auth.password}`)}`;
    return {
      Authorization: `Basic ${token}`,
    };
  }

  return {};
};

const applyAuthHandler = <T>(client: T) => {
  // @ts-ignore
  (client.instance as AxiosInstance).interceptors.request.use((request) => {
    const cookie = new Cookies();
    const cookieAuth = cookie.get('auth');

    if (cookieAuth && cookieAuth.username) {
      const token = `${window.btoa(
        `${cookieAuth.username}:${cookieAuth.password}`
      )}`;
      request.headers = {
        Authorization: `Basic ${token}`,
      };
      return request;
    }

    const auth = getRecoilState(AuthState);
    if (auth) {
      const { accessToken } = auth;
      request.headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return request;
  });
  return client;
};

const applyErrorHandler = <T>(client: T) => {
  // @ts-ignore
  (client.instance as AxiosInstance).interceptors.response.use(
    (response) => response,
    async (error) => {
      // eslint-disable-next-line
      console.log('global error handler', error);
      if (!error.config.retry && error.response.status === 401) {
        // handle auth
        // eslint-disable-next-line
        const authClient = AuthApi();
        const resp = await authClient.postAuth();

        if (resp.data.accessToken && resp.data.user?.id) {
          setRecoilState(AuthState, {
            accessToken: resp.data.accessToken,
            id: resp.data.user.id,
            name: resp.data.user.name,
            email: resp.data.user.email,
            profileImage: resp.data.user.profileImage,
          });

          /* retry request here */
          error.config.retry = true;
          error.config.headers = {
            Authorization: `Bearer ${resp.data.accessToken}`,
          };

          return axios.request(error.config);
        }

        setRecoilState(AuthState, null);
      }
      throw error;
    }
  );
  return client;
};

const enhanceClient = <T>(client: T) => {
  return applyErrorHandler(applyAuthHandler(client));
};

export const ChartApi = () => {
  const client = new Charts({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const PublicChartApi = () => {
  const client = new Charts({
    baseURL: envs.ZettaBackendApiPublic,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const PublicQueryApi = () => {
  const client = new Queries({
    baseURL: envs.ZettaBackendApiPublic,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const QueryApi = () => {
  const client = new Queries({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const DatabaseApi = () => {
  const client = new Databases({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const DashboardApi = () => {
  const client = new Dashboards({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const StreamApi = () => {
  const client = new Stream({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const QueryRunsApi = () => {
  const client = new Queryruns({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const TextToSQL = () => {
  const client = new Texttosql({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};

export const MyApi = () => {
  const client = new My({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const AuthApi = () => {
  const client = new Auth({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });
  return enhanceClient(client);
};

export const UserApiV2 = () => {
  const client = new Userapis({
    baseURL: `${envs.ZettaBackendApiPrivate}`,
    withCredentials: true,
  });
  return enhanceClient(client);
};

// TODO: replace it later. this is for demo perpose
export const UsersApi = () => {
  const client = new Charts({
    baseURL: envs.ZettaBackendApiPrivate,
    withCredentials: true,
  });

  return enhanceClient(client);
};
