import { sql } from '@codemirror/lang-sql';
import { useCodeMirror } from '@uiw/react-codemirror';
import { memo, useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';

import './api-query-pad.scss';
import { ApiQueryTextState } from './ApiQueryPadHooks';
import { useApiId } from './ViewApiHooks';

function ViewApiOverviewEditor() {
  const editor = useRef<HTMLDivElement>();
  const apiId = useApiId();
  const [query] = useRecoilState(ApiQueryTextState(apiId));

  const { setContainer } = useCodeMirror({
    readOnly: true,
    container: editor.current,
    extensions: [sql()],
    value: query,
  });

  useEffect(() => {
    if (editor.current) {
      setContainer(editor.current);
    }
  }, [editor]);

  return (
    <div className='flex readonly h-full w-full'>
      <div
        key='editor'
        className='flex-1 overflow-y-auto border'
        ref={editor as React.RefObject<HTMLDivElement>}
      />
    </div>
  );
}

export default memo(ViewApiOverviewEditor);
