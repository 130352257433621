/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  GraphqlResult,
  ModelCreateUserApiRequest,
  ModelCreateUserApiResponse,
  ModelGetUserApiProgressResponse,
  ModelGetUserApiResponse,
  ModelGetUserApiStatisticsResponse,
  ModelGetUserApiStatusResponse,
  ModelGraphqlQueryRequest,
  ModelListUserApiUserFacingEventLogResponse,
  ModelSubmitQueryRequest,
  ModelUpdateUserApiRequest,
  ModelUpdateUserApiResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Userapis<
  SecurityDataType = unknown
> extends HttpClient<SecurityDataType> {
  /**
   * @description create user api
   *
   * @tags example
   * @name UserapisCreate
   * @summary create user api
   * @request POST:/userapis
   */
  userapisCreate = (
    request: ModelCreateUserApiRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelCreateUserApiResponse, any>({
      path: `/userapis`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get user api
   *
   * @tags example
   * @name UserapisDetail
   * @summary get user api
   * @request GET:/userapis/{id}
   */
  userapisDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetUserApiResponse, any>({
      path: `/userapis/${id}`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description update user api
   *
   * @tags example
   * @name UserapisCreate2
   * @summary update user api
   * @request POST:/userapis/{id}
   * @originalName userapisCreate
   * @duplicate
   */
  userapisCreate2 = (
    id: string,
    request: ModelUpdateUserApiRequest,
    params: RequestParams = {}
  ) =>
    this.request<ModelUpdateUserApiResponse, any>({
      path: `/userapis/${id}`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description list user api user facing event log
   *
   * @tags example
   * @name EventsDetail
   * @summary list user api user facing event log
   * @request GET:/userapis/{id}/events
   */
  eventsDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelListUserApiUserFacingEventLogResponse, any>({
      path: `/userapis/${id}/events`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description executes a graphql query on user api dataset
   *
   * @tags example
   * @name GraphqlCreate
   * @summary executes a graphql query on user api dataset
   * @request POST:/userapis/{id}/graphql
   */
  graphqlCreate = (
    id: string,
    request: ModelGraphqlQueryRequest,
    params: RequestParams = {}
  ) =>
    this.request<GraphqlResult, any>({
      path: `/userapis/${id}/graphql`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get user_api progress
   *
   * @tags example
   * @name ProgressDetail
   * @summary get user api progress
   * @request GET:/userapis/{id}/progress
   */
  progressDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetUserApiProgressResponse, any>({
      path: `/userapis/${id}/progress`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description executes a sql query on user api dataset
   *
   * @tags example
   * @name PostUserapis
   * @summary executes a sql query on user api dataset
   * @request POST:/userapis/{id}/sql
   */
  postUserapis = (
    id: string,
    request: ModelSubmitQueryRequest,
    params: RequestParams = {}
  ) =>
    this.request<string, any>({
      path: `/userapis/${id}/sql`,
      method: 'POST',
      body: request,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get user_api statistics
   *
   * @tags example
   * @name StatisticsDetail
   * @summary get user api statistics
   * @request GET:/userapis/{id}/statistics
   */
  statisticsDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetUserApiStatisticsResponse, any>({
      path: `/userapis/${id}/statistics`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description get user_api status
   *
   * @tags example
   * @name StatusDetail
   * @summary get user api status
   * @request GET:/userapis/{id}/status
   */
  statusDetail = (id: string, params: RequestParams = {}) =>
    this.request<ModelGetUserApiStatusResponse, any>({
      path: `/userapis/${id}/status`,
      method: 'GET',
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
