import { memo } from 'react';

import { CommonChartComposeProps } from '../../chart/types-v2';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BsCode } from 'react-icons/bs';
import { RiShareBoxFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import ChartDisplayName from '../../chart/components/ChartDisplayName';
import SaturatedButton from './interactives/SaturatedButton';
import { ChartEntity } from '../../chart/types';

function ChartComposeHeader({
  chart,
}: { chart: ChartEntity } & CommonChartComposeProps) {
  return (
    <div className='flex items-center justify-between gap-3'>
      <div className='w-full overflow-hidden flex-1 flex items-center p-3 gap-3'>
        <div className='flex-auto'>
          <ChartDisplayName className='overflow-hidden overflow-ellipsis whitespace-nowrap'>
            {chart.displayName}
          </ChartDisplayName>
        </div>
      </div>
      <div className='flex'>
        <div className='btn-group pr-3 opacity-60'>
          <CopyToClipboard text={`${window.origin}/embed/${chart.id}`}>
            <SaturatedButton
              onClick={() => {
                toast.success('Link copied', {
                  autoClose: 2000,
                });
              }}
            >
              <BsCode size='0.8rem' />
              Embed
            </SaturatedButton>
          </CopyToClipboard>
          <SaturatedButton className='hidden'>
            <RiShareBoxFill size='0.8rem' />
            Share
          </SaturatedButton>
        </div>
      </div>
    </div>
  );
}

export default memo(ChartComposeHeader);
