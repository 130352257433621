export const formatColumn = (
  columnName?: string,
  params?: {
    withComma?: boolean;
  }
) => {
  if (params?.withComma) {
    return `${columnName}, `;
  }
  return `${columnName}`;
};
