import { useCookies } from 'react-cookie';
import { useRecoilValue } from 'recoil';
import { AuthState } from './AuthState';
import ProfileAvatar from './ProfileAvatar';

export function UserAvatar() {
  const auth = useRecoilValue(AuthState);
  const [cookies] = useCookies(['auth']);
  const nameParts = auth?.name?.split(' ');

  if (
    !auth?.profileImage &&
    (!nameParts || (nameParts && nameParts?.length < 2)) &&
    !cookies.auth?.username
  ) {
    return <ProfileAvatar />;
  }

  return (
    <div className='avatar placeholder'>
      <div className='bg-neutral-focus text-neutral-content rounded-full w-10'>
        {auth?.profileImage ? (
          <ProfileAvatar img={auth?.profileImage} />
        ) : (
          <span className='text-md'>
            {cookies.auth?.username.charAt(0) ||
              (nameParts &&
                `${nameParts[0].charAt(0)} ${nameParts[1].charAt(0)}`)}
          </span>
        )}
      </div>
    </div>
  );
}
