import { memo, useEffect, useState } from 'react';
import { BiCopy } from 'react-icons/bi';
import { AiFillFileAdd } from 'react-icons/ai';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

import './query-history.scss';
import { useQueryHistoryV3 } from './QueryHistoryHookV3';
import { useQueryId, useSearchParam } from './QueryPadState';
import { convertNumberToDatetime } from '../../utils/date';
import { DefaultAutoDismissMs } from '../../config/toast';
import classNames from 'classnames';
import LoadingIndicator from '../../components/LoadingIndicator';

const pageSize = 20;

function QueryHistoryV3() {
  const [queryId] = useQueryId();
  const queryRunId = useSearchParam('runId');
  const [{ queryHistory, rpcStatus }, { getQueryHistory }] = useQueryHistoryV3({
    queryId,
  });

  const [limit, setLimit] = useState(pageSize);

  useEffect(() => {
    getQueryHistory();
  }, []);

  if (rpcStatus?.isLoading) {
    return (
      <div className='mt-4'>
        <div className='flex items-center justify-center p-20'>
          <LoadingIndicator />
        </div>
      </div>
    );
  }

  if (rpcStatus?.error) {
    return (
      <div className='mt-4'>
        <div>{rpcStatus?.error}</div>
      </div>
    );
  }

  const list = queryHistory?.items || [];
  return (
    <div className='p-3 query-history'>
      <div className='text-lg'>Query History</div>
      <table className='w-full table table-compact table-zebra'>
        <tr>
          <th className='w-1'>STARTED</th>
          <th className='w-1'>DURATION</th>
          <th className='w-1'>ROWS</th>
          <th>SQL</th>
          <th className='w-1'>ACTIONS</th>
        </tr>

        {list?.slice(0, limit).map((item, idx: number) => {
          const link = `${document.location.href.split('?')[0]}?runId=${
            item.id
          }`;
          return (
            <tr
              key={idx}
              className={classNames({
                'bg-primary bg-opacity-5': item.id === queryRunId,
              })}
            >
              <td>{convertNumberToDatetime(item.createdTime)}</td>
              <td>-</td>
              <td>-</td>
              <td className='w-full whitespace-pre-wrap'>
                <div className='w-full whitespace-pre-wrap break-all bg-base-content bg-opacity-[0.06] p-2'>
                  {item.text || ''}
                </div>
              </td>
              <td>
                <div className='flex items-center justify-end'>
                  <div
                    className='tooltip'
                    data-tip='Open query in a new window'
                  >
                    <span
                      className='p-1 cursor-pointer'
                      onClick={() => {
                        window.open(link);
                      }}
                    >
                      <AiFillFileAdd />
                    </span>
                  </div>

                  <div className='tooltip' data-tip='Copy query history link'>
                    <CopyToClipboard text={link}>
                      <span
                        className='p-1 cursor-pointer'
                        onClick={() => {
                          toast.success('Link copied', {
                            autoClose: DefaultAutoDismissMs,
                          });
                        }}
                      >
                        <BiCopy />
                      </span>
                    </CopyToClipboard>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </table>
      {limit < list?.length && (
        <button
          className='btn btn-sm btn-primary'
          onClick={() => {
            setLimit(limit + pageSize);
          }}
        >
          Show more
        </button>
      )}
    </div>
  );
}

export default memo(QueryHistoryV3);
