import { BsFillPlayFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import LoadingIndicator from '../../../components/LoadingIndicator';
import { H3 } from '../../../components/typographic/Headers';
import GraphQLEditor from './GraphqlEditor';
import { useRunQueryApiV3 } from './GraphqlHooksV3';
import { useState } from 'react';

// Creating this component because we might have some customization later
// Will probably revisit this in the future
function GraphqlV3() {
  const [{ graphqlState, runQuery }, loading] = useRunQueryApiV3();

  const [showSchema, setShowShema] = useState(true);

  return (
    <div className='flex'>
      <div className='flex flex-col flex-grow h-full w-2/3 px-8 py-6'>
        <div className='inline-flex items-center justify-between w-full pb-2'>
          <H3>GraphQL endpoint</H3>
          <div className='flex'>
            {!showSchema && (
              <button
                className='mr-8 btn-ghost px-4 border border-gray-300 rounded-md'
                onClick={() => {
                  (
                    document.querySelector('.docExplorerShow') as HTMLElement
                  )?.click();
                  setShowShema(true);
                }}
              >
                Show Schema
              </button>
            )}
            <button
              className='btn-primary btn-circle flex justify-center items-center'
              onClick={() => {
                if (loading) return;
                toast.dismiss();

                runQuery(graphqlState.query);
              }}
            >
              {loading ? (
                <LoadingIndicator color='white' />
              ) : (
                <BsFillPlayFill color='#fff' size='1.5rem' />
              )}
            </button>
          </div>
        </div>
        <div className='flex-1'>
          <GraphQLEditor setShowShema={setShowShema} />
        </div>
      </div>
      {/**
         * 
      <div className='divider-y' />
      {schemaShown ? (
        <div className='h-full w-1/3 px-8 py-6'>
          <div className='inline-flex items-center justify-between w-full h-[5vh]'>
            <div className='inline-flex items-center h-full w-auto'>
              <button className='btn btn-sm btn-ghost rounded-full p-0'>
                <BiChevronLeft size='1.5rem' className='text-primary' />
              </button>
              <div className='w-1' />
              <span className='text-lg text-primary'>Schema</span>
            </div>
            <div className='inline-flex items-center h-full w-auto'>
              <button
                className='btn btn-sm btn-ghost rounded-full p-0'
                onClick={() => toggleSchemaShown(false)}
              >
                <BiChevronRight size='1rem' className='text-gray-500' />
              </button>
              <div className='w-1' />
              <span className='text-sm text-gray-500'>Hide</span>
            </div>
          </div>
          <div className='h-3' />
          <SearchBar onSearch={() => {}} placeholder='Find a Schema' />
          <div className='h-3' />
          <div className='h-5/6 flex flex-col overflow-auto'>
            <span className='flex w-full h-auto text-lg'>
              ContractToPoolMapping
            </span>
          </div>
        </div>
      ) : (
        <div className='min-h-full w-[10%]'>
          <div className='flex items-center justify-center h-full w-full'>
            <button
              className='btn btn-primary'
              onClick={() => toggleSchemaShown(true)}
            >
              Show
              <BiChevronRight size='1.5rem' />
            </button>
          </div>
        </div>
      )}
         */}
    </div>
  );
}

export default GraphqlV3;
