import produce from 'immer';
import { max, set, sum, uniq, unset } from 'lodash';
import numeral from 'numeral';
import {
  BarChartUiOptions,
  YCol,
} from '../../queryBuilder/components/BarChartComposeV2Hooks';
import { QueryResultResult } from '../../queryBuilder/QueryState';
import { EChartOption } from '../components/EChartV2';
import { EchartTypes } from '../types-v2';
import { BroswerFloatLengthMax, defaultChartTheme } from './const';

export const DefaultBarChartUiOptions: BarChartUiOptions = {
  version: 2,
  chartOptions: {
    showLegend: true,
  },
};

export const DefaultEChartOptions: EChartOption = {
  grid: {
    containLabel: true,
    bottom: 30,
    top: 30,
    left: 60,
    right: 30,
  },
  legend: {
    orient: 'vertical',
    type: 'scroll',
    right: 10,
    padding: [18, 0],
    top: 'center',
    animation: false,
  },
  yAxis: [
    {
      type: 'value',
    },
  ],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
    },
  },
};

export const DefaultBarChartProps = {
  type: 'bar',
  displayName: 'Bar chart',
} as const;

function getChartTypeProps({
  chartType,
  type,
}: {
  chartType?: string;
  type: string;
}) {
  const props = {
    type: chartType || type || 'bar',
  };

  if (chartType === 'area') {
    // @ts-ignore
    props.areaStyle = {};
    props.type = 'line';
  }
  return props;
}

function getNormalizedValue({
  value,
  sumValue,
  fixedTo = BroswerFloatLengthMax,
}: {
  value: number | string;
  sumValue?: number | string;
  fixedTo?: number;
}): number {
  try {
    return parseFloat(
      (
        (parseFloat(`${value}`) / (parseFloat(`${sumValue}`) || 1)) *
        100
      ).toFixed(fixedTo)
    );
  } catch (error) {
    return 0;
  }
}

function normalizeFormatter(value: number) {
  return `${value.toFixed(2)} %`;
}

function generateData({
  yCol,
  barChartOptions,
  queryResults,
  dataSums,
}: {
  yCol: YCol;
  barChartOptions: EchartTypes;
  queryResults?: QueryResultResult;
  dataSums: number[] | null;
}) {
  const { chartOptions } = barChartOptions.uiOptions;
  const rows = queryResults?.rows || [];

  // normalized
  const normalizeData = chartOptions?.normalizeData;

  if (normalizeData) {
    return rows.map((row, idx) =>
      getNormalizedValue({
        value: row[yCol.colName],
        sumValue: dataSums?.[idx],
      })
    );
  }

  // regular
  return rows.map((row) => row[yCol.colName]);
}

/**
 * This is pure function and must be kept as idempotant
 */
export function connectEChartData({
  chart,
  queryResults,
  defaultOption = DefaultEChartOptions,
}: {
  chart: EchartTypes;
  queryResults?: QueryResultResult;
  defaultOption?: EChartOption;
}): EChartOption {
  const { xAxisOptions, yAxisOptions, dataOptions, chartOptions } =
    chart.uiOptions;
  const rows = queryResults?.rows || [];

  const showLegend = chartOptions?.showLegend;
  const enableStacking = chartOptions?.enableStacking;
  const normalizeData = chartOptions?.normalizeData;
  const logarithmic = yAxisOptions?.logarithmic;

  const xAxisLabel = xAxisOptions?.label;
  const xAxisTickFormat = xAxisOptions?.tickFormat;

  const yAxisLabel = yAxisOptions?.label;
  const yAxisLabelFormat = yAxisOptions?.labelFormat;
  const yAxisTickFormat = yAxisOptions?.tickFormat;

  const xColumnName = dataOptions?.xCol?.colName;
  const groupByCol = dataOptions?.groupByCol;
  const groupByYCols = chart?.uiOptions?.dataOptions?.groupByYCols || [];

  return produce(defaultOption, (draft) => {
    set(draft, 'animationDuration', 200);

    if (xColumnName) {
      set(draft, 'xAxis.type', 'category');

      if (groupByCol) {
        set(draft, 'xAxis.data', uniq(rows.map((row) => row[xColumnName])));
      } else {
        set(
          draft,
          'xAxis.data',
          rows.map((row) => row[xColumnName])
        );
      }
    } else {
      set(draft, 'xAxis.data', []);
    }

    const yCols = dataOptions?.yCols || [];

    // eslint-disable-next-line
    let valueFormatter = (value: any) => value;

    if (normalizeData) {
      valueFormatter = normalizeFormatter;
    } else if (yAxisLabelFormat) {
      valueFormatter = (value) => numeral(value).format(yAxisLabelFormat);
    }

    const firstYCol = yCols?.[0];
    if (groupByCol && firstYCol) {
      // GROUP BY
      const sumMapByXAxis = xColumnName
        ? rows.reduce((p, row) => {
            const xValue = row[xColumnName];
            const yValue = row[firstYCol.colName];

            if (typeof yValue === 'number') {
              const key = `${xValue}`;

              if (xValue && p[xValue]) {
                // @ts-ignore
                p[key] += yValue;
              } else {
                p[key] = yValue;
              }
            }
            return p;
          }, {})
        : {};

      const series = groupByYCols.map((yCol, colIdx) => {
        const data = rows
          .filter((row) => row[groupByCol] === yCol.colName)
          .map((row) => {
            if (xColumnName) {
              // value as is
              const value = row[firstYCol?.colName];
              if (!normalizeData) {
                return value;
              }

              // normalized value
              const xAxisKey = `${row[xColumnName]}` || '';
              const sumValue = sumMapByXAxis[xAxisKey] || 1;
              const percent = getNormalizedValue({
                value,
                sumValue,
              });

              return percent;
            }
            return 0;
          });

        return {
          data,
          name: showLegend ? yCol.customLabel || yCol.colName : '',
          stack: enableStacking ? 'Stack1' : null,
          color:
            yCol.color || defaultChartTheme[colIdx % defaultChartTheme.length],

          tooltip: {
            valueFormatter,
          },
          ...getChartTypeProps({
            chartType: yCol?.chartType,
            type: chart.type,
          }),
        };
      });
      set(draft, 'series', series);
    } else if (!groupByCol) {
      const dataSums = normalizeData
        ? rows.map((row) =>
            sum(
              yCols.map((yCol) =>
                parseFloat(
                  parseFloat(`${row[yCol.colName]}`).toFixed(
                    BroswerFloatLengthMax
                  )
                )
              )
            )
          )
        : null;

      // GROUP BY
      set(
        draft,
        'series',
        yCols.map((yCol, colIdx) => {
          return {
            data: generateData({
              yCol,
              barChartOptions: chart,
              queryResults,
              dataSums,
            }),
            name: showLegend ? yCol.customLabel || yCol.colName : '',
            stack: enableStacking ? 'Stack1' : null,
            color:
              yCol.color ||
              defaultChartTheme[colIdx % defaultChartTheme.length],
            tooltip: {
              valueFormatter,
            },
            ...getChartTypeProps({
              chartType: yCol?.chartType,
              type: chart.type,
            }),
          };
        })
      );
    }

    if (logarithmic) {
      set(draft, 'yAxis.[0].type', 'log');
    } else {
      set(draft, 'yAxis.[0].type', 'value');
    }

    const targetLegends = groupByCol ? groupByYCols : yCols;
    const legendsLength = targetLegends
      .map((yCol) => yCol.customLabel || yCol.colName || '')
      .map((l) => l.length);

    const maxLegendLength = max(legendsLength) || 30;

    if (showLegend) {
      set(draft, 'grid.right', maxLegendLength * 6 + 70);
    } else {
      set(draft, 'grid.right', 30);
    }

    if (yAxisLabel) {
      set(draft, 'yAxis.[0].name', yAxisLabel);
      set(draft, 'yAxis.[0].nameLocation', 'middle');
      // https://github.com/apache/echarts/issues/9265
      // positioning y label
      set(draft, 'yAxis.[0].nameGap', 70);
      set(draft, 'yAxis.[0].nameTextStyle', { fontWeight: 'bold' });
    } else {
      unset(draft, 'yAxis.[0].name');
      unset(draft, 'yAxis.[0].nameLocation');
      unset(draft, 'yAxis.[0].nameGap');
      unset(draft, 'xAxis.[0].nameTextStyle');
    }

    if (xAxisLabel) {
      set(draft, 'xAxis.name', xAxisLabel);
      set(draft, 'xAxis.nameLocation', 'middle');
      set(draft, 'xAxis.nameGap', 20);
      set(draft, 'xAxis.nameTextStyle', { fontWeight: 'bold' });
    } else {
      unset(draft, 'xAxis.name');
      unset(draft, 'xAxis.nameLocation');
      unset(draft, 'xAxis.nameGap');
      unset(draft, 'xAxis.nameTextStyle');
    }

    if (normalizeData) {
      set(draft, 'yAxis.[0].axisLabel.formatter', '{value} %');
      set(draft, 'yAxis.[0].max', 100);
    } else if (yAxisTickFormat) {
      set(draft, 'yAxis.[0].axisLabel.formatter', yAxisTickFormat);
    } else {
      unset(draft, 'yAxis.[0].axisLabel.formatter');
    }
    if (xAxisTickFormat) {
      set(draft, 'xAxis.axisLabel.formatter', xAxisTickFormat);
    } else {
      unset(draft, 'xAxis.axisLabel.formatter');
    }
  });
}
