import { BiRocket } from 'react-icons/bi';
import { useRecoilState } from 'recoil';
import LoadingIndicator from '../../components/LoadingIndicator';

import Modal from '../../components/modals/Modal';
import { ApiEditFormState, useApiId, useUpdateApi } from './ViewApiHooks';

export default function ApiUnpublishModal({
  openPublish,
  setOpenPublish,
}: {
  openPublish?: boolean;
  setOpenPublish: (v: boolean) => void;
}) {
  const apiId = useApiId();
  const [{ rpcStatus }, { updateApi }] = useUpdateApi();
  const [values, setValues] = useRecoilState(ApiEditFormState(apiId));

  return (
    <Modal open={openPublish}>
      <div className='flex flex-col gap-2'>
        <div className='text-xl font-bold'>Remove API from Community</div>
        <div className='text-sm opacity-70'>
          Unpublishing this API will remove it from the Community hub and
          prevent people from finding and using this API
          <br />
          <br />
        </div>
      </div>
      <div className='flex flex-col gap-3'>
        <div className='flex gap-2 items-center'>
          <button
            className='btn gap-3 btn-error font-bold text-white'
            onClick={async () => {
              const nValues = {
                ...values,
                access: 'private',
              };
              const success = await updateApi(nValues);

              if (success) {
                setValues(nValues);
                setOpenPublish(false);
              }
            }}
          >
            <BiRocket size='1.1rem' />
            Unpublish
          </button>

          <button
            className='btn btn-ghost'
            style={{ border: '1px solid #aaaaaa' }}
            onClick={() => {
              setOpenPublish(false);
            }}
          >
            Cancel
          </button>
          {rpcStatus.isLoading && <LoadingIndicator />}
        </div>
      </div>
    </Modal>
  );
}
