import { SQLIconV2 } from '../../components/icons/Icons';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { useMyQueries } from './QueriesHooks';
import { useEffect } from 'react';
import { convertNumberToFormatted, DateFormat } from '../../utils/date';
import ProfileAvatar from '../auth/ProfileAvatar';
import LogoBlinkerCenter from '../../components/app/LogoBlinkerCenter';
import { useClientPageSize } from '../../hooks/network';
import OnVisible from './components/OnVisible';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import BodyHeader3 from '../../components/typographic/BodyHeader3';
import { HorizontalLine } from '../../components/HorizontalLine';
import AddEntityButton from './components/AddEntityButton';
import { Link } from 'react-router-dom';

export default function QueryListToApiPage() {
  const [{ menu }, { getPath }] = useWorkspace();

  const [{ myQueries, rpcStatus }, { getMyQueries }] = useMyQueries();
  const [pageSize, { showMore }] = useClientPageSize('query-list');

  useEffect(() => {
    getMyQueries();
  }, []);

  if (!myQueries?.items?.length && rpcStatus.isLoading) {
    return (
      <div className='flex w-full items-center py-20 justify-center'>
        <LogoBlinkerCenter />
      </div>
    );
  }

  return (
    <LayoutBodyCard>
      <div className='flex px-6 py-3 items-center justify-between'>
        <BodyHeader3>Create API</BodyHeader3>
      </div>
      <HorizontalLine />
      <div className='explorer flex flex-col w-full p-3 overflow-x-auto'>
        <div className='flex w-full space-x-5 items-center p-2'>
          <AddEntityButton
            title='Create API With SQL'
            to={getPath('queries/new')}
          />
        </div>
        <table className='table w-full'>
          <thead className='rounded-full w-full'>
            <tr className='w-full font-semibold opacity-30'>
              <th className='p-2'>NAME</th>
              <th className='p-2 w-2'>CREATED BY</th>
              <th className='p-2 w-2'>
                <span className='flex items-center'>CREATED AT</span>
              </th>
              <th className='p-2 w-2 text-center'>SELECT</th>
            </tr>
          </thead>
          <tbody className='rounded-full w-full'>
            {myQueries.items?.slice(0, pageSize).map(
              ({
                id,
                // @ts-ignore
                displayName,
                creator,
                createdTime,
              }) => (
                <tr
                  key={id}
                  className='font-semibold items-center overflow-hidden'
                >
                  <td className='p-2 overflow-hidden'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div className='flex flex-auto items-center text-lg space-x-2 pr-2 overflow-hidden w-[42rem]'>
                        <SQLIconV2
                          style={{ width: '1.5rem' }}
                          className='flex-none'
                        />
                        <span
                          title={displayName || id}
                          className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                        >
                          {displayName || id}
                        </span>
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 overflow-hidden'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div className='flex flex-auto w-[12rem] justify-start items-center gap-3'>
                        <ProfileAvatar
                          img={creator?.profileImage}
                          className='flex-none w-6 bg-base-content bg-opacity-30'
                        />
                        <span
                          title={creator?.name}
                          className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                        >
                          {creator?.name}
                        </span>
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 w-8 opacity-50'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div>
                        {convertNumberToFormatted(createdTime, {
                          formatStr: DateFormat,
                        })}
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 w-8'>
                    <div>
                      <Link to={`${getPath('apis')}/new?queryId=${id}`}>
                        <button className='btn btn-primary'>
                          Select to Create
                        </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            )}
            <OnVisible
              onVisible={() => {
                showMore();
              }}
            />
          </tbody>
        </table>
      </div>
    </LayoutBodyCard>
  );
}
