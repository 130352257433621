import { useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useApiId } from '../../api/ViewApiHooks';
import { zToast } from '../../toast/toast';
import { runGraphqlQuery } from './GraphqlApis';

type GraphqlApiStateType = {
  query: string;
};

export const GraphqlApiState = atom<GraphqlApiStateType>({
  key: 'GraphqlApiState',
  default: { query: '' },
});

type GraphqlQueryResultType = {
  result?: unknown;
};

const defaultResult = {
  result: {
    data: {},
  },
};
export const GraphqlApiResultState = atom<GraphqlQueryResultType>({
  key: 'GraphqlApiResultState',
  default: defaultResult,
});

export function useRunQueryApiV3() {
  const apiId = useApiId();
  const [graphqlState, setGraphqlState] = useRecoilState(GraphqlApiState);

  const [loading, setLoading] = useState(false);
  const [result, setResult] = useRecoilState(GraphqlApiResultState);

  // to replace generic error which is unusable
  useEffect(() => {
    setResult({ result: { msg: 'init' } });
    setTimeout(() => {
      setResult({ result: {} });
    }, 10);
    return () => {
      setResult({ result: {} });
    };
  }, []);

  async function runQuery(query: string) {
    if (!apiId) {
      return;
    }

    setLoading(true);
    setResult({ result: {} });

    const resp = await runGraphqlQuery({ query, apiId });

    // TODO deal with error type
    // @ts-ignore
    if (resp?.error) {
      // @ts-ignore
      zToast.warning(resp?.error);
    }

    // handle results
    if (resp?.data) {
      setResult({ result: resp?.data });
    }

    setLoading(false);
  }

  return [
    { graphqlState, setGraphqlState, runQuery, result, setResult },
    loading,
  ] as const;
}
