import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { isEmpty, startsWith } from 'lodash';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';

import './layout-v3.scss';
import Navbar from './Navbar';
import { AuthState } from '../modules/auth/AuthState';
import { AuthApi } from '../api/client';
import FullPageLoading from '../components/app/FullPageLoading';
import FooterV3 from './components/FooterV3';

interface IAuthRouterProps {
  onComplete: () => void;
}

function AuthRouter({ onComplete }: IAuthRouterProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [auth, setAuth] = useRecoilState(AuthState);
  const [cookies] = useCookies(['auth']);

  useEffect(() => {
    const refreshAccessToken = async () => {
      const client = AuthApi();
      try {
        const resp = await client.postAuth();
        if (resp && resp.data.accessToken && resp.data.user?.id) {
          setAuth({
            accessToken: resp.data.accessToken,
            id: resp.data.user.id,
            name: resp.data.user.name,
            email: resp.data.user.email,
            profileImage: resp.data.user.profileImage,
          });
        } else {
          setAuth(null);
        }

        setTimeout(() => {
          onComplete();
        }, 1);
      } catch (error) {
        setAuth(null);
      }
    };
    refreshAccessToken();
  }, []);

  useEffect(() => {
    const isAuthRoute = startsWith(location.pathname, '/auth');
    if (!isAuthRoute && auth === null && isEmpty(cookies.auth)) {
      const backParam = encodeURIComponent(location.pathname);
      navigate(`/auth/login${backParam ? `?back=${backParam}` : ''}`);
    }
  }, [cookies.auth, auth, location.pathname]);

  return null;
}

function LayoutV3() {
  const [isLoading, toggleLoading] = useState(true);
  return (
    // Lock the width [responsive]
    // https://datalego.atlassian.net/browse/DL-353
    <div className='layout-v3 container min-w-[1240px] w-[1240px] mx-auto px-5'>
      <AuthRouter onComplete={() => toggleLoading(false)} />
      {!isLoading && <Navbar />}
      {isLoading ? <FullPageLoading /> : <Outlet />}
      <FooterV3 />
    </div>
  );
}

export default LayoutV3;
