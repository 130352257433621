import { HtmlHTMLAttributes, useCallback, useEffect, useState } from 'react';

import {
  SelectedTableSchemaState,
  useQueryPadInject,
} from '../database/TableSchemaState';
import { AiFillCaretDown, AiFillCheckCircle } from 'react-icons/ai';
import { useRecoilState } from 'recoil';
import { SelectedDBState } from '../database/DatabaseState';
import { SelectedSchemaState } from '../database/SchemaState';
import classNames from 'classnames';
import {
  useCategories,
  useDatabases,
  useTables,
} from '../database/schema-hooks';
import { useQueryIdV3 } from './QueryPadStateV3';
import { isEmpty } from 'lodash';
import TableColumnsV3 from './TableColumnsV3';
import { FiArrowRightCircle, FiPlusCircle } from 'react-icons/fi';
import './querybuilder-right-panel.scss';
import { formatSql } from './QueryPadState';
import { ModelTable } from '../../api/__gen__/data-contracts';
import { formatColumn } from './utils/sql';
import { clientSqlDefaultLimit } from '../dashboard/config';
import { SqlLanguage } from 'sql-formatter';
import { dbGroup1, dbGroup2 } from './const';

function SelectorLineItem<T>({
  selectedValue,
  item,
  getKey,
  getLabel,
  onSelectItem,
  onInjectSql,
  onInsertName,
}: {
  item: T;
  selectedValue?: string;
  getKey: (value: T) => string;
  getLabel: (value?: T) => string;
  onSelectItem: (value: T) => void;
  onInjectSql?: (value: T) => void;
  onInsertName?: (value: T) => void;
} & HtmlHTMLAttributes<HTMLDivElement>) {
  return (
    <div
      key={getKey(item)}
      className='db-lineitem cursor-pointer py-3 px-5 flex items-center gap-2 text-sm flex-nowrap w-full hover:bg-base-300'
      onClick={() => {
        onSelectItem(item);
      }}
    >
      {selectedValue === getKey(item) && (
        <AiFillCheckCircle size='1.1rem' className='text-primary flex-none' />
      )}
      <span className='flex-auto text-ellipsis whitespace-nowrap'>
        <div className='flex items-center justify-between'>
          <span>{getLabel(item)}</span>
          {onInsertName && onInjectSql && (
            <span
              className='flex gap-1 db-item-actions'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <FiArrowRightCircle
                className='opacity-40'
                onClick={() => {
                  onInjectSql(item);
                }}
              />
              <FiPlusCircle
                className='opacity-40'
                onClick={() => {
                  onInsertName(item);
                }}
              />
            </span>
          )}
        </div>
      </span>
    </div>
  );
}

function SelectorSectionItem<T>({
  isOpen,
  label,
  selectedValue,
  onClick,
  items,
  getKey,
  getLabel,
  onSelectItem,
  onInjectSql,
  onInsertName,
  group1,
  group2,
}: {
  items: T[];
  label: string;
  isOpen?: boolean;
  selectedValue?: string;
  getKey: (value: T) => string;
  getLabel: (value?: T) => string;
  onSelectItem: (value: T) => void;
  onInjectSql?: (value: T) => void;
  onInsertName?: (value: T) => void;
  group1?: string[];
  group2?: string[];
} & HtmlHTMLAttributes<HTMLDivElement>) {
  const selectedItem = items?.find((c) => getKey(c) === selectedValue);
  const valueLabel = getLabel(selectedItem) || '';

  const group1Items =
    (group1 && items.filter((i) => group1.includes(getKey(i)))) || [];
  const group2Items =
    (group2 && items.filter((i) => group2.includes(getKey(i)))) || [];

  const groupHasItems = !isEmpty(group2Items) && !isEmpty(group1Items);

  const restItems = groupHasItems
    ? items.filter(
        (i) =>
          ![
            ...group1Items.map((i2) => getKey(i2)),
            ...group2Items.map((i2) => getKey(i2)),
          ].includes(getKey(i))
      )
    : items;

  return (
    <div className='w-full flex-none'>
      <div
        className='w-full flex items-center pl-2 py-4 cursor-pointer text-xs'
        onClick={onClick}
      >
        <span className='w-[33%] font-semibold opacity-40 flex-none'>
          {label}
        </span>
        <div
          className='flex-auto font-semibold overflow-hidden whitespace-nowrap text-ellipsis'
          title={valueLabel}
        >
          {valueLabel}
        </div>
        <div className='px-2'>
          <AiFillCaretDown
            size='1rem'
            className={classNames('opacity-30 flex-none', {
              'rotate-180': isOpen,
            })}
          />
        </div>
        {label === 'TABLES' && selectedItem && onInjectSql && onInsertName && (
          <div className='px-2'>
            <span
              className='flex gap-1 db-item-actions'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <div className='tooltip z-30' data-tip='Insert Full Basic SQL'>
                <FiArrowRightCircle
                  className='opacity-40'
                  onClick={() => {
                    onInjectSql(selectedItem);
                  }}
                />
              </div>
              <div className='tooltip z-30' data-tip='Insert Name in SQL'>
                <FiPlusCircle
                  className='opacity-40'
                  onClick={() => {
                    onInsertName(selectedItem);
                  }}
                />
              </div>
            </span>
          </div>
        )}
      </div>
      <div className='border-b' />
      <div
        className={classNames(
          'overflow-auto transition-max-height duration-400 ease-in-out',
          isOpen && !isEmpty(items) ? 'max-h-[60vh]' : 'max-h-0'
        )}
      >
        <div className='w-full flex flex-col items-center text-xs bg-base-200'>
          {!isEmpty(group1Items) && (
            <div className='flex px-5 py-1 bg-gray-200 text-[10px] font-bold w-full'>
              <span className='opacity-40'>CHAIN DATA</span>
            </div>
          )}

          {group1Items?.map((item) => (
            <SelectorLineItem
              key={getKey(item)}
              item={item}
              getKey={getKey}
              getLabel={getLabel}
              selectedValue={selectedValue}
              onSelectItem={onSelectItem}
              onInjectSql={onInjectSql}
              onInsertName={onInsertName}
            />
          ))}

          {!isEmpty(group2Items) && (
            <div className='flex px-5 py-1 bg-gray-200 text-[10px] font-bold w-full'>
              <span className='opacity-40'>ABSTRACTION DATA</span>
            </div>
          )}

          {group2Items?.map((item) => (
            <SelectorLineItem
              key={getKey(item)}
              item={item}
              getKey={getKey}
              getLabel={getLabel}
              selectedValue={selectedValue}
              onSelectItem={onSelectItem}
              onInjectSql={onInjectSql}
              onInsertName={onInsertName}
            />
          ))}

          {groupHasItems && (
            <div className='flex px-5 py-1 bg-gray-200 text-[10px] font-bold w-full'>
              <span className='opacity-40'>PROJECT DATA</span>
            </div>
          )}

          {restItems?.map((item) => (
            <SelectorLineItem
              key={getKey(item)}
              item={item}
              getKey={getKey}
              getLabel={getLabel}
              selectedValue={selectedValue}
              onSelectItem={onSelectItem}
              onInjectSql={onInjectSql}
              onInsertName={onInsertName}
            />
          ))}
        </div>
        <div className='border-b' />
      </div>
    </div>
  );
}

export default function QueryBuilderLeftPanel() {
  // selector states
  const [selectedDB, setDB] = useRecoilState(SelectedDBState);
  const [selectedSchema, setSchema] = useRecoilState(SelectedSchemaState);
  const [selectedTable, setTableSchema] = useRecoilState(
    SelectedTableSchemaState
  );

  const handleDatabaseSelect = useCallback(
    (value?: string) => {
      setDB(value);
    },
    [setDB]
  );

  const handleSchemaSelect = useCallback(
    (value?: string) => {
      if (selectedDB) {
        setSchema({ database: selectedDB, schema: value });
      }
    },
    [selectedDB, setSchema]
  );

  const handleTableSelect = useCallback(
    (value?: string) => {
      setTableSchema(value);
    },
    [setTableSchema]
  );

  const [openSection, setOpenSection] = useState<
    'category' | 'database' | 'table' | null
  >(null);

  const [{ categories }] = useCategories();
  const [{ databases }] = useDatabases(selectedDB);
  const [{ tables }] = useTables(selectedSchema, { autoRefetch: true });

  const queryId = useQueryIdV3();

  // select category
  useEffect(() => {
    const firstCategory = categories?.items?.[0]?.id;

    if (!queryId && !selectedDB && firstCategory) {
      handleDatabaseSelect(firstCategory);
    }
  }, [queryId, categories, selectedDB]);

  useEffect(() => {
    const firstItem = databases?.items?.[0].name;

    // default database
    if (!selectedSchema?.schema && firstItem && selectedDB) {
      handleSchemaSelect(firstItem);
      return;
    }

    // set first item when selected is not found in the new list
    if (
      firstItem &&
      selectedSchema?.schema &&
      !databases?.items?.find((d) => d.name === selectedSchema.schema) &&
      selectedDB
    ) {
      handleSchemaSelect(firstItem);
    }
  }, [queryId, selectedSchema, databases, selectedDB]);

  useEffect(() => {
    const firstItem = tables?.items?.[0].name;

    // set default table
    if (!selectedTable && firstItem) {
      handleTableSelect(firstItem);
      return;
    }

    // set first item when selected is not found in the new list
    if (
      firstItem &&
      selectedTable &&
      !tables?.items?.find((t) => t.name === selectedTable)
    ) {
      handleTableSelect(firstItem);
    }
  }, [queryId, selectedTable, tables]);

  // query inject
  const injectKeyword = useQueryPadInject();

  const handleInject = useCallback(
    (value: string) => {
      injectKeyword(value);
    },
    [injectKeyword]
  );

  const selectedCategoryObj = categories?.items?.find(
    (i) => i.id === selectedDB
  );

  const handleFormat = useCallback(
    (table: ModelTable) => {
      if (selectedSchema?.schema) {
        injectKeyword(
          formatSql(
            `SELECT ${table.columns
              ?.map((item) => formatColumn(item.name))
              .join(', ')}\n  FROM ${selectedSchema.schema}.${
              table?.name
            } limit ${clientSqlDefaultLimit}`,
            {
              language: selectedCategoryObj?.sqlDialect as SqlLanguage,
            }
          )
        );
      }
    },
    [injectKeyword, selectedSchema, selectedCategoryObj?.sqlDialect]
  );

  const isCategoryOpen = openSection === 'category';
  const isDatabaseOpen = openSection === 'database';
  const isTableOpen = openSection === 'table';

  // Side bar
  return (
    <div className='flex flex-col w-full flex-none max-h-[96vh] overflow-visible'>
      <SelectorSectionItem
        label='CATEGORY'
        selectedValue={selectedDB}
        getKey={(item) => item.id ?? ''}
        getLabel={(item) => item?.displayName ?? ''}
        items={categories?.items || []}
        isOpen={isCategoryOpen}
        onClick={() => {
          if (isCategoryOpen) {
            setOpenSection(null);
          } else {
            setOpenSection('category');
          }
        }}
        onSelectItem={(item) => {
          handleDatabaseSelect(item.id);
        }}
      />

      <SelectorSectionItem
        label='DATABASE'
        selectedValue={selectedSchema?.schema || ''}
        getKey={(item) => item.name ?? ''}
        getLabel={(item) => item?.name ?? ''}
        items={databases?.items || []}
        group1={dbGroup1}
        group2={dbGroup2}
        isOpen={isDatabaseOpen}
        onClick={() => {
          if (isDatabaseOpen) {
            setOpenSection(null);
          } else {
            setOpenSection('database');
          }
        }}
        onSelectItem={(item) => {
          handleSchemaSelect(item.name || '');
        }}
      />

      <SelectorSectionItem
        label='TABLES'
        selectedValue={selectedTable || ''}
        getKey={(item) => item.name ?? ''}
        getLabel={(item) => item?.name ?? ''}
        items={tables?.items || []}
        isOpen={isTableOpen}
        onClick={() => {
          if (isTableOpen) {
            setOpenSection(null);
          } else {
            setOpenSection('table');
          }
        }}
        onSelectItem={(item) => {
          handleTableSelect(item.name || '');
        }}
        onInjectSql={(item) => {
          handleFormat(item);
        }}
        onInsertName={(item) => {
          if (item.name) {
            handleInject(`${selectedSchema?.schema}.${item?.name}`);
          }
        }}
      />

      <div className='label font-semibold opacity-50 flex items-center justify-between'>
        <span className='label-text font-semibold p-2 text-xs'>COLUMNS</span>
      </div>
      <TableColumnsV3 onInsertClick={handleInject} />
    </div>
  );
}
