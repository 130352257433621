import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from 'react-icons/md';
import classNames from 'classnames';
import { HTMLAttributes, memo, useMemo } from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from 'react-table';
import LogoBlinkerCenter from '../../../components/app/LogoBlinkerCenter';
import { useWidth } from '../../../hooks/layout';
import { TableChartEntity } from '../types';
import { QueryResultResult } from '../../queryBuilder/QueryState';

interface ITableChartProps {
  queryResults?: QueryResultResult;
  chart: TableChartEntity;
  loading?: boolean;
}

function TableChartV2({
  className,
  queryResults,
  chart,
  loading,
}: HTMLAttributes<HTMLTableElement> & ITableChartProps) {
  const [ref, width] = useWidth<HTMLDivElement>();

  // const rows = queryResults?.rows;
  const columns = queryResults?.metadata?.columns || [];
  const rows = queryResults?.rows || [];

  const yCols = chart?.uiOptions?.dataOptions?.yCols;

  const memoData = useMemo(
    () => rows as Record<string, string | number>[],
    [rows]
  );

  const memoColumns = useMemo(
    () =>
      columns.map((c, idx) => ({
        accessor: c.name,
        Header: yCols?.[idx]?.customName || c.name,
        headerClassName: 'text-right',
      })),
    [columns, yCols]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
    setGlobalFilter,
  } = useTable(
    {
      columns: memoColumns,
      data: memoData,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const onSearchInputChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  if (loading) {
    return <LogoBlinkerCenter />;
  }

  return (
    <div
      ref={ref}
      className={classNames(
        'flex flex-col w-full h-full pt-[2px] pb-1',
        className
      )}
    >
      <div className={classNames('flex-1 table-dense overflow-y-auto')}>
        <table
          className={classNames(
            'border table table-compact w-full table-zebra border-separate'
          )}
          style={{
            borderSpacing: '0',
          }}
          {...getTableProps()}
        >
          <thead className='w-full'>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, colIndex) => {
                    const yCol = yCols?.[colIndex];

                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        style={{
                          borderRadius: 0,
                          zIndex: 12,
                          top: 0,
                          position: 'sticky',
                          left: 'unset',
                          padding: '0.2rem',
                        }}
                        className={classNames(
                          'cursor-pointer sticky border-b-2 border-r border-t'
                        )}
                      >
                        <div
                          className={classNames('flex items-center', {
                            'flex-row-reverse': yCol?.align === 'right',
                            'justify-center': yCol?.align === 'center',
                          })}
                        >
                          <div className='font-bold'>
                            {column.render('Header')}
                          </div>
                          <div
                            className={classNames('pl-3 w-10', {
                              hidden: !column.isSorted && !column.isSortedDesc,
                            })}
                          >
                            {
                              // eslint-disable-next-line
                              column.isSorted &&
                                (column.isSortedDesc ? (
                                  <MdOutlineArrowDropDown size='0.9rem' />
                                ) : (
                                  <MdOutlineArrowDropUp size='0.9rem' />
                                ))
                            }
                          </div>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, cellIdx) => {
                    const yCol = yCols?.[cellIdx];
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          padding: '0.2rem',
                        }}
                        className={classNames('border-b-content border-r', {
                          'text-right': yCol?.align === 'right',
                          'text-left': yCol?.align === 'left',
                          'text-center': yCol?.align === 'center',
                        })}
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className='h-3' />
      <div className='flex pagination items-center'>
        <div className='flex-1 flex items-center'>
          {width && width > 750 && (
            <div className='btn-group'>
              <button
                className={classNames('btn btn-sm btn-primary', {
                  'btn-disabled': !canPreviousPage,
                })}
                onClick={() => gotoPage(0)}
              >
                «
              </button>
              <button
                className={classNames('btn btn-sm btn-primary', {
                  'btn-disabled': !canPreviousPage,
                })}
                onClick={() => previousPage()}
              >
                ‹
              </button>
              <button
                className={classNames('btn btn-sm btn-primary', {
                  'btn-disabled': !canNextPage,
                })}
                onClick={() => nextPage()}
              >
                ›
              </button>
              <button
                className={classNames('btn btn-sm btn-primary', {
                  'btn-disabled': !canNextPage,
                })}
                onClick={() => gotoPage(pageCount - 1)}
              >
                »
              </button>
            </div>
          )}
          <div className='w-3' />
          <span>
            {' Page '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </span>
          <div className='w-3' />
          <input
            type='text'
            placeholder='search in results'
            className='input input-bordered input-sm w-36'
            onChange={(e) => {
              onSearchInputChange(e.target.value);
            }}
          />
          <div className='w-3' />
        </div>
        <div className='flex'>
          <span>
            {'Go to '}
            <input
              type='number'
              className='input input-sm input-primary w-20 text-center'
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const nPage = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(nPage);
              }}
            />
          </span>
          <div className='w-3' />
          <select
            className='select select-sm max-w-xs'
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              e.stopPropagation();
              e.preventDefault();
              e.target.blur();
            }}
          >
            {[10, 20, 30, 40, 50].map((nPageSize) => (
              <option key={nPageSize} value={nPageSize}>
                Show {nPageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}

export default memo(TableChartV2);
