import { Link } from 'react-router-dom';

import envs from '../../config/envs';
import ComingSoon from '../connectors/tabs/ComingSoon';
import { SQLIconV2 } from '../../components/icons/Icons';
import { useWorkspace } from '../../layouts/components/workspace-hooks';
import { convertNumberToFormatted, DateFormat } from '../../utils/date';
import ProfileAvatar from '../auth/ProfileAvatar';
import LayoutBodyCard from '../../components/cards/LayoutBodyCard';
import BodyHeader3 from '../../components/typographic/BodyHeader3';
import { HorizontalLine } from '../../components/HorizontalLine';
import { IoMdKey } from 'react-icons/io';
import { isEmpty } from 'lodash';

export default function ApiKeysPage() {
  const [{ menu }, { getPath }] = useWorkspace();

  if (envs.isProdRuntime) {
    return <ComingSoon />;
  }

  return (
    <LayoutBodyCard>
      <div className='flex px-6 py-3 items-center justify-between'>
        <BodyHeader3>
          <div className='flex gap-2 items-center'>
            <IoMdKey className='inline' size='1.4rem' /> API Keys
          </div>
        </BodyHeader3>
      </div>
      <HorizontalLine />
      <div className='flex flex-col w-full p-3 overflow-x-auto'>
        <table className='table w-full'>
          <thead className='rounded-full w-full'>
            <tr className='w-full font-semibold opacity-30'>
              <th className='p-2'>KEY NAME</th>
              <th className='p-2'>KEY</th>
              <th className='p-2 w-2'>OWNER</th>
              <th className='p-2 w-2'>QUERIES</th>
              <th className='p-2 w-2'>API CALLS</th>
              <th className='p-2 w-2'>
                <span className='flex items-center'>CREATED TIME</span>
              </th>
            </tr>
          </thead>
          <tbody className='rounded-full w-full'>
            {isEmpty([]) && (
              <td colSpan={1000} className='text-center opacity-30 p-20'>
                No API keys
              </td>
            )}
            {[].map(
              ({
                id,
                // @ts-ignore
                displayName,
                creator,
                createdTime,
              }) => (
                <tr
                  key={id}
                  className='font-semibold items-center overflow-hidden'
                >
                  <td className='p-2 overflow-hidden'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div className='flex flex-auto items-center text-lg space-x-2 pr-2 overflow-hidden w-[42rem]'>
                        <SQLIconV2
                          style={{ width: '1.5rem' }}
                          className='flex-none'
                        />
                        <span
                          title={displayName || id}
                          className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                        >
                          {displayName || id}
                        </span>
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 overflow-hidden'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div className='flex flex-auto w-[12rem] justify-start items-center gap-3'>
                        <ProfileAvatar
                          img={
                            // @ts-ignore
                            creator?.profileImage
                          }
                          className='flex-none w-6 bg-base-content bg-opacity-30'
                        />
                        <span
                          title={
                            // @ts-ignore
                            creator?.name
                          }
                          className='flex-auto overflow-hidden whitespace-nowrap text-ellipsis'
                        >
                          {
                            // @ts-ignore
                            creator?.name
                          }
                        </span>
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 w-8 opacity-50'>
                    <Link key={id} to={`${getPath(menu)}/${id}`}>
                      <div>
                        {convertNumberToFormatted(createdTime, {
                          formatStr: DateFormat,
                        })}
                      </div>
                    </Link>
                  </td>
                  <td className='p-2 w-8'>
                    <div>
                      <Link to={`${getPath('apis')}/new?queryId=${id}`}>
                        <button className='btn btn-primary'>
                          Select to Create
                        </button>
                      </Link>
                    </div>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </LayoutBodyCard>
  );
}
